import { useRef, useEffect } from 'react';

// eslint-disabled
export default function useDidUpdate(callback, depends) {
  const hasMount = useRef(false);
  useEffect(() => {
    if (hasMount.current) {
      callback();
    } else {
      hasMount.current = true;
    }
  }, depends); // eslint-disable-line
}
