import React, { useEffect } from 'react';

import LoadingModal from 'components/Loading/LoadingModal';
import { showResponseError } from 'functions';
import { useRequestCertificateMessage } from 'queries/signature';

const SignatureModal = ({ title, product = {}, loading, onSubmit, onClose, ...rest }) => {
  const [requestCertificateMessage] = useRequestCertificateMessage();
  const handleMessage = payload => {
    const {
      CERT_SUBJECT: subject,
      HEX_SERIAL: serial_number,
      NGAY_HLUC: validity_not_before,
      NGAY_HHAN: validity_not_after,
      CERT_ISSUER: issuer,
      CERT_CHAIN: fingerprint,
      CERT: certificate
    } = payload;

    onSubmit({
      subject,
      serial_number,
      validity_not_before,
      validity_not_after,
      issuer,
      fingerprint,
      certificate
    });
  };

  useEffect(() => {
    requestCertificateMessage(
      {},
      {
        onSuccess: payload => {
          if (payload.HEX_SERIAL) {
            handleMessage(payload);
          } else {
            onClose();
          }
        },
        onError: error => {
          onClose();
          showResponseError({ error });
        }
      }
    );
  }, []); // eslint-disable-line

  return <LoadingModal {...rest} />;
};

export default SignatureModal;
