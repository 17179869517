import { useMutation, usePaginatedQuery } from 'react-query';
import { getInvoicePackages, createInvoicePackage, updateInvoicePackage } from 'services/package';

const _paginatedKey = params => ['invoice-packages', params];

export const useCreatePackage = () => useMutation(product => createInvoicePackage(product));
export const useUpdatePackage = () => useMutation(product => updateInvoicePackage(product));
export const useInvoicePackages = params => {
  return usePaginatedQuery(_paginatedKey(params), () => getInvoicePackages(params));
};
