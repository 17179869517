/**
 * Get token from local storage
 */
export const getToken = () => localStorage.getItem('token');

/**
 * Set token
 */
export const setToken = token => {
  if (!token) return;
  localStorage.setItem('token', token);
};

/**
 * Remove token
 */
export const removeToken = () => localStorage.removeItem('token');

/**
 * Clear authorization data.
 */
export function clearAuthorization() {
  removeToken();
  localStorage.removeItem('authority');
}

/**
 * Get authority
 * Default [] for now
 */
export const getAuthority = () => [];

/**
 * Get tenant from local storage
 */
export const getTenant = () => localStorage.getItem('tenant');

/**
 * Set tenant
 */
export const setTenant = tenant => {
  if (!tenant) return;
  localStorage.setItem('tenant', tenant);
};

/**
 * Remove tenant
 */
export const remoteTenant = () => localStorage.removeItem('tenant');

/**
 * Get flag if user is forced to change password.
 */
export const getForceChangePwd = () => localStorage.getItem('forceChangePwd');

/**
 * Set flag if user is forced to change password.
 */
export const setForceChangePwd = (forceChangePwd = false) => {
  localStorage.setItem('forceChangePwd', forceChangePwd.toString());
};
