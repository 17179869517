import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCloneInvoice } from 'queries/invoice';
import { showResponseError, showMessage } from 'functions';

const InvoiceCloneModal = ({ data, onOk, ...restProps }) => {
  const [t] = useTranslation();
  const [clone, { isLoading }] = useCloneInvoice();

  const handleClone = () => {
    clone(data.id, {
      onSuccess: () => {
        showMessage({ content: 'Đã tạo bản sao thành công.' });
        onOk();
      },
      onError: error => {
        showResponseError({ error });
      }
    });
  };

  return (
    <Modal
      okText={t('button.clone')}
      cancelText={t('button.cancel')}
      title={t('confirm.clone-title')}
      {...restProps}
      onOk={handleClone}
      confirmLoading={isLoading}
    >
      <span>{t('confirm.clone-message')}</span>
    </Modal>
  );
};

InvoiceCloneModal.defaultProps = {
  data: {},
  okOk: () => {}
};

InvoiceCloneModal.propTypes = {
  data: PropTypes.object,
  onOk: PropTypes.func
};
export default InvoiceCloneModal;
