import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LoadingModal from 'components/Loading/LoadingModal';
import { download, pollingRequest } from 'functions';

const ExportFileModal = ({ onCancel, params, visible, fileName, exportFunc, ...rest }) => {
  useEffect(() => {
    if (visible && exportFunc) {
      (async function () {
        const _f = () => exportFunc(...params);
        const { download_url } = await pollingRequest(_f);
        download(download_url, fileName);
        onCancel();
      })();
    }
  }, [params, onCancel, visible, fileName, exportFunc]);

  return <LoadingModal {...rest} visible={visible} />;
};

ExportFileModal.defaultProps = {
  visible: false,
  params: [],
  exportFunc: undefined,
  fileName: ''
};

ExportFileModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  exportFunc: PropTypes.func,
  fileName: PropTypes.string,
  visible: PropTypes.bool,
  params: PropTypes.array
};

export default ExportFileModal;
