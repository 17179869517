import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import CancellationContextWrapper from 'pages/invoice/cancellationNotification/CancellationContextWrapper';
import { useCancellationsByInvoiceId } from 'queries/invoice';
import CancellationNotificationTable from './CancellationNotificationTable';

const CancellationNotificationsModal = ({ invoice, visible, ...rest }) => {
  const [t] = useTranslation();
  const { data: items = [], isFetching, refetch, remove } = useCancellationsByInvoiceId(invoice.id, { enabled: false });

  React.useEffect(() => {
    if (visible) refetch();
    else remove();
  }, [visible, refetch, remove]);
  return (
    <Modal
      visible={visible}
      okText={t('button.create-in-short')}
      cancelText={t('button.cancel')}
      title="Danh sách thông báo huỷ/chỉnh sửa hoá đơn đã phát hành"
      footer={null}
      {...rest}
      width={663}
    >
      <CancellationContextWrapper>
        <CancellationNotificationTable loading={isFetching} items={items} onReload={refetch} />
      </CancellationContextWrapper>
    </Modal>
  );
};

CancellationNotificationsModal.defaultProsp = {
  visible: false,
  invoice: {}
};

CancellationNotificationsModal.propTypes = {
  visible: PropTypes.bool,
  invoice: PropTypes.object
};

export default CancellationNotificationsModal;
