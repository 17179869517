import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

const AuthSection = ({ hideBtnLogin, onClick }) => (
  <div>
    {!hideBtnLogin && (
      <Button type="link" onClick={onClick}>
        <Link to="/user/sign-in" style={{ color: 'black', fontWeight: 500 }}>
          Đăng nhập
        </Link>
      </Button>
    )}
    <Button type="primary" onClick={onClick}>
      <Link to="/landing/registration">Đăng ký</Link>
    </Button>
  </div>
);

AuthSection.defaultProps = {
  hideBtnLogin: false,
  onClick: () => null
};

AuthSection.propTypes = {
  onClick: PropTypes.func,
  hideBtnLogin: PropTypes.bool
};

export default AuthSection;
