import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Tooltip, Divider, Button, Space } from 'antd';
import PDFViewer from 'components/PDF/PDFViewer';
import { getInvoiceByCode } from 'services/invoice';
import { pollingRequest, download } from 'functions';
import { FileFormat } from 'constant';
import { ReactComponent as ExportXMLIcon } from 'assets/icons/action-export-xml.svg';
import { ReactComponent as DownloadPDFIcon } from 'assets/icons/action-download-pdf.svg';
import styles from './ViewInvoiceModal.less';

const ViewInvoiceModal = ({ code, url, ...rest }) => {
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [t] = useTranslation();

  const startDownloadFile = async fileType => {
    setIsDownloading(true);
    const { download_url, status } = await pollingRequest(() => getInvoiceByCode(code, fileType));
    if (status === 'DONE') download(`${download_url}`, `invoice-${code}.${fileType}`);
    setIsDownloading(false);
  };

  return (
    <Modal
      width={1190}
      className={styles.modal}
      title={
        <Space>
          <div className={styles.actions}>
            <Tooltip title={t('invoice.registration.action.download-pdf')}>
              <Button
                type="text"
                disabled={isDownloading}
                onClick={() => startDownloadFile(FileFormat.PDF)}
                icon={<DownloadPDFIcon />}
              />
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title={t('invoice.action.export-xml')}>
              <Button
                type="text"
                disabled={isDownloading}
                onClick={() => startDownloadFile(FileFormat.XML)}
                icon={<ExportXMLIcon />}
              />
            </Tooltip>
          </div>
        </Space>
      }
      footer={null}
      {...rest}
    >
      <PDFViewer isProtected={false} file={url} tenant={rest.tenant} />
    </Modal>
  );
};

export default ViewInvoiceModal;
