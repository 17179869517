import { Col, Row } from 'antd';
import React from 'react';
import styles from './InvoiceDescription.less';

const InvoiceDescription = ({ invoice = {}, onOk, ...rest }) => (
  <div className={styles.invoiceDescription}>
    <Row gutter={36}>
      <Col span={6}>Mẫu số/Ký hiệu</Col>
      <Col span={18}>
        <strong>
          {invoice.invoice_template?.form} - {invoice.symbol}
        </strong>
      </Col>
    </Row>
    <Row gutter={36}>
      <Col span={6}>Khách hàng</Col>
      <Col span={18}>
        <strong>{invoice.consumer?.name}</strong>
      </Col>
    </Row>
  </div>
);

export default InvoiceDescription;
