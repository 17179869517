export const INVOICE_TYPE = Object.freeze({
  VAT: 'VAT',
  SALE: 'SALE',
  DELIVERY_NOTE: 'DELIVERY_NOTE',
  COMMERCIAL: 'COMMERCIAL',
  OTHERS: 'OTHERS'
});

export const INVOICE_TYPE_FORM = Object.freeze({
  [INVOICE_TYPE.VAT]: 1,
  [INVOICE_TYPE.SALE]: 2,
  [INVOICE_TYPE.DELIVERY_NOTE]: 6
});

export const INVOICE_TYPE_NUMBER = Object.freeze({
  [INVOICE_TYPE.VAT]: '01GTKT0',
  [INVOICE_TYPE.DELIVERY_NOTE]: '03XKNB0',
  [INVOICE_TYPE.SALE]: '02GTTT0'
});

export const INVOICE_STAGE = Object.freeze({
  SIGNED: 'SIGNED',
  WAITING: 'WAITING'
});

export const INVOICE_STATUS = Object.freeze({
  CANCELED: 'CANCELED',
  ADJUSTED: 'ADJUSTED',
  ORIGINAL: 'ORIGINAL',
  REPLACED: 'REPLACED'
});

export const INVOICE_ADJUST_TYPE = Object.freeze({
  ADJUST_UP: 'ADJUST_UP',
  ADJUST_DOWN: 'ADJUST_DOWN',
  ADJUST_INFO: 'ADJUST_INFO'
});

export const LEGAL_INVOICE_TYPE = Object.freeze({
  INVOICE_119: 'INVOICE_119',
  INVOICE_123: 'INVOICE_123',
  INVOICE_1209_2660: 'INVOICE_1209_2660',
  INVOICE_51_04: 'INVOICE_51_04'
});

export const INVOICE_UPLOAD_TYPE = Object.freeze({
  IMPORT: 'IMPORT',
  REPLACE_ATTACHMENT: 'REPLACE_ATTACHMENT',
  CANCELLATION_ATTACHMENT: 'CANCELLATION_ATTACHMENT',
  ADJUST_ATTACHMENT: 'ADJUST_ATTACHMENT'
});

export const AUTHORITY_INVOICE_STATUS = Object.freeze({
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
});

export const DECREE123_ACTION_NO_TAX_AUTHORITY_MAPPING = Object.freeze({
  edit: [[INVOICE_STAGE.WAITING], [INVOICE_STATUS.ORIGINAL, INVOICE_STATUS.ADJUSTED, INVOICE_STATUS.REPLACED]],
  delete: [[INVOICE_STAGE.WAITING], [INVOICE_STATUS.ORIGINAL, INVOICE_STATUS.ADJUSTED, INVOICE_STATUS.REPLACED]],
  issue: [[INVOICE_STAGE.WAITING], [INVOICE_STATUS.ORIGINAL, INVOICE_STATUS.ADJUSTED, INVOICE_STATUS.REPLACED]],
  adjust: [
    [INVOICE_STAGE.SIGNED],
    [INVOICE_STATUS.ORIGINAL, INVOICE_STATUS.REPLACED] // to show error msg with status's REPLACED
  ],
  replace: [[INVOICE_STAGE.SIGNED], [INVOICE_STATUS.ORIGINAL, INVOICE_STATUS.REPLACED, INVOICE_STATUS.CANCELED]],
  cancel: [[INVOICE_STAGE.SIGNED], [INVOICE_STATUS.ORIGINAL, INVOICE_STATUS.ADJUSTED, INVOICE_STATUS.REPLACED]],
  sendEmail: [[...Object.values(INVOICE_STAGE)], [...Object.values(INVOICE_STATUS)]],
  convert: [[INVOICE_STAGE.SIGNED], [INVOICE_STATUS.ORIGINAL, INVOICE_STATUS.ADJUSTED, INVOICE_STATUS.REPLACED]]
});

export const DECREE51_ACTION_NO_TAX_AUTHORITY_MAPPING = Object.freeze({
  edit: [[INVOICE_STAGE.WAITING], [INVOICE_STATUS.ORIGINAL, INVOICE_STATUS.ADJUSTED, INVOICE_STATUS.REPLACED]],
  delete: [[INVOICE_STAGE.WAITING], [INVOICE_STATUS.ORIGINAL, INVOICE_STATUS.ADJUSTED, INVOICE_STATUS.REPLACED]],
  issue: [[INVOICE_STAGE.WAITING], [INVOICE_STATUS.ORIGINAL, INVOICE_STATUS.ADJUSTED, INVOICE_STATUS.REPLACED]],
  adjust: [[INVOICE_STAGE.SIGNED], [INVOICE_STATUS.ORIGINAL, INVOICE_STATUS.REPLACED]],
  replace: [[INVOICE_STAGE.SIGNED], [INVOICE_STATUS.ORIGINAL, INVOICE_STATUS.REPLACED, INVOICE_STATUS.CANCELED]],
  cancel: [[INVOICE_STAGE.SIGNED], [INVOICE_STATUS.ORIGINAL, INVOICE_STATUS.ADJUSTED, INVOICE_STATUS.REPLACED]],
  sendEmail: [[...Object.values(INVOICE_STAGE)], [...Object.values(INVOICE_STATUS)]],
  convert: [[INVOICE_STAGE.SIGNED], [INVOICE_STATUS.ORIGINAL, INVOICE_STATUS.ADJUSTED, INVOICE_STATUS.REPLACED]]
});
