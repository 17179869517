import axios from 'axios';
import { notification } from 'antd';
import { pathToRegexp } from 'path-to-regexp';
import rateLimit from 'axios-rate-limit';
import config from 'config/app.config';
import history from './history';
import { getToken, clearAuthorization } from './authority';
import { trimObject } from './format';

const { api, publicAPIs } = config;

const request = axios.create({ baseURL: api });

/**
 * Intercept authorized request.
 */
export function createRequestInterceptor(publicAPIs) {
  return function interceptor(config) {
    const { headers, data, url, authenticated = true } = config;
    if (!authenticated) return config;
    const isPublic = publicAPIs.some(api => pathToRegexp(api).test(url));
    if (isPublic) return config;

    const newHeaders = {
      ...headers,
      Authorization: `Bearer ${getToken()}`
    };
    const trimmedData = {
      data: data ? trimObject(data) : {}
    };
    return {
      ...config,
      ...trimmedData,
      headers: newHeaders
    };
  };
}

export function getPathname(config) {
  const { url, baseURL } = config;
  if (baseURL) {
    return url.replace(baseURL, '');
  }
  return new URL(url).pathname;
}

export function notifyError(notifier, error) {
  // const errorDetails = getErrorDetails(error);
  notifier(error);
}

/**
 * Notify error using Ant Design message.
 */
export function errorMessage(errorDetails) {
  // message.error(errorDetails);
  console.error(errorDetails);
}

/**
 * Notify error using Ant Design notification.
 */
export function errorNotification(errorDetails) {
  notification.error({
    message: 'Error',
    description: errorDetails
  });
}

/**
 * Check if the given status is user error or not.
 */
export function isUserError({ response: { status } }) {
  return status >= 400 && status < 500;
}

/**
 * Check if the given status is server error or not.
 */
export function isServerError({ response: { status } }) {
  return status >= 500;
}

/**
 * Check if the error is unauthorized error.
 */
export function isUnauthenticated(error) {
  const status = error && error.response && error.response.status;
  return status === 401;
}

/**
 * Check if the error is unauthorized error.
 */
export function isUnauthorized(error) {
  const status = error && error.response && error.response.status;
  return status === 403;
}

/**
 * Check if the error is request error or not.
 */
export function isRequestError({ response, request }) {
  return !response && !!request;
}

/**
 * Check if the error is response error or not.
 */
export function isResponseError({ response }) {
  return !!response;
}

export function createHandlerChain(handlers = []) {
  return function handlerChain(error) {
    const {
      config: { useDefaultErrorHandler = true }
    } = error;
    if (!useDefaultErrorHandler) {
      return Promise.reject(error);
    }
    const stack = [...handlers];
    function next() {
      if (stack.length === 0) {
        return;
      }
      const nextHandler = stack.pop();
      nextHandler(error, next);
    }
    next();
    return Promise.reject(error);
  };
}

export function serverErrorHandler(error, next) {
  if (!isResponseError(error) || !isServerError(error)) {
    return next();
  }
  notifyError(errorMessage, error);
  next();
}

export function userErrorHandler(error, next) {
  if (!isResponseError(error) || !isUserError(error)) {
    return next();
  }
  notifyError(errorMessage, error);
  next();
}

export function unauthenticatedErrorHandler(error, next) {
  const { response: { config: { url } = {} } = {} } = error;
  const isPublic = publicAPIs.some(api => pathToRegexp(api).test(url));
  if (!isPublic && isUnauthenticated(error)) {
    clearAuthorization();
    history.go();
    return;
  }
  next();
}

export function unauthorizedErrorHandler(error, next) {
  const { response: { config: { url } = {} } = {} } = error;
  const isPublic = publicAPIs.some(api => pathToRegexp(api).test(url));
  if (!isPublic && isUnauthorized(error)) {
    history.push('/not-authorized');
    history.go();
    return;
  }
  next();
}

export function parseResultsHandler(response) {
  const { data, config } = response;
  if (config.parseResponse === false) {
    return response;
  }
  return data;
}

request.interceptors.request.use(createRequestInterceptor(publicAPIs));
request.interceptors.response.use(
  parseResultsHandler,
  createHandlerChain([
    serverErrorHandler,
    userErrorHandler,
    unauthenticatedErrorHandler,
    unauthorizedErrorHandler
  ])
);

// Default request instance
export default rateLimit(request, { maxRequests: 5, perMilliseconds: 1000 });
