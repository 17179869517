import CancellationNotificationsModal from 'pages/invoice/list/components/CancellationNotificationsModal';
import CustomerImportModal from 'pages/category/customer/list/components/CustomerImportModal';
import ArchiveModal from 'components/Modal/ArchiveModal';
import DecisionAppCreateModal from 'pages/invoice/register/decisionApplication/components/DecisionAppCreateModal';
import DecisionApplicationAttachmentModal from 'pages/invoice/register/decisionApplication/list/components/DecisionApplicationAttachmentModal';
import DecisionApplicationExportModal from 'pages/invoice/register/decisionApplication/list/components/DecisionApplicationExportModal';
import DeleteModal from 'components/Modal/DeleteModal';
import DeleteProductModal from 'pages/category/product/list/components/DeleteProductModal';
import DeleteSignatureModal from 'pages/organization/signature/list/components/DeleteSignatureModal';
import InvoiceCancelModal from 'pages/invoice/list/components/InvoiceCancelModal';
import InvoiceConvertModal from 'pages/invoice/list/components/InvoiceConvertModal';
import InvoiceCancellationReplacementModal from 'pages/invoice/replace/components/InvoiceCancellationReplacementModal';
import InvoiceCloneModal from 'pages/invoice/list/components/InvoiceCloneModal';
import InvoiceDeleteModal from 'pages/invoice/list/components/InvoiceDeleteModal';
import InvoiceProductSelectionModal from 'pages/invoice/create/components/InvoiceProductSelectionModal';
import InvoiceSearchSelectModal from 'pages/invoice/cancellationNotification/detail/components/InvoiceSearchSelectModal';
import InvoicePreviewModal from 'pages/invoice/create/components/InvoicePreviewModal';
import IssueInvoiceModal from 'pages/invoice/list/components/IssueInvoiceModal';
import IssueInvoiceConfirmModal from 'pages/invoice/list/components/IssueInvoiceConfirmModal';
import InvoiceSummaryExportModal from 'pages/report/invoice-summary/list/components/InvoiceSummaryExportModal';
import InvoiceSummaryIssueModal from 'pages/report/invoice-summary/list/components/InvoiceSummaryIssueModal';
import IssuedTemplateSignModal from 'pages/invoice/register/issuedTemplate/list/components/IssuedTemplateSignModal';
import LoadingModal from 'components/Loading/LoadingModal';
import ProductImportModal from 'pages/category/product/list/components/ProductImportModal';
import ProductLinesImportModal from 'pages/invoice/create/components/ProductLinesImportModal';
import ProductModal from 'pages/category/product/list/components/ProductModal';
import ReplacementInvoiceModal from 'pages/invoice/replace/components/ReplacementInvoiceModal';
import RoleModal from 'pages/role/list/components/RoleModal';
import SendEmailModal from 'components/_share/SendInvoiceEmailModal';
import CancelConfirmModal from 'components/Modal/CancelConfirmModal';
import SignatureModal from 'pages/organization/signature/list/components/SignatureModal';
import SignatureRegistrationModal from 'pages/organization/signature/list/components/SignatureRegistrationModal';
import TenantModal from 'pages/tenant/list/components/TenantModal';
import TenantAccountModal from 'pages/tenant/list/components/TenantAccountModal';
import TenantOrganizationModal from 'pages/tenant/list/components/TenantOrganizationModal';
import TenantInvoicePackage from 'pages/tenant/list/components/TenantInvoicePackageModal';
import UserModal from 'pages/user/list/components/UserModal';
import UserRolesModal from 'pages/user/list/components/UserRolesModal/index';
import ViewConvertedInvoiceModal from 'pages/invoice/list/components/ViewConvertedInvoiceModal';
import ChangePasswordModal from './ChangePasswordModal';

export const ModalType = {
  ARCHIVE_CONFIRMATION: 'ARCHIVE_CONFIRMATION',
  CANCEL_CONFIRM: 'CANCEL_CONFIRM',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CUSTOMER_IMPORT: 'CUSTOMER_IMPORT',
  DECISION_APP_CREATE: 'DECISION_APP_CREATE',
  DECISION_APPLICATION_EXPORT: 'DECISION_APPLICATION_EXPORT',
  DECISION_APPLICATION_ATTACHMENT: 'DECISION_APPLICATION_ATTACHMENT',
  DELETE_CONFIRMATION: 'DELETE_CONFIRMATION',
  INVOICE_CANCEL: 'INVOICE_CANCEL',
  INVOICE_CONVERT: 'INVOICE_CONVERT',
  INVOICE_CANCELLATION_REPLACEMENT: 'INVOICE_CANCELLATION_REPLACEMENT',
  INVOICE_CLONE: 'INVOICE_CLONE',
  INVOICE_DELETE: 'INVOICE_DELETE',
  INVOICE_ISSUE: 'INVOICE_ISSUE',
  INVOICE_ISSUE_CONFIRM: 'INVOICE_ISSUE_CONFIRM',
  INVOICE_PREVIEW: 'INVOICE_PREVIEW',
  INVOICE_PRODUCT_SELECTION: 'INVOICE_PRODUCT_SELECTION',
  INVOICE_SEARCH_SELECT: 'INVOICE_SEARCH_SELECT',
  INVOICE_SUMMARY_EXPORT: 'INVOICE_SUMMARY_EXPORT',
  INVOICE_SUMMARY_ISSUE: 'INVOICE_SUMMARY_ISSUE',
  INVOICE_TEMPLATE_ISSUE: 'INVOICE_TEMPLATE_ISSUE',
  ISSUED_TEMPLATE_SIGN: 'ISSUED_TEMPLATE_SIGN',
  LOADING: 'LOADING',
  PRODUCT: 'PRODUCT',
  PRODUCT_DELETE_CONFIRMATION: 'PRODUCT_DELETE_CONFIRMATION',
  PRODUCT_IMPORT: 'PRODUCT_IMPORT',
  PRODUCT_LINES_IMPORT: 'PRODUCT_LINES_IMPORT',
  REPLACE_CONFIRMATION: 'REPLACE_CONFIRMATION',
  ROLE: 'ROLE',
  SEND_EMAIL: 'SEND_EMAIL',
  SIGNATURE: 'SIGNATURE',
  SIGNATURE_DELETE_CONFIRMATION: 'SIGNATURE_DELETE_CONFIRMATION',
  SIGNATURE_REGISTRATION: 'SIGNATURE_REGISTRATION',
  TENANT: 'TENANT',
  TENANT_ACCOUNT: 'TENANT_ACCOUNT',
  TENANT_ORGANIZATION: 'TENANT_ORGANIZATION',
  TENANT_INVOICE_PACKAGE: 'TENANT_INVOICE_PACKAGE',
  TEMPLATE_DOWNLOAD: 'TEMPLATE_DOWNLOAD',
  TEMPLATE_PDF_VIEWER: 'TEMPLATE_PDF_VIEWER',
  USER: 'USER',
  USER_ROLES: 'USER_ROLES',
  VIEW_CANCELLATION_NOTIFICATIONS: 'VIEW_CANCELLATION_NOTIFICATIONS',
  VIEW_CONVERTED_INVOICE: 'VIEW_CONVERTED_INVOICE'
};

export const Modals = {
  [ModalType.ARCHIVE_CONFIRMATION]: ArchiveModal,
  [ModalType.CHANGE_PASSWORD]: ChangePasswordModal,
  [ModalType.CANCEL_CONFIRM]: CancelConfirmModal,
  [ModalType.CUSTOMER_IMPORT]: CustomerImportModal,
  [ModalType.DECISION_APP_CREATE]: DecisionAppCreateModal,
  [ModalType.DECISION_APPLICATION_EXPORT]: DecisionApplicationExportModal,
  [ModalType.DECISION_APPLICATION_ATTACHMENT]: DecisionApplicationAttachmentModal,
  [ModalType.DELETE_CONFIRMATION]: DeleteModal,
  [ModalType.INVOICE_CANCEL]: InvoiceCancelModal,
  [ModalType.INVOICE_CONVERT]: InvoiceConvertModal,
  [ModalType.INVOICE_CANCELLATION_REPLACEMENT]: InvoiceCancellationReplacementModal,
  [ModalType.INVOICE_CLONE]: InvoiceCloneModal,
  [ModalType.INVOICE_DELETE]: InvoiceDeleteModal,
  [ModalType.INVOICE_ISSUE]: IssueInvoiceModal,
  [ModalType.INVOICE_ISSUE_CONFIRM]: IssueInvoiceConfirmModal,
  [ModalType.INVOICE_PREVIEW]: InvoicePreviewModal,
  [ModalType.INVOICE_PRODUCT_SELECTION]: InvoiceProductSelectionModal,
  [ModalType.INVOICE_SEARCH_SELECT]: InvoiceSearchSelectModal,
  [ModalType.INVOICE_SUMMARY_EXPORT]: InvoiceSummaryExportModal,
  [ModalType.INVOICE_SUMMARY_ISSUE]: InvoiceSummaryIssueModal,
  [ModalType.ISSUED_TEMPLATE_SIGN]: IssuedTemplateSignModal,
  [ModalType.LOADING]: LoadingModal,
  [ModalType.PRODUCT]: ProductModal,
  [ModalType.PRODUCT_IMPORT]: ProductImportModal,
  [ModalType.PRODUCT_LINES_IMPORT]: ProductLinesImportModal,
  [ModalType.PRODUCT_DELETE_CONFIRMATION]: DeleteProductModal,
  [ModalType.REPLACE_CONFIRMATION]: ReplacementInvoiceModal,
  [ModalType.ROLE]: RoleModal,
  [ModalType.SIGNATURE]: SignatureModal,
  [ModalType.SIGNATURE_REGISTRATION]: SignatureRegistrationModal,
  [ModalType.SIGNATURE_DELETE_CONFIRMATION]: DeleteSignatureModal,
  [ModalType.TENANT]: TenantModal,
  [ModalType.TENANT_ACCOUNT]: TenantAccountModal,
  [ModalType.TENANT_ORGANIZATION]: TenantOrganizationModal,
  [ModalType.TENANT_INVOICE_PACKAGE]: TenantInvoicePackage,
  [ModalType.SEND_EMAIL]: SendEmailModal,
  [ModalType.USER]: UserModal,
  [ModalType.USER_ROLES]: UserRolesModal,
  [ModalType.VIEW_CANCELLATION_NOTIFICATIONS]: CancellationNotificationsModal,
  [ModalType.VIEW_CONVERTED_INVOICE]: ViewConvertedInvoiceModal
};
