import React, { useState } from 'react';
import { LogoutOutlined, UserOutlined, UnlockOutlined } from '@ant-design/icons';
import { Dropdown, Avatar, Menu, Space, Spin } from 'antd';
import { useUserProfile } from 'queries/user';
import ChangePasswordModal from '../../layouts/ChangePasswordModal';

const ProfileDropdown = ({ onSignOut }) => {
  const { data: profile, isLoading } = useUserProfile();

  const [showChangePassword, setShowChangePassword] = useState();

  const changePassword = () => {
    setShowChangePassword(true);
  };

  const menu = (
    <Menu>
      <Menu.Item key="change-password" onClick={changePassword}>
        <UnlockOutlined /> Đổi mật khẩu
      </Menu.Item>
      <Menu.Item key="sign-out" onClick={onSignOut}>
        <LogoutOutlined /> Đăng xuất
      </Menu.Item>
    </Menu>
  );

  return isLoading ? (
    <Spin spinning={isLoading} style={{ color: '#fff' }} />
  ) : (
    <Dropdown overlay={menu}>
      <Space>
        {profile && (
          <>
            <Avatar>
              {profile.last_name[0]?.toUpperCase()}
              {profile.first_name[0]?.toUpperCase()}
            </Avatar>
            <span>{`${profile.last_name} ${profile.first_name}`}</span>
          </>
        )}
        {!profile && <Avatar icon={<UserOutlined />} />}
        <ChangePasswordModal
          visible={showChangePassword}
          onCancel={() => setShowChangePassword(false)}
          onOk={() => setShowChangePassword(false)}
        />
      </Space>
    </Dropdown>
  );
};

export default ProfileDropdown;
