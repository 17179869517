import { createContext } from 'react';

export default createContext({
  replaceInvoice: () => null,
  adjustInvoice: () => null,
  cancelInvoice: () => null,
  viewCancellation: () => null,
  deleteCancellation: () => null,
  editCancellation: () => null,
  issueCancellation: () => null
});
