import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Select } from 'antd';
import { sortWith, ascend, prop } from 'ramda';
import { useInvoicePackages } from 'queries/package';
import { useCreateTopups } from 'queries/topups';
import useDidUpdate from 'hooks/useDidUpdate';
import styles from './index.less';

const { Item: FormItem } = Form;
export const PACKAGE_STATUS = Object.freeze({ ACTIVE: 'ACTIVE' });
const TenantInvoicePackageModal = ({ tenantId, onDone, ...props }) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [dataSet, setDataSet] = useState([]);
  const { data = { count: 0, items: [] }, isFetching: isPackageLoading } = useInvoicePackages({
    pagination: { current: 1, pageSize: 100 }
  });

  const [create, { isLoading }] = useCreateTopups();
  const handleSubmit = ({ packageId }) => {
    const { quota, id } = data.items.find(item => item.id === packageId);
    const payload = {
      tenant: { id: tenantId },
      invoice_package: { id },
      amount: quota
    };
    create(payload, { onSuccess: onDone });
  };

  useDidUpdate(() => {
    if (!isPackageLoading) {
      const dataSet = sortWith([ascend(prop('quota'))])(data.items)
        .filter(item => item.status === PACKAGE_STATUS.ACTIVE)
        .map(item => ({
          label: `${item.name} - ${item.quota} hóa đơn`,
          value: item.id
        }));
      setDataSet(dataSet);
      if (dataSet[0]) {
        form.setFieldsValue({ packageId: dataSet[0].value });
      }
    }
  }, [isPackageLoading]);

  return (
    <Modal
      wrapClassName={styles.modalClassName}
      okText="Ok"
      cancelText={t('button.cancel')}
      onOk={form.submit}
      okButtonProps={{ loading: isLoading }}
      {...props}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <FormItem
          name="packageId"
          label="Chọn gói hóa đơn"
          rules={[{ required: true, message: 'Gói hóa đơn không được để trống' }]}
        >
          <Select
            size="large"
            showSearch
            allowClear
            loading={isPackageLoading}
            style={{ width: '100%' }}
            options={dataSet}
            placeholder="Chọn gói hóa đơn"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default TenantInvoicePackageModal;
