import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const ReplacementInvoiceModal = ({ onOk, isLoading, ...rest }) => {
  const [t] = useTranslation();
  return (
    <Modal
      okText={t('button.replace')}
      cancelText={t('button.cancel')}
      title={t('confirm.replace-title')}
      confirmLoading={isLoading}
      onOk={onOk}
      {...rest}
    >
      <span>{t('confirm.replace-message')}</span>
    </Modal>
  );
};

export default ReplacementInvoiceModal;
