export const CodeType = Object.freeze({
  NO_USB: 'NO_USB',
  CANCEL: 'CANCEL'
});

export class CodeError extends Error {
  constructor(code = CodeType.NO_USB, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CodeError);
    }

    this.name = 'CodeError';
    this.code = code;
  }
}
