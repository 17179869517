import { message } from 'antd';

const showResponseError = ({ error, ...rest }) => {
  const { response: { data: { message: firstMsg, details: thirdMsg } = {} } = {}, message: secondMsg } = error;
  const content = firstMsg || secondMsg || thirdMsg || '';
  if (content) {
    message.error({ content, ...rest });
  }
};

export default showResponseError;
