import request from 'utils/request';
import { requestCertificateMessage } from './signature';
import { sendEsignerMessageWithTimeout } from './esigner';
import { replace } from 'ramda';
import BigNumber from 'bignumber.js';
import { CodeError, CodeType } from 'services/CodeError';

/**
 * Default request signing timeout.
 */
export const DEFAULT_REQUEST_SIGNING_TIMEOUT = 30;

export const signEntity = (id, data) => request.post(`/signings/${id}`, data);

/**
 * Sign bulk of entities.
 *
 * @param data the given signed data
 */
export const signBulkEntities = data => request.post('/signings/bulk', data);

/**
 * Validate if eSigner and USB Token are ready for signing.
 */
export const validateIfSigningReady = async () => {
  const certPayload = await sendEsignerMessageWithTimeout(requestCertificateMessage(), DEFAULT_REQUEST_SIGNING_TIMEOUT);
  if (!certPayload) {
    throw new CodeError(CodeType.NO_USB, 'Vui lòng cài đặt eSigner, USB Token và thử lại.');
  }
  if (!certPayload.HEX_SERIAL) throw new CodeError(CodeType.CANCEL);
  return certPayload;
};

/**
 * Extract serial number from certificate payload.
 */
export const extractSerialNumber = ({ HEX_SERIAL: hexSerial }) => {
  const bignumber = new BigNumber(replace(/\s+/g, '', hexSerial), 16);
  return bignumber.toString(10);
};
