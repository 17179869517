import { Modal, Spin } from 'antd';
import React from 'react';
import styles from './LoadingModal.less';

const LoadingModal = props => (
  <Modal
    {...props}
    loading
    centered
    footer={null}
    width={70}
    mask={false}
    maskClosable={false}
    className={styles.loadingModal}
  >
    <Spin spinning />
  </Modal>
);

export default LoadingModal;
