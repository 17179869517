import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const RedirectPageForm04Confirm = ({ actionType, ...restProps }) => {
  const [t] = useTranslation();
  const message = {
    adjust:
      'Hoá đơn này đã được tạo thông báo hoá đơn điện tử có sai sót mẫu 04. Bạn cần chuyển tới trang danh sách thông báo hoá đơn điện tử có sai sót mẫu 04 để điều chỉnh hoá đơn này.',
    replace:
      'Hoá đơn này đã được tạo thông báo hoá đơn điện tử có sai sót mẫu 04. Bạn cần chuyển tới trang danh sách thông báo hoá đơn điện tử có sai sót mẫu 04 để thay thế hoá đơn này.',
    cancel:
      'Hoá đơn này đã được tạo thông báo hoá đơn điện tử có sai sót mẫu 04. Bạn cần chuyển tới trang danh sách thông báo hoá đơn điện tử có sai sót mẫu 04 để huỷ hoá đơn này.'
  };

  return (
    <Modal okText="Đồng ý" cancelText={t('button.cancel')} title="Thông báo" {...restProps}>
      <span>{message[actionType]}</span>
    </Modal>
  );
};

export default RedirectPageForm04Confirm;
