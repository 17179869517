import { Col, Form, Modal, Input, Row, Descriptions, DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useConvertInvoice } from 'queries/invoice';
import { useUserProfile } from 'queries/user';
import { showMessage, showResponseError } from 'functions';
import { formatDate, formatNumber } from 'utils/format';
import { DATE_FORMAT, DECREE_TYPE } from 'constant';

const InvoiceConvertModal = ({ invoice, decree, onOk, ...rest }) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const isDecree51 = DECREE_TYPE.DECREE_51 === decree;
  const { data: profile } = useUserProfile();
  const [convert, { isLoading }] = useConvertInvoice();

  const convertInvoice = (id, data) => {
    convert(
      { id, data },
      {
        onSuccess: () => {
          onOk();
          showMessage({
            content: 'Đã chuyển đổi hoá đơn thành công.'
          });
        },
        onError: error => showResponseError({ error })
      }
    );
  };

  const handleConvert = async () => {
    const data = await form.validateFields();
    convertInvoice(invoice.id, data);
  };

  function disabledDate(current) {
    return current && current.isBefore(moment().startOf('day'));
  }

  useEffect(
    () =>
      form.setFieldsValue({
        convert_date: moment(),
        converter: `${profile.last_name} ${profile.first_name}`
      }),
    [form, profile]
  );

  return (
    <Modal
      okText="Chuyển đổi"
      cancelText={t('button.cancel')}
      title="Chuyển đổi hoá đơn"
      onOk={handleConvert}
      width={703}
      confirmLoading={isLoading}
      {...rest}
    >
      <Form form={form} colon={false} layout="vertical">
        <Row gutter={36}>
          <Col span={24}>
            <Descriptions column={2} title="">
              <Descriptions.Item label="Số hoá đơn">
                <strong>{invoice.serial_number}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Ngày hóa đơn">
                <strong>{formatDate(invoice.date_of_issue)}</strong>
              </Descriptions.Item>
              <Descriptions.Item label={`${isDecree51 ? 'Mẫu số/' : ''}Ký hiệu`}>
                <strong>
                  {invoice.invoice_template.form}
                  {DECREE_TYPE.DECREE_51 === decree ? '-' : ''}
                  {invoice.symbol}
                </strong>
              </Descriptions.Item>
              <Descriptions.Item label="Số tiền">
                <strong>{formatNumber(invoice.extra_fields.total)}</strong>
              </Descriptions.Item>
              <Descriptions.Item span={2} label="Khách hàng">
                <strong>{invoice.consumer.name}</strong>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Row gutter={36}>
          <Col span={24}>
            <Descriptions column={2} title="" />
          </Col>
        </Row>
        <Row gutter={36}>
          <Col span={24}>
            <Form.Item
              label="Người chuyển đổi"
              name="converter"
              rules={[
                {
                  required: true,
                  message: 'Người chuyển đổi không được để trống'
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={36}>
          <Col span={24}>
            <Form.Item
              label="Ngày chuyển đổi"
              name="convert_date"
              rules={[
                {
                  required: true,
                  message: 'Ngày chuyển đổi không được để trống'
                }
              ]}
            >
              <DatePicker disabledDate={disabledDate} placeholder="" format={DATE_FORMAT} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default InvoiceConvertModal;
