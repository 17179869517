import moment from 'moment';
import { DATE_TIME_FORMAT } from 'constant';
import buildQueryParams from 'utils/buildQueryParams';
import request from 'utils/request';

/**
 * Get signature list
 */
export const getSignatures = params =>
  request.get(`/signatures`, {
    params: buildQueryParams(params)
  });

/**
 * Create signature.
 *
 * @param signature the given signature
 */
export const createSignature = signature => request.post('/signatures', signature);

/**
 * Delete signature.
 *
 * @param id the given signature id
 */
export const deleteSignature = id => request.delete(`/signatures/${id}`);

/**
 * Get message to get certificate.
 */
export const requestCertificateMessage = () => ({ CODE: 'CER_002' });

/**
 * Get base64 message.
 */
export const signBase64Message = ({ payload, serial_number, tax_code }) => ({
  CODE: 'CER_001',
  BASE64_VALUE: payload,
  SERIAL_NUMBER: serial_number,
  CHECK_CERT_SERIAL: 'true',
  CHECK_TIN: 'true',
  TIN_VALID: tax_code,
  DATE_SIGNING: moment().format(DATE_TIME_FORMAT),
  TIMEZONE_SIGNING: 'Asia/Bangkok'
});

/**
 * Mark signature as default.
 *N
 * @param id the given signature id
 */
export const markSignatureAsDefault = (id, serialNumber) =>
  request.put(`/organizations/${id}/digital_signature/${serialNumber}`);
