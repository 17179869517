import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const InvoiceCancellationReplacementModal = ({ isLoading, ...rest }) => {
  const [t] = useTranslation();
  return (
    <Modal
      okText="Huỷ và Tạo hoá đơn thay thế"
      cancelText="Thoát"
      title={t('confirm.replace-title')}
      confirmLoading={isLoading}
      {...rest}
    >
      <span>Bạn có đồng ý huỷ hoá đơn gốc và tạo hoá đơn thay thế?</span>
    </Modal>
  );
};

export default InvoiceCancellationReplacementModal;
