import React from 'react';
import { Layout } from 'antd';
import ModalManager from 'components/Modal/ModalManager';
import useDetectMobileScreen from 'hooks/useDetectMobileScreen';
import WebLandingLayout from './components/Web';
import MobileLandingLayout from './components/Mobile';
import { PublicModals } from '../PublicModals';
import styles from './index.less';

const LandingLayout = ({ children }) => {
  const [selectedKey, setSelectedKey] = React.useState();
  const [isMobile] = useDetectMobileScreen();
  const handleMenuClick = ({ key }) => setSelectedKey(key);

  const renderHeader = () => {
    const props = { selectedKey, onMeuItemClick: handleMenuClick };
    if (isMobile) return <MobileLandingLayout.Header {...props} />;
    return <WebLandingLayout.Header {...props} />;
  };

  const renderFooter = () => {
    const props = { selectedKey, onMeuItemClick: handleMenuClick };
    if (isMobile) return <MobileLandingLayout.Footer {...props} />;
    return <WebLandingLayout.Footer {...props} />;
  };

  return (
    <Layout className={styles.layout}>
      {renderHeader()}
      <Layout.Content style={{ backgroundColor: 'white' }}>
        <ModalManager modalMap={PublicModals}>{children}</ModalManager>
      </Layout.Content>
      {renderFooter()}
    </Layout>
  );
};

export default LandingLayout;
