import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Layout, Menu, Button } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import Image from 'components/Image';
import Logo from 'assets/logos/logo-green.svg';
import HomeIcon from 'assets/icons/icon-home.svg';
import InviceIcon from 'assets/landing/header-invoice.svg';
import PricingIcon from 'assets/icons/icon-pricing.svg';
import SearchIcon from 'assets/icons/icon-search.svg';
import GuideIcon from 'assets/icons/icon-guide.svg';
import AuthSection from 'layouts/AuthSection';
import styles from './index.less';

const LandingLayout = ({ onMeuItemClick, selectedKey }) => {
  const [menuCollapsed, setMenuCollapsed] = React.useState(true);
  const history = useHistory();
  const menuWrapperCN = classNames(styles.menuWrapper, {
    [styles.active]: !menuCollapsed
  });
  const backdropClassName = classNames(styles.backdrop, {
    [styles.active]: !menuCollapsed
  });

  const linkClassName = key => {
    return classNames(styles.link, { [styles.active]: selectedKey === key });
  };

  const redirect = path => {
    setMenuCollapsed(true);
    history.push(path);
  };

  const handleMenuClick = event => {
    onMeuItemClick(event);
    setMenuCollapsed(true);
  };

  const handleAuthClick = () => {
    if (!menuCollapsed) setMenuCollapsed(true);
  };

  const renderIcon = () => {
    const props = {
      onClick: () => setMenuCollapsed(prev => !prev),
      className: styles.menuOutlined
    };
    if (menuCollapsed) return <MenuOutlined {...props} />;
    return <CloseOutlined {...props} />;
  };

  return (
    <Layout.Header>
      <div className={styles.logoWrapper}>
        <Link to="/">
          <Image src={Logo} width={100} alt="logo" />
        </Link>
      </div>
      <div className={styles.auth}>
        <AuthSection hideBtnLogin onClick={handleAuthClick} />
      </div>
      {renderIcon()}
      <div className={menuWrapperCN}>
        <Menu theme="light" mode="vertical" onClick={handleMenuClick}>
          <Menu.Item key="home">
            <Link to="/landing/home" className={linkClassName('home')}>
              <Image src={HomeIcon} />
              Trang chủ
            </Link>
          </Menu.Item>
          <Menu.Item key="invoiceTemplates">
            <Link to="/invoice-templates" className={linkClassName('invoiceTemplates')}>
              <Image src={InviceIcon} />
              Mẫu hóa đơn
            </Link>
          </Menu.Item>
          <Menu.Item key="pricing">
            <Link to="/pricing" className={linkClassName('pricing')}>
              <Image src={PricingIcon} />
              Báo giá
            </Link>
          </Menu.Item>
          <Menu.Item key="lookupInvoice">
            <Link to="/lookup-invoice" className={linkClassName('lookupInvoice')}>
              <Image src={SearchIcon} />
              Tra cứu
            </Link>
          </Menu.Item>
          <Menu.Item key="guide">
            <Link to="/support" className={linkClassName('guide')}>
              <Image src={GuideIcon} />
              Hỗ trợ
            </Link>
          </Menu.Item>
        </Menu>
        <Button type="primary" ghost onClick={() => redirect('/user/sign-in')}>
          Đăng nhập
        </Button>
        <Button type="primary" onClick={() => redirect('/landing/registration')}>
          Đăng ký
        </Button>
      </div>
      <div className={backdropClassName} onClick={() => setMenuCollapsed(true)} />
    </Layout.Header>
  );
};

export default LandingLayout;
