import { useMutation, usePaginatedQuery } from 'react-query';
import { deleteSignature, getSignatures, createSignature, markSignatureAsDefault } from 'services/signature';
import { validateIfSigningReady } from 'services/signing';

const paginatedKey = page => ['signatures', page];

/**
 * Query signature list hook.
 */
export const useSignatures = ({ pagination, filters }) => {
  const f = () => getSignatures({ pagination, filters });
  return usePaginatedQuery(paginatedKey(pagination.current), f);
};

/**
 * Create signature hook.
 */
export const useCreateSignature = () => useMutation(signature => createSignature(signature));

/**
 * Delete signature hook.
 */
export const useDeleteSignature = () => useMutation(signature => deleteSignature(signature.id));

/**
 * Request certificate hook.
 */
export const useRequestCertificateMessage = () => useMutation(validateIfSigningReady);

/**
 * Mark signature as default hook.
 */
export const useMarkSignatureAsDefault = () =>
  useMutation(({ organization, signature }) => markSignatureAsDefault(organization.id, signature.serial_number));
