import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ForceSignConfirmModal = ({ ...rest }) => {
  const [t] = useTranslation();
  return (
    <Modal okText="Phát hành" cancelText={t('button.cancel')} title="Phát hành?" {...rest}>
      <span>Bạn đang ký bằng chữ ký khác với chữ ký mặc định. Bạn có muốn tiếp tục?</span>
    </Modal>
  );
};

export default ForceSignConfirmModal;
