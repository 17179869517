import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Select } from 'antd';
import { useDidUpdate } from 'hooks';
import { validateEmailList } from 'utils/validate';
import { showMessage } from 'functions';
import styles from './index.less';

const SendEmailModal = ({ data, visible, onOk, isIssued, sendEmailMethod, ...restProps }) => {
  const [t] = useTranslation();
  const searchRef = React.useRef();
  const [error, setError] = useState('');
  const [emails, setEmails] = useState([]);
  const { id } = data;

  const [sendInvoice, { isLoading }] = sendEmailMethod();
  const sendEmail = emails => {
    sendInvoice(
      { id, emails },
      {
        onSuccess: () => {
          showMessage({ content: 'Gửi email cho khách hàng thành công.' });
          onOk();
        },
        onError: () => {
          setError('Có lỗi xảy ra, vui lòng thử lại.');
        }
      }
    );
  };

  const validateFields = (data = emails) => {
    let error = '';
    if (!data.length) error = 'Email không được để trống.';
    if (!validateEmailList(data)) error = 'Email không hợp lệ.';
    setError(error);
    return error;
  };

  const submit = () => {
    const error = validateFields();
    if (!error) sendEmail(emails);
  };

  const handleSelectChange = emails => {
    validateFields(emails);
    setEmails(emails);
    searchRef.current = '';
  };

  const handleKeyDown = e => {
    if (!searchRef.current && e.key === 'Enter') e.stopPropagation();
  };

  useDidUpdate(() => {
    if (!visible) {
      setError('');
      setEmails([]);
    }
  }, [visible]);
  return (
    <Modal
      okText={t('button.send')}
      cancelText={t('button.cancel')}
      title="Gửi hoá đơn qua email"
      onOk={submit}
      confirmLoading={isLoading}
      destroyOnClose
      visible={visible}
      {...restProps}
    >
      <div className={styles.note}>
        Gửi hoá đơn
        {!isIssued && <span>NHÁP</span>}
        {isIssued && <span>ĐÃ PHÁT HÀNH</span>}
        đến khách hàng.
      </div>
      <Select
        open
        autoFocus
        placeholder="Nhập danh sách email khách hàng. Email cách nhau bởi dấu phẩy"
        mode="tags"
        className={styles.modalWrapper}
        dropdownStyle={{ display: 'none' }}
        style={{ width: '100%' }}
        tokenSeparators={[',', ' ']}
        onSearch={value => (searchRef.current = value)}
        onChange={handleSelectChange}
        onInputKeyDown={handleKeyDown}
      />
      <p className={styles.error}>{error}</p>
    </Modal>
  );
};

SendEmailModal.defaultProps = {
  onOk: () => {},
  data: {},
  visible: false,
  isIssued: false
};

SendEmailModal.propTypes = {
  visible: PropTypes.bool,
  isIssued: PropTypes.bool,
  data: PropTypes.object,
  onOk: PropTypes.func,
  sendEmailMethod: PropTypes.func.isRequired
};

export default SendEmailModal;
