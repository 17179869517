import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { AUTHORITY_INVOICE_STATUS } from 'constants/invoice';

const InvoiceAdjustModal = ({ invoice, visible, signedForm04, onCancel, ...rest }) => {
  const history = useHistory();
  const [t] = useTranslation();
  const { authority_status } = invoice;
  const sentDoT = authority_status === AUTHORITY_INVOICE_STATUS.SUCCESS;
  const isReadyCreateAdjust = !sentDoT || signedForm04;

  const redirectCancellationNotificationPage = () => {
    onCancel();
    history.push('/invoice/cancellation-notification/create');
  };

  React.useEffect(() => {
    if (isReadyCreateAdjust && visible) {
      onCancel();
      history.push(`/invoice/${invoice.id}/adjust`);
    }
  }, [invoice, isReadyCreateAdjust, visible, history, onCancel]);

  if (isReadyCreateAdjust && visible) return <div />;
  return (
    <Modal
      okText={t('button.create-adjust-notification')}
      cancelText={t('button.cancel')}
      title={t('invoice.action.adjust')}
      onOk={redirectCancellationNotificationPage}
      visible={visible}
      onCancel={onCancel}
      {...rest}
    >
      <span>{t('invoice.adjust.notification')}</span>
    </Modal>
  );
};

InvoiceAdjustModal.defaultProps = {
  visible: false,
  signedForm04: false,
  invoice: {},
  onOK: () => {},
  onCancel: () => {}
};

InvoiceAdjustModal.propTypes = {
  visible: PropTypes.bool,
  signedForm04: PropTypes.bool,
  invoice: PropTypes.object,
  onOK: PropTypes.func,
  onCancel: PropTypes.func
};

export default InvoiceAdjustModal;
