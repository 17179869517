import { Modal } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/format';

const IssueInvoiceConfirmModal = props => {
  const [t] = useTranslation();
  return (
    <Modal okText="Phát hành" cancelText={t('button.cancel')} title="Phát hành?" {...props}>
      <span>{`Bạn có muốn phát hành hoá đơn này vào ngày ${formatDate(moment())}?`}</span>
    </Modal>
  );
};

export default IssueInvoiceConfirmModal;
