import React from 'react';
import { useHistory } from 'react-router-dom';
import DeleteModal from 'components/_share/DeleteModal';
import ExportFileModal from 'components/_share/ExportFileModal';
import SigningEntityModal from 'components/_share/SigningEntityModal';
import ViewCancellationPDFModal from 'components/_share/ViewCancellationNotificationPDFModal';
import { useDeleteCancellation } from 'queries/cancellationNotification';
import {
  exportCancellation,
  requestCancellationSignPayload,
  refreshCancellations
} from 'services/cancellationNotification';
import CancellationContext from './CancellationContext';

const CancellationContextWrapper = ({ children }) => {
  const history = useHistory();
  const [deleteIndex, setDeleteIndex] = React.useState(0);
  const [deleteModal, setDeleteModal] = React.useState({ visible: false });
  const [viewModal, setViewModal] = React.useState({ visible: false });
  const [exportModal, setExportModal] = React.useState({ visible: false });
  const closeModal = _func => _func({ visible: false });
  const [signingModal, setSigningModal] = React.useState({ visible: false });

  const editCancellation = ({ id }) => history.push(`/invoice/cancellation-notification/${id}/edit`);

  const viewCancellation = item => {
    setViewModal(prev => ({
      ...prev,
      data: item,
      visible: true
    }));
  };

  const deleteCancellation = item => {
    setDeleteModal(prev => ({
      ...prev,
      data: item,
      visible: true,
      onOk: () => {
        closeModal(setViewModal);
        closeModal(setDeleteModal);
        setDeleteIndex(Date.now());
      }
    }));
  };

  const downloadXml = item => {
    setExportModal({
      visible: true,
      params: [item.id, 'xml'],
      fileName: `thong_bao_huy_mau_04.xml`,
      exportFunc: exportCancellation
    });
  };

  const issueCancellation = cancellation => {
    return new Promise(resolve => {
      setSigningModal(prev => ({
        visible: true,
        payload: {
          id: cancellation.id,
          requestSignPayload: requestCancellationSignPayload
        },
        onOk: () => {
          closeModal(setSigningModal);
          refreshCancellations();
          setDeleteIndex(Date.now());
          resolve(true);
        },
        onCancel: () => {
          closeModal(setSigningModal);
          resolve();
        }
      }));
    });
  };

  return (
    <CancellationContext.Provider
      value={{
        deleteIndex,
        viewCancellation,
        editCancellation,
        deleteCancellation,
        issueCancellation,
        downloadXml
      }}
    >
      <SigningEntityModal onCancel={() => closeModal(setSigningModal)} {...signingModal} />
      <ExportFileModal {...exportModal} onCancel={() => closeModal(setExportModal)} />
      <ViewCancellationPDFModal {...viewModal} onCancel={() => closeModal(setViewModal)} />
      <DeleteModal
        {...deleteModal}
        successfulDeletionMsg="Đã xoá mẫu 04 thành công."
        deleteFunction={useDeleteCancellation}
        onCancel={() => closeModal(setDeleteModal)}
      />
      {children}
    </CancellationContext.Provider>
  );
};

export default CancellationContextWrapper;
