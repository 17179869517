import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Space, Tooltip, Button } from 'antd';
import FilterableHeaderTable from 'components/Table/FilterableHeaderTable';
import CancellationContext from 'pages/invoice/cancellationNotification/CancellationContext';
import { checkIfActionAvailable } from 'services/cancellationNotification';
import { formatDate } from 'utils/format';
import { ReactComponent as SignIcon } from 'assets/icons/action-sign.svg';
import { ReactComponent as ViewIcon } from 'assets/icons/action-view.svg';
import { ReactComponent as EditIcon } from 'assets/icons/action-edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/action-delete.svg';
import { ReactComponent as DownloadXMLIcon } from 'assets/icons/action-export-xml.svg';

const CancellationNotificationTable = ({ items, loading, onReload }) => {
  const [t] = useTranslation();
  const {
    viewCancellation,
    deleteIndex,
    downloadXml,
    editCancellation,
    deleteCancellation,
    issueCancellation
  } = React.useContext(CancellationContext);

  React.useEffect(() => {
    if (deleteIndex > 0) onReload();
  }, [deleteIndex, onReload]);
  return (
    <FilterableHeaderTable
      rowKey="id"
      size="small"
      loading={loading}
      pagination={false}
      dataSource={items}
      columns={[
        {
          title: 'Ngày phát hành',
          width: '30%',
          dataIndex: 'issued_date',
          render: v => formatDate(v)
        },
        {
          title: 'Cơ quan thuế nhận thông báo',
          width: '50%',
          dataIndex: 'tax_agency',
          render: value => value.name
        },
        {
          title: 'Thao tác',
          width: '20%',
          align: 'center',
          render: (_, r) => (
            <Space>
              <Button type="text" icon={<ViewIcon />} onClick={() => viewCancellation(r)} />
              <Tooltip title="Phát hành thông báo">
                <Button
                  type="text"
                  onClick={() => issueCancellation(r)}
                  disabled={!checkIfActionAvailable('issue', r.authority_status)}
                  style={{
                    opacity: checkIfActionAvailable('issue', r.authority_status) ? 1 : 0.5
                  }}
                  icon={<SignIcon />}
                />
              </Tooltip>
              <Tooltip title="Chỉnh sửa">
                <Button
                  type="text"
                  icon={<EditIcon />}
                  onClick={() => editCancellation(r)}
                  disabled={!checkIfActionAvailable('edit', r.authority_status)}
                />
              </Tooltip>
              <Tooltip title="Tải xuống XML">
                <Button type="text" onClick={() => downloadXml(r)} icon={<DownloadXMLIcon />} />
              </Tooltip>
              <Tooltip title={t('button.delete')}>
                <Button
                  type="text"
                  icon={<DeleteIcon />}
                  disabled={!checkIfActionAvailable('delete', r.authority_status)}
                  onClick={() => deleteCancellation(r)}
                />
              </Tooltip>
            </Space>
          )
        }
      ]}
    />
  );
};

CancellationNotificationTable.defaultProps = {
  loading: false
};

CancellationNotificationTable.propTypes = {
  onReload: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool
};
export default CancellationNotificationTable;
