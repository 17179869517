import React, { useState } from 'react';
import { Button, Upload, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { validateExcelFile, isFileSizeLessThan } from 'utils/validate';
import { ReactComponent as UploadIcon } from 'assets/icons/action-upload.svg';
import { ReactComponent as DefaultFileIcon } from 'assets/icons/action-default-file.svg';
import styles from './FileDraggerUpload.less';

const { Dragger } = Upload;
const FileDraggerUpload = ({ onOk, isLoading }) => {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [t] = useTranslation();

  const handleClear = () => {
    setFile();
    setFileName('');
    setDisabled(true);
    setErrorMessage('');
  };

  const getErrorCode = file => {
    setFileName(file.name);
    if (!validateExcelFile(file)) return 'File import không đúng định dạng.';
    if (!isFileSizeLessThan(file, 5, true)) return '	File import không quá 5MB.';
    return null;
  };

  const beforeUpload = file => {
    const error = getErrorCode(file);
    if (error) {
      setErrorMessage(error);
      setDisabled(true);
      return Promise.reject();
    }
    setErrorMessage('');
    setDisabled(false);
    setFile(file);
    return false;
  };

  const uploadProps = {
    multiple: false,
    showUploadList: false,
    accept: '.xls, .xlsx',
    beforeUpload
  };

  const renderBeforeUpload = (
    <div className={styles.beforeUploadWrapper}>
      <Dragger {...uploadProps}>
        <UploadIcon />
        <p className={styles.description}>{t('category.customer.import.uploadDescription')}</p>
        <p className={styles.errorMessage}>{errorMessage}</p>
        <p className={styles.or}>{t('category.customer.import.or')}</p>
        <Button type="primary" className={styles.uploadBtn}>
          {t('category.customer.import.uploadFile')}
        </Button>
      </Dragger>
    </div>
  );

  const renderAfterUpload = (
    <Spin spinning={isLoading}>
      <div className={styles.afterUploadWrapper}>
        <span className={styles.title}>{t('category.customer.import.importFile')}</span>
        <Dragger {...uploadProps}>
          <DefaultFileIcon />
          <Tooltip placement="top" title={fileName}>
            <span className={styles.dragText}>{fileName}</span>
          </Tooltip>
        </Dragger>
        <span className={styles.errorMessage}>{errorMessage}</span>
        <div className={styles.buttonWrapper}>
          <Button key="cancel" onClick={handleClear}>
            {t('button.cancel')}
          </Button>
          <Button key="submit" type="primary" disabled={disabled} onClick={() => onOk(file)}>
            {t('button.ok')}
          </Button>
        </div>
      </div>
    </Spin>
  );

  return file ? renderAfterUpload : renderBeforeUpload;
};

export default FileDraggerUpload;
