import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, Input } from 'antd';
import classNames from 'classnames';
import { TABLE_PAGE_SIZE_OPTION } from 'constant';
import styles from './FilterableHeaderTable.less';

const HeaderCell = props => {
  const { filter, sorter, filterRenderer, onFilter, className, children, hasTableFilter, ...restProps } = props;
  const shouldDisplayFilter = () =>
    filter &&
    className.indexOf('ant-table-selection-column') === -1 &&
    className.indexOf('ant-table-row-expand-icon-cell') === -1;

  const handleInputChange = ({ target }) => {
    const { value } = target;
    if (value === '') onFilter(value);
  };

  const renderFilterControl = () => {
    if (shouldDisplayFilter()) {
      return (
        <div onClick={e => e.stopPropagation()} style={{ padding: '8px 8px' }}>
          {filterRenderer && filterRenderer()}
          {!filterRenderer && (
            <Input
              allowClear
              size="small"
              onChange={handleInputChange}
              onClick={e => e.stopPropagation()}
              onPressEnter={({ target }) => onFilter(target.value)}
            />
          )}
        </div>
      );
    }
    if (restProps.title && hasTableFilter) return <div style={{ height: 42 }} />;
    return null;
  };

  return (
    <th className={classNames(className, { [styles.forceNoPadding]: !sorter })} {...restProps}>
      {children.map(child => {
        if (typeof child !== 'string') return child;
        return <span style={{ padding: '8px 8px', display: 'inline-block' }}>{child}</span>;
      })}
      {renderFilterControl()}
    </th>
  );
};

const FilterableHeaderTable = ({ columns, pagination, ...restProps }) => {
  const customizedColumns = useMemo(
    () =>
      columns.map(col => ({
        ...col,
        children: col.children && col.children.map(child => ({ ...child, sorter: false })),
        onHeaderCell: column => ({
          ...column,
          title: col.title,
          hasTableFilter: columns.some(item => item.filter === true)
        })
      })),
    [columns]
  );

  const paginateConfig = useMemo(() => {
    if (typeof pagination === 'boolean') return pagination;
    return {
      showTotal: (t, r) => `${r[0]}-${r[1]}/${t}`,
      hideOnSinglePage: false,
      showSizeChanger: true,
      pageSizeOptions: TABLE_PAGE_SIZE_OPTION,
      ...pagination
    };
  }, [pagination]);

  return (
    <Table
      style={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)' }}
      pagination={paginateConfig}
      {...restProps}
      components={{ header: { cell: HeaderCell } }}
      columns={customizedColumns}
      locale={{ emptyText: 'Không tìm thấy dữ liệu' }}
    />
  );
};

HeaderCell.defaultProps = {
  filter: false,
  sorter: false,
  filterRenderer: '',
  onFilter: '',
  className: ''
};

HeaderCell.propTypes = {
  filter: PropTypes.bool,
  sorter: PropTypes.bool,
  filterRenderer: PropTypes.any,
  onFilter: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.any
};

FilterableHeaderTable.defaultProps = {
  pagination: {},
  columns: []
};

FilterableHeaderTable.propTypes = {
  pagination: PropTypes.any,
  columns: PropTypes.array
};

export default FilterableHeaderTable;
