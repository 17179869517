import { addIndex, flatten, map, pipe, reduce, replace, split, splitEvery } from 'ramda';
import { getToken } from '../utils/authority';
import showResponseError from './showResponseError';

const VIETNAMESE_UNIGNS = 'aAeEoOuUiIdDyY';

const VIETNAMESE_SIGNS = [
  'áàạảãâấầậẩẫăắằặẳẵ',
  'ÁÀẠẢÃÂẤẦẬẨẪĂẮẰẶẲẴ',
  'éèẹẻẽêếềệểễ',
  'ÉÈẸẺẼÊẾỀỆỂỄ',
  'óòọỏõôốồộổỗơớờợởỡ',
  'ÓÒỌỎÕÔỐỒỘỔỖƠỚỜỢỞỠ',
  'úùụủũưứừựửữ',
  'ÚÙỤỦŨƯỨỪỰỬỮ',
  'íìịỉĩ',
  'ÍÌỊỈĨ',
  'đ',
  'Đ',
  'ýỳỵỷỹ',
  'ÝỲỴỶỸ'
];

const getCharacterMappingByIndex = (val, idx) =>
  pipe(
    splitEvery(1),
    map(item => ({ key: item, value: val }))
  )(VIETNAMESE_SIGNS[idx]);

const getSignCharacterMappings = () =>
  pipe(
    splitEvery(1),
    addIndex(map)((val, idx) => getCharacterMappingByIndex(val, idx)),
    flatten
  )(VIETNAMESE_UNIGNS);

const removeVietnameseSigns = value => {
  return reduce((acc, { key, value }) => replace(key, value, acc), value, getSignCharacterMappings());
};

const normalizeFilename = filename => pipe(replace(/\//g, '-'), replace(/ /g, '-'))(removeVietnameseSigns(filename));

const forceDownload = (blob, filename) => {
  const anchor = document.createElement('a');
  anchor.download = filename;
  anchor.href = blob;
  document.body.appendChild(anchor);
  anchor.click();
  anchor.remove();
};

export default (url, filename, ignoreToken = true) => {
  const header = {
    Origin: window.location.origin,
    Authorization: `Bearer ${getToken()}`
  };
  if (ignoreToken) delete header.Authorization;
  window
    .fetch(url, {
      headers: new Headers(header),
      mode: 'cors'
    })
    .then(async response => {
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const remoteFilename = response.headers.get('content-disposition') || '';
      if (!filename) {
        const splittedContentDispositions = split('=', remoteFilename);
        forceDownload(
          blobUrl,
          splittedContentDispositions.length > 1 ? splittedContentDispositions[1] : remoteFilename
        );
        return;
      }

      const splittedContentDispositions = split('.', remoteFilename);
      const extension = splittedContentDispositions.length > 1 ? splittedContentDispositions[1] : '';
      const splittedFiles = split('.', filename);

      forceDownload(
        blobUrl,
        normalizeFilename(splittedFiles.length > 1 ? filename : extension ? `${filename}.${extension}` : filename)
      );
    })
    .catch(error => showResponseError({ error }));
};
