import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ModalContext from './ModalContext';

export default ({ modalMap, children }) => {
  const [modal, setModal] = useState({});
  const [visible, setVisible] = useState(true);

  const location = useLocation();

  /**
   * Open modal
   * @param type ModalType
   * @param props modal props
   */
  const openModal = useCallback((type, props, Wrapper) => {
    setModal({
      type,
      props,
      Wrapper
    });
    setVisible(true);
  }, []);

  /**
   * Close modal by setting visibility
   */
  const closeModal = useCallback(() => setVisible(false), []);

  /**
   * Remove modal from the DOM
   */
  const destroyModal = useCallback(() => setModal({}), []);

  useEffect(() => {
    destroyModal();
  }, [location, destroyModal]);

  const contextValue = useMemo(
    () => ({
      openModal,
      closeModal,
      destroyModal
    }),
    [openModal, closeModal, destroyModal]
  );

  const renderModal = () => {
    const { type, props, Wrapper } = modal;
    const ModalComponent = modalMap[type];
    if (!ModalComponent) return null;

    const node = <ModalComponent {...props} visible={visible} onCancel={closeModal} afterClose={destroyModal} />;
    if (Wrapper) return <Wrapper>{node}</Wrapper>;
    return node;
  };
  return (
    <>
      <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>
      {renderModal()}
    </>
  );
};
