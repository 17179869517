import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const DeleteModal = ({ onOk, isLoading, ...rest }) => {
  const [t] = useTranslation();
  return (
    <Modal
      okText={t('button.delete')}
      cancelText={t('button.cancel')}
      title={t('confirm.delete-title')}
      confirmLoading={isLoading}
      onOk={onOk}
      {...rest}
    >
      <span>{t('confirm.delete-message')}</span>
    </Modal>
  );
};

export default DeleteModal;
