import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Space, Spin } from 'antd';
import PDFViewer from 'components/PDF/PDFViewer';
import usePollingExportReport from 'hooks/usePollingExportReport';
import { exportEntityById } from 'services/export';
import styles from './InvoicePreviewModal.less';

const InvoicePreviewModal = ({ id, ...rest }) => {
  const [t] = useTranslation();
  const [{ url, loading }] = usePollingExportReport({
    exportFunc: exportEntityById,
    args: [id]
  });

  return (
    <Modal width="1190px" className={styles.modal} title={<Space>&nbsp;</Space>} footer={null} {...rest}>
      <div className={styles.pdfViewer}>
        {loading && <Spin spinning={loading} style={{ padding: 20 }} tip={t('invoice.is-downloading')} />}
        {!loading && <PDFViewer file={url} />}
      </div>
    </Modal>
  );
};

export default InvoicePreviewModal;
