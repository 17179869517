import React from 'react';
import FilterableHeaderTable from 'components/Table/FilterableHeaderTable';
import { formatNumber } from 'utils/format';

const InvoiceTransportationSlipProductTable = ({ records = [], total, pagination, onChange, onRowSelection }) => (
  <FilterableHeaderTable
    bordered
    dataSource={records}
    size="small"
    pagination={{ ...pagination, total }}
    onChange={onChange}
    rowSelection={{
      onChange: onRowSelection
    }}
    columns={[
      {
        title: 'Mã sản phẩm',
        dataIndex: 'code',
        key: 'code',
        width: '100px',
        align: 'center'
      },
      {
        title: 'Tên nhãn hiệu, quy cách, phẩm chất vật tư (sản phẩm, hàng hóa)',
        dataIndex: 'name',
        key: 'name',
        width: '30%',
        align: 'center'
      },
      {
        title: 'Đơn vị tính',
        dataIndex: 'unit',
        key: 'unit',
        align: 'center',
        width: '100px'
      },
      {
        title: 'Số lượng',
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'right',
        width: '100px',
        render: value => value && formatNumber(value)
      },
      {
        title: 'Đơn giá',
        dataIndex: 'price',
        key: 'price',
        width: '20%',
        align: 'right',
        render: value => value && formatNumber(value)
      },
      {
        title: 'Thành tiền',
        dataIndex: 'amount',
        key: 'amount',
        width: '20%',
        align: 'right',
        render: value => value && formatNumber(value)
      }
    ]}
    rowKey="id"
  />
);

export default InvoiceTransportationSlipProductTable;
