import { Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import rules from 'utils/rules';
import FormattedNumberInput from 'components/Input/FormattedNumberInput';

const ProductForm = ({ form, product = {} }) => {
  const [t] = useTranslation();

  const TAXES = [
    {
      label: t('category.product.taxes.none'),
      value: 'NONE'
    },
    {
      label: t('category.product.taxes.zero'),
      value: 'ZERO'
    },
    {
      label: t('category.product.taxes.five'),
      value: 'FIVE'
    },
    {
      label: t('category.product.taxes.ten'),
      value: 'TEN'
    }
  ];

  useEffect(() => {
    form.setFieldsValue(product);
  }, [form, product]);

  return (
    <Form form={form} colon={false} layout="vertical">
      <Form.Item label={t('category.product.name')} name="name" rules={rules.productName}>
        <Input placeholder={t('category.product.name-placeholder')} />
      </Form.Item>

      <Row gutter={10}>
        <Col span={12}>
          <Form.Item label={t('category.product.product-id')} name="code" rules={rules.productCode}>
            <Input placeholder={t('category.product.product-id-placeholder')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t('category.product.unit')} name="unit" rules={rules.unit}>
            <Input placeholder={t('category.product.unit-placeholder')} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col span={12}>
          <Form.Item label={t('category.product.price')} name="price">
            <FormattedNumberInput />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t('category.product.tax')} name="vat_rate">
            <Select style={{ width: '100%' }}>
              {TAXES.map(({ value, label }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ProductForm;
