import React from 'react';

const PaymentCurrencyContext = React.createContext({ setPaymentCurrency: () => null });
export const PaymentCurrencyContextProvider = ({ children }) => {
  const [paymentCurrency, setPaymentCurrency] = React.useState('VND');

  return (
    <PaymentCurrencyContext.Provider value={{ paymentCurrency, setPaymentCurrency }}>
      {children}
    </PaymentCurrencyContext.Provider>
  );
};

export default PaymentCurrencyContext;
