import React, { useState } from 'react';
import { Button, Upload, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UploadIcon } from 'assets/icons/action-upload.svg';
import { ReactComponent as DefaultFileIcon } from 'assets/icons/action-default-file.svg';
import styles from './FileDraggerUpload.less';

const { Dragger } = Upload;

const FileDraggerUpload = ({ onOk, isLoading }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [t] = useTranslation();

  const handleOk = () => {
    if (file) onOk(file);
  };
  const handleClear = () => {
    setFile(null);
    setFileName('');
    setDisabled(true);
    setErrorMessage('');
  };

  const isExcel = type => {
    const acceptedFileTypes = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];
    return acceptedFileTypes.includes(type);
  };

  const isLargerThan5Mb = size => {
    return size / 1024 / 1024 > 5;
  };

  const getErrorCode = file => {
    const { size, type, name } = file;
    setFileName(name);
    if (!isExcel(type)) return 'File import không đúng định dạng.';
    if (isLargerThan5Mb(size)) return '	File import không quá 5MB.';
    return null;
  };

  const beforeUpload = file => {
    const error = getErrorCode(file);
    if (error) {
      setErrorMessage(error);
      setDisabled(true);
      return Promise.reject();
    } else {
      setErrorMessage('');
      setDisabled(false);
      setFile(file);
      return false;
    }
  };

  const uploadProps = {
    className: styles.draggerWrapper,
    multiple: false,
    showUploadList: false,
    accept: '.xls, .xlsx',
    beforeUpload
  };

  const renderBeforeUpload = (
    <div className={styles.beforeUploadWrapper}>
      <Dragger {...uploadProps}>
        <UploadIcon />
        <p className={styles.description}>{t('category.product.import.uploadDescription')}</p>
        <p className={styles.errorMessage}>{errorMessage}</p>
        <p className={styles.or}>{t('category.product.import.or')}</p>
        <Button type="primary" className={styles.uploadBtn}>
          {t('category.product.import.uploadFile')}
        </Button>
      </Dragger>
    </div>
  );

  const renderAfterUpload = (
    <Spin spinning={isLoading}>
      <div className={styles.afterUploadWrapper}>
        <span className={styles.title}>{t('category.product.import.importFile')}</span>
        <Dragger {...uploadProps}>
          <DefaultFileIcon />
          <Tooltip placement="top" title={fileName}>
            <span className={styles.dragText}>{fileName}</span>
          </Tooltip>
        </Dragger>
        <span className={styles.errorMessage}>{errorMessage}</span>
        <div className={styles.buttonWrapper}>
          <Button key="cancel" onClick={handleClear}>
            {t('button.cancel')}
          </Button>
          <Button key="submit" type="primary" disabled={disabled} onClick={handleOk}>
            {t('button.ok')}
          </Button>
        </div>
      </div>
    </Spin>
  );

  return file ? renderAfterUpload : renderBeforeUpload;
};

export default FileDraggerUpload;
