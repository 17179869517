import { queryCache, useMutation, usePaginatedQuery, useQuery } from 'react-query';
import {
  createInvoice,
  getInvoice,
  getInvoices,
  updateInvoice,
  requestInvoiceSignPayload,
  sendInvoiceToCustomer,
  deleteInvoice,
  cancelInvoice,
  replaceInvoice,
  adjustInvoice,
  cloneInvoice,
  getCancellationsByInvoiceId,
  importInvoiceProductLines,
  convertInvoice,
  calculateInvoiceUsageByRange,
  previewInvoice,
  getInvoiceQuota,
  isInvoiceExistInForm04,
  previewInvoiceById,
  importInvoicesByExcel
} from 'services/invoice';
import { showResponseError } from 'functions';
import { extractSerialNumber, signEntity, validateIfSigningReady } from 'services/signing';
import { sendEsignerMessage } from 'services/esigner';
import { signBase64Message } from 'services/signature';

const paginatedKey = params => ['invoices', params];
const listKey = () => ['invoices'];
const singleKey = id => ['invoice', id];
const cancellationKey = 'invoice-cancellations';

/**
 * Get invoices hook.
 */
export const useInvoices = params => {
  const f = () => getInvoices(params);
  return usePaginatedQuery(paginatedKey(params), f);
};

/**
 * Get an invoice hook.
 */
export const useInvoice = (id, options) => {
  return useQuery(singleKey(id), () => getInvoice(id), options);
};

/**
 * Create an invoice hook.
 */
export const useCreateInvoice = () =>
  useMutation(invoice => createInvoice(invoice), {
    onSettled: () => {
      queryCache.invalidateQueries(listKey());
    }
  });

/**
 * Create an invoice hook.
 */
export const useUpdateInvoice = () =>
  useMutation(invoice => updateInvoice(invoice.id, invoice), {
    onSuccess: invoice => {
      queryCache.setQueryData(singleKey(invoice.id), invoice);
      queryCache.invalidateQueries(singleKey(invoice.id));
      queryCache.invalidateQueries(listKey());
    }
  });

/**
 * Send email to customer hook.
 */
export const useSendInvoiceToCustomer = () => useMutation(({ id, emails }) => sendInvoiceToCustomer(id, emails));

/**
 * Delete an invoice hook.
 */
export const useDeleteInvoice = () =>
  useMutation(id => deleteInvoice(id), {
    onMutate: id => {
      queryCache.cancelQueries(singleKey(id));
      const prevInvoice = queryCache.getQueryData(singleKey(id));
      return () => queryCache.setQueryData(singleKey(id), prevInvoice);
    },
    onError: (err, newTodo, rollback) => {
      showResponseError(err);
      rollback();
    },
    onSuccess: id => {
      queryCache.removeQueries(singleKey(id));
      queryCache.invalidateQueries(listKey());
    }
  });

/**
 * Cancel invoice hook.
 */
export const useCancelInvoice = () =>
  useMutation(({ id, data = {} }) => cancelInvoice(id, data), {
    onSuccess: () => {
      queryCache.invalidateQueries(listKey());
    }
  });

/**
 * Create an replacement invoice hook.
 */
export const useReplaceInvoice = id =>
  useMutation(invoice => replaceInvoice(id, invoice), {
    onSuccess: invoice => {
      queryCache.setQueryData(singleKey(id), invoice);
      queryCache.invalidateQueries(singleKey(id));
      queryCache.invalidateQueries(listKey());
    }
  });

/**
 * Adjust an invoice hook.
 */
export const useAdjustInvoice = () =>
  useMutation(invoice => adjustInvoice(invoice.id, invoice), {
    onSuccess: invoice => {
      queryCache.setQueryData(singleKey(invoice.id), invoice);
      queryCache.invalidateQueries(singleKey(invoice.id));
      queryCache.invalidateQueries(listKey());
    }
  });

/**
 * Clone an invoice hook.
 */
export const useCloneInvoice = () =>
  useMutation(id => cloneInvoice(id), {
    onSuccess: () => {
      queryCache.invalidateQueries(listKey());
    }
  });

/**
 * Get cancellations by invoice id hook.
 */
export const useCancellationsByInvoiceId = (id, options) => {
  return useQuery(cancellationKey, () => getCancellationsByInvoiceId(id), options);
};

/**
 * Import invoice product lines
 */
export const useImportProductLines = () => useMutation(id => importInvoiceProductLines(id));

/**
 * Import invoice product lines
 */
export const useImportInvoices = () => useMutation(params => importInvoicesByExcel(...params));

/**
 * Convert invoice hook.
 */
export const useConvertInvoice = () =>
  useMutation(({ id, data = {} }) => convertInvoice(id, data), {
    onSuccess: () => {
      queryCache.invalidateQueries(listKey());
    }
  });

/**
 * Issue invoice hook.
 */
export const useIssueInvoice = () =>
  useMutation(
    async ({ id, forceSign = false, recipients = [] }) => {
      const certPayload = await validateIfSigningReady();
      const serialNumber = extractSerialNumber(certPayload);
      const requestedPayload = await requestInvoiceSignPayload(id, serialNumber, forceSign, recipients);
      const signedPayload = await sendEsignerMessage(signBase64Message(requestedPayload));
      const { CERT_CHAIN, SIGNED_CONTENT, CERT, PIN_CODE } = signedPayload;
      return signEntity(requestedPayload.id, {
        id: requestedPayload.id,
        payload: requestedPayload.payload,
        signature: SIGNED_CONTENT,
        cert: CERT,
        cert_chain: CERT_CHAIN,
        pin_code: PIN_CODE,
        signature_path: requestedPayload.signature_path
      });
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('invoices');
      }
    }
  );

/**
 * Calculate invoice usage by range hook.
 */
export const useCalculateInvoiceUsageByRange = () => useMutation(data => calculateInvoiceUsageByRange(data));

/**
 * Preview invoice hook.
 */
export const usePreviewInvoice = () => useMutation(invoice => previewInvoice(invoice));

/**
 * Preview invoice hook.
 */
export const usePreviewInvoiceById = (id, status) => useMutation(invoice => previewInvoiceById(id, invoice, status));

export const useInvoiceQuota = () => useQuery('invoice-quota', () => getInvoiceQuota());

export const useInvoiceExistInForm04 = () => useMutation(({ ids, context }) => isInvoiceExistInForm04(ids, context));
