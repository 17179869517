import { Modal } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/format';

const InvoiceSummaryIssueModal = ({ isLoading, ...rest }) => {
  const [t] = useTranslation();
  return (
    <Modal
      okText="Phát hành"
      cancelText={t('button.cancel')}
      title="Phát hành?"
      confirmLoading={isLoading}
      closable={false}
      {...rest}
    >
      <span>{`Bạn có muốn phát hành báo cáo hoá đơn này vào ngày ${formatDate(moment())}?`}</span>
    </Modal>
  );
};

export default InvoiceSummaryIssueModal;
