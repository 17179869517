function _isElement(element) {
  return element instanceof Element || element instanceof HTMLDocument;
}

function _waitingElementReady(callback, { query, max = 3000 }) {
  let interval;
  let count = 0;
  function _isElementReady() {
    const nextPost = document.querySelector(query);
    return !!nextPost;
  }

  interval = setInterval(() => {
    count += 200;
    const isTimeout = count > max;
    if (_isElementReady() || isTimeout) {
      clearInterval(interval);
      !isTimeout && callback();
    }
  }, 200);
}

function _scrollToTargetAdjusted(element) {
  const yOffset = -40;
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ top: y, behavior: 'smooth' });
}

function scrollToFirstFormItemError(context) {
  let firstFormItemError = null;
  const query = '.ant-form-item-explain-error';
  _waitingElementReady(
    () => {
      if (!_isElement(context)) {
        firstFormItemError = document.querySelectorAll(query)[0];
      } else {
        firstFormItemError = context.querySelectorAll(query)[0];
      }
      if (!firstFormItemError) return;
      _scrollToTargetAdjusted(firstFormItemError.parentNode);
    },
    { query }
  );
}

export default scrollToFirstFormItemError;
