import React from 'react';
import { Route, Switch } from 'react-router-dom';
import withLoadable from 'utils/withLoadable';
import { PaymentCurrencyContextProvider } from 'components/_share/Invoice/PaymentCurrencyContext';
import InvoiceContextWrapper from './InvoiceContextWrapper';

const CreateInvoicePage = withLoadable(() => import('pages/invoice/create'));
const InvoiceListPage = withLoadable(() => import('pages/invoice/list'));
const EditInvoicePage = withLoadable(() => import('pages/invoice/edit'));
const ReplacedInvoicePage = withLoadable(() => import('pages/invoice/replace/list'));
const ReplaceInvoicePage = withLoadable(() => import('pages/invoice/replace'));
const AdjustedInvoicePage = withLoadable(() => import('pages/invoice/adjust/list'));
const AdjustInvoicePage = withLoadable(() => import('pages/invoice/adjust'));
const CancelledInvoicePage = withLoadable(() => import('pages/invoice/cancel'));
const ImportInvoicePage = withLoadable(() => import('pages/invoice/import'));
const CommercialInvoiceRouter = withLoadable(() => import('pages/invoice/commercial/Router'));
const TemplateRouter = withLoadable(() => import('pages/invoice/register/template/TemplateRouter'));
const IssuedTemplateRouter = withLoadable(() => import('pages/invoice/register/issuedTemplate/IssuedTemplateRouter'));
const IssuanceNoticeRouter = withLoadable(() => import('pages/invoice/register/issuanceNotice/IssuanceNoticeRouter'));
const DecisionApplicationRouter = withLoadable(() =>
  import('pages/invoice/register/decisionApplication/DecisionApplicationRouter')
);
const CancellationNotificationRouter = withLoadable(() =>
  import('pages/invoice/cancellationNotification/CancellationNotificationRouter')
);
const RegistrationFormRouter = withLoadable(() =>
  import('pages/invoice/register/registrationForm/RegistrationFormRouter')
);
const DestroyingAnnouncementRouter = withLoadable(() => import('pages/invoice/register/destroyingAnnouncement/Router'));

const InvoiceRouter = () => (
  <InvoiceContextWrapper>
    <PaymentCurrencyContextProvider>
      <Switch>
        <Route path="/invoice/register/registration-form" component={RegistrationFormRouter} />
        <Route path="/invoice/register/template" component={TemplateRouter} />
        <Route path="/invoice/register/issued-template" component={IssuedTemplateRouter} />
        <Route path="/invoice/register/issuance-notice" component={IssuanceNoticeRouter} />
        <Route path="/invoice/register/decision-application" component={DecisionApplicationRouter} />
        <Route path="/invoice/register/destroying-announcement" component={DestroyingAnnouncementRouter} />
        <Route path="/invoice/commercial" component={CommercialInvoiceRouter} />
        <Route path="/invoice/cancellation-notification" component={CancellationNotificationRouter} />
        <Route path="/invoice/:id/edit" component={EditInvoicePage} />
        <Route path="/invoice/:id/replace" component={ReplaceInvoicePage} />
        <Route path="/invoice/:id/adjust" component={AdjustInvoicePage} />
        <Route path="/invoice/create" component={CreateInvoicePage} />
        <Route path="/invoice/cancel" component={CancelledInvoicePage} />
        <Route path="/invoice/adjust" component={AdjustedInvoicePage} />
        <Route path="/invoice/replace" component={ReplacedInvoicePage} />
        <Route path="/invoice/import" component={ImportInvoicePage} />
        <Route path="/invoice" component={InvoiceListPage} />
      </Switch>
    </PaymentCurrencyContextProvider>
  </InvoiceContextWrapper>
);

export default InvoiceRouter;
