import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import request from 'utils/request';
import buildQueryParams from 'utils/buildQueryParams';
import { getFileExtension } from 'utils';

export const getAppDecisions = params => request.get(`/decisions`, { params: buildQueryParams(params) });
export const convertDraftJsToJson = draftObj => draftToHtml(convertToRaw(draftObj.getCurrentContent()));
export const convertTextToDraftJs = text => EditorState.createWithContent(ContentState.createFromText(text));
export const getDecisionApplications = params => request.get('/decisions', { params: buildQueryParams(params) });
export const createDecisionApplication = data => request.post('/decisions', data);
export const getDecisionApplication = id => request.get(`/decisions/${id}`);
export const editDecisionApplication = data => request.put(`/decisions/${data.id}`, data);
export const deleteDecisionApplication = id => request.delete(`/decisions/${id}`);

export const convertJsonTextToDraftJs = text => {
  if (!text) {
    return undefined;
  }
  const blocksFromHtml = htmlToDraft(text);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  return EditorState.createWithContent(contentState);
};

export const exportDecisionApplication = (id, format = 'pdf') => {
  return request.post(
    `/decisions/${id}/export`,
    { id },
    {
      params: { format }
    }
  );
};

export const requestUploadUrl = (id, file_name, format) => {
  const extension = format || getFileExtension(file_name, 'pdf');
  return request.post(
    `/decisions/${id}/documents`,
    { file_name },
    {
      params: { format: extension }
    }
  );
};
