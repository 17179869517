import React from 'react';

const LayoutContent = ({ fullScreen, style, ...props }) => (
  <div
    {...props}
    style={{
      width: '100%',
      maxWidth: fullScreen ? '100%' : 1200,
      margin: '0 auto',
      paddingLeft: 20,
      paddingRight: 20,
      ...style
    }}
  />
);

export default LayoutContent;
