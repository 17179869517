import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { download } from 'functions';
import { ReactComponent as DeleteIcon } from 'assets/icons/action-delete.svg';
import { ReactComponent as AttachIcon } from 'assets/icons/action-attach.svg';
import styles from './index.less';

const AttachmentLineItem = ({ src, onRemove, disabled, customDownloadFile, ...props }) => {
  const isFile = src instanceof File;

  const handleDownloadFile = () => {
    if (!isFile) customDownloadFile(src);
  };

  return (
    src && (
      <div className={styles.attachmentLineItem} {...props}>
        <div className={styles.attachmentName}>
          <AttachIcon />
          <div type="link" onClick={handleDownloadFile}>
            {isFile ? src.name : 'tệp đính kèm'}
          </div>
        </div>
        {!disabled && (
          <div className={styles.removeIcon}>
            <Tooltip title="Xoá">
              <Button type="text" icon={<DeleteIcon />} onClick={onRemove} />
            </Tooltip>
          </div>
        )}
      </div>
    )
  );
};

AttachmentLineItem.defaultProps = {
  src: '',
  disabled: false,
  onRemove: () => {},
  customDownloadFile: download
};

AttachmentLineItem.propTypes = {
  disabled: PropTypes.bool,
  src: PropTypes.any,
  onRemove: PropTypes.func,
  customDownloadFile: PropTypes.func
};

export default AttachmentLineItem;
