import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Spin } from 'antd';
import PDFViewer from 'components/PDF/PDFViewer';
import usePollingExportReport from 'hooks/usePollingExportReport';
import { exportConvertedInvoice } from 'services/invoice';
import ConvertedInvoiceActions from './ConvertedInvoiceActions';
import styles from './ViewConvertedInvoiceModal.less';

const ViewConvertedInvoiceModal = ({ invoice, ...rest }) => {
  const [t] = useTranslation();
  const [{ url, loading }] = usePollingExportReport({
    exportFunc: exportConvertedInvoice,
    args: [invoice.id]
  });

  return (
    <Modal
      width="1190px"
      className={styles.modal}
      footer={null}
      title={<ConvertedInvoiceActions invoice={invoice} download_url={url} />}
      {...rest}
    >
      <div className={styles.pdfViewer}>
        {loading && <Spin spinning={loading} style={{ padding: 20 }} tip={t('invoice.is-downloading')} />}
        {!loading && <PDFViewer file={url} />}
      </div>
    </Modal>
  );
};

export default ViewConvertedInvoiceModal;
