import React from 'react';
import { Spin, Result } from 'antd';
import classNames from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './index.less';

export default ({ loading = true, tip, error = false }) => (
  <div className={styles.splash}>
    <div className={classNames(styles.inner, { [styles.error]: error })}>
      {error && <Result status="warning" title={tip} />}
      <Spin
        spinning={error ? false : loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
        style={{ marginTop: 16 }}
        tip={tip}
      />
    </div>
  </div>
);
