import { Checkbox } from 'antd';
import React from 'react';
import FilterableHeaderTable from 'components/Table/FilterableHeaderTable';
import { formatNumber } from 'utils/format';
import { useTranslation } from 'react-i18next';

const InvoiceVATProductTable = ({ records = [], total, pagination, onChange, onRowSelection }) => {
  const [t] = useTranslation();
  return (
    <FilterableHeaderTable
      bordered
      dataSource={records}
      size="small"
      pagination={{ ...pagination, total }}
      onChange={onChange}
      rowSelection={{
        onChange: onRowSelection
      }}
      columns={[
        {
          title: 'Mã sản phẩm',
          dataIndex: 'code',
          key: 'code',
          width: '100',
          align: 'center'
        },
        {
          title: 'Tên hàng hóa',
          dataIndex: 'name',
          key: 'name',
          width: '100',
          align: 'center'
        },
        {
          title: 'Đơn vị tính',
          dataIndex: 'unit',
          key: 'unit',
          width: '100',
          align: 'center'
        },
        {
          title: 'Số lượng',
          dataIndex: 'quantity',
          key: 'quantity',
          width: '70',
          align: 'right',
          render: value => value && formatNumber(value)
        },
        {
          title: 'Đơn giá',
          dataIndex: 'price',
          key: 'price',
          width: '100',
          align: 'right',
          render: value => value && formatNumber(value)
        },
        {
          title: '% CK',
          dataIndex: ['extra_fields', 'discount_rate'],
          key: ['extra_fields', 'discount_rate'],
          width: '80',
          align: 'right',
          render: value => value && `${formatNumber(value)}%`
        },
        {
          title: 'Số tiền CK',
          dataIndex: ['discount_amount'],
          key: ['discount_amount'],
          width: '100',
          align: 'right',
          render: value => value && formatNumber(value)
        },
        {
          title: 'Thành tiền',
          dataIndex: 'amount',
          key: 'amount',
          width: '100',
          align: 'right',
          render: value => value && formatNumber(value)
        },
        {
          title: 'Thuế suất GTGT',
          dataIndex: 'vat_rate',
          key: 'vat_rate',
          width: '150',
          align: 'center',
          render: vat_rate => vat_rate && t(`category.product.taxes.${vat_rate.toLowerCase()}`)
        },
        {
          title: 'Khuyến mãi',
          dataIndex: ['extra_fields', 'promoted'],
          key: ['extra_fields', 'promoted'],
          width: '100',
          align: 'center',
          render: v => <Checkbox checked={v === 'true'} />
        }
      ]}
      rowKey="id"
    />
  );
};

export default InvoiceVATProductTable;
