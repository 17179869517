import React from 'react';
import { debounce } from 'lodash';
import { isMobileScreen } from 'utils';

export default function useDetectMobileScreen() {
  const [isMobile, setIsMobile] = React.useState(() => isMobileScreen());

  const reCheck = debounce(() => {
    setIsMobile(isMobileScreen());
  }, 500);

  React.useEffect(() => {
    window.addEventListener('resize', reCheck);
    return () => {
      window.removeEventListener('resize', reCheck);
    }; // eslint-disable-next-line
  }, []);

  return [isMobile];
}
