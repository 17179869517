import React from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';
import { ReactComponent as UploadIcon } from 'assets/icons/action-upload.svg';
import styles from './drag-upload.less';

const DragUpload = ({ description, preventUpload, onChange, ...props }) => {
  const beforeUpload = file => {
    onChange([file]);
    return preventUpload ? Upload.LIST_IGNORE : false;
  };

  return (
    <Upload.Dragger beforeUpload={beforeUpload} {...props} className={styles.upload}>
      <p className="ant-upload-drag-icon">
        <UploadIcon />
      </p>
      <p className="ant-upload-text">{description || 'Kéo thả file PDF vào đây hoặc chọn file PDF'}</p>
    </Upload.Dragger>
  );
};

DragUpload.defaultProps = {
  description: 'Kéo thả file PDF vào đây hoặc chọn file PDF',
  preventUpload: false,
  onChange: () => {}
};

DragUpload.propTypes = {
  description: PropTypes.string,
  preventUpload: PropTypes.bool,
  onChange: PropTypes.func
};

export default DragUpload;
