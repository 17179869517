import React from 'react';
import FilterableHeaderTable from 'components/Table/FilterableHeaderTable';
import { Checkbox } from 'antd';
import { formatNumber } from 'utils/format';

const InvoiceSaleProductTable = ({ records = [], total, pagination, onChange, onRowSelection }) => (
  <FilterableHeaderTable
    bordered
    dataSource={records}
    size="small"
    pagination={{ ...pagination, total }}
    onChange={onChange}
    rowSelection={{
      onChange: onRowSelection
    }}
    columns={[
      {
        title: 'Mã sản phẩm',
        dataIndex: 'code',
        key: 'code',
        width: '100',
        align: 'center'
      },
      {
        title: 'Tên hàng hóa',
        dataIndex: 'name',
        key: 'name',
        width: '100',
        align: 'center'
      },
      {
        title: 'Đơn vị tính',
        dataIndex: 'unit',
        key: 'unit',
        width: '100',
        align: 'center'
      },
      {
        title: 'Số lượng',
        dataIndex: 'quantity',
        key: 'quantity',
        width: '100',
        align: 'right',
        render: value => value && formatNumber(value)
      },
      {
        title: 'Đơn giá',
        dataIndex: 'price',
        key: 'price',
        width: '100',
        align: 'right',
        render: price => price && formatNumber(price)
      },
      {
        title: '% CK',
        dataIndex: ['extra_fields', 'discount_rate'],
        key: ['extra_fields', 'discount_rate'],
        width: '100',
        align: 'right',
        render: value => value && `${formatNumber(value)}%`
      },
      {
        title: 'Số tiền CK',
        dataIndex: ['discount_amount'],
        key: ['discount_amount'],
        width: '100',
        align: 'right',
        render: value => value && formatNumber(value)
      },
      {
        title: 'Thành tiền',
        dataIndex: 'amount',
        key: 'amount',
        width: '100',
        align: 'right',
        render: value => value && formatNumber(value)
      },
      {
        title: 'Khuyến mãi',
        dataIndex: ['extra_fields', 'promoted'],
        key: ['extra_fields', 'promoted'],
        width: '100',
        align: 'center',
        render: v => <Checkbox checked={v === 'true'} />
      }
    ]}
    rowKey="id"
  />
);

export default InvoiceSaleProductTable;
