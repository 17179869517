import { Button } from 'antd';
import React from 'react';
import { ReactComponent as SignIcon } from 'assets/icons/action-sign.svg';
import styles from './AttachmentFileUpload.less';

const AttachmentFileUpload = ({ file }) => (
  <div className={styles.attachmentFileUpload}>
    <SignIcon height={50} width={50} />
    <Button type="link" className={styles.uploadLink}>
      {file.name}
    </Button>
  </div>
);

export default AttachmentFileUpload;
