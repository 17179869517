import { message } from 'antd';

const showMessage = ({ type = 'success', content, ...rest }) => {
  return message[type]({
    content,
    ...rest
  });
};

export default showMessage;
