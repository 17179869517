import React from 'react';
import { Link } from 'react-router-dom';
import Image from 'components/Image';
import { ReactComponent as ContactIcon } from 'assets/icons/icon-contact.svg';
import { ReactComponent as Location } from 'assets/icons/icon-location.svg';
import { ReactComponent as MailIcon } from 'assets/icons/icon-mail.svg';
import logoGreenSVG from 'assets/logos/logo-green.svg';
import styles from './ContactUs.less';

const ContactUs = () => {
  return (
    <div className={styles.contactUs}>
      <div className={styles.logo}>
        <Link to="/">
          <Image src={logoGreenSVG} width={100} alt="logo" />
        </Link>
      </div>
      <div className={styles.contactUsContent}>
        <div className={styles.contactUsItem}>
          <MailIcon />
          <div className={styles.contactUsInfo}>lienhe@koffi.vn</div>
        </div>
        <div className={styles.contactUsItem}>
          <ContactIcon />
          <div className={styles.contactUsInfo}>028.6681.5808</div>
        </div>
        <div className={styles.contactUsItem}>
          <Location />
          <div className={styles.contactUsInfo}>
            23.07 Block A2 chung cư Kỷ Nguyên đường 15B, Phường Phú Mỹ,
            <div>Quận 7, TP Hồ Chí Minh</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
