import React from 'react';
import { Route, Switch } from 'react-router-dom';
import withLoadable from 'utils/withLoadable';

const OrganizationPage = withLoadable(() => import('pages/organization'));
const SignaturePage = withLoadable(() => import('pages/organization/signature/list'));
const OrganizationRouter = () => (
  <Switch>
    <Route path="/organization/signature" component={SignaturePage} />
    <Route path={['/organization/:id', '/organization']} component={OrganizationPage} />
  </Switch>
);

export default OrganizationRouter;
