export const TAX_RATE = Object.freeze({
  NONE: 'NONE',
  ZERO: 'ZERO',
  FIVE: 'FIVE',
  TEN: 'TEN'
});

export const TAX_RATE_MAPPING = Object.freeze({
  ZERO: 0,
  FIVE: 5,
  TEN: 10
});
