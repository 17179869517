import { queryCache, useMutation, usePaginatedQuery, useQuery } from 'react-query';
import {
  createDecisionApplication,
  getDecisionApplications,
  editDecisionApplication,
  getDecisionApplication,
  deleteDecisionApplication,
  getAppDecisions
} from 'services/decisionApplication';

const _paginatedKey = page => ['decision-applications', page];
const _singleKey = id => ['decision-application', id];

export const useAppDecisions = params => usePaginatedQuery(_paginatedKey(params), () => getAppDecisions(params));
export const useCreateDecisionApplication = () => useMutation(data => createDecisionApplication(data));
export const useDecisionApplication = id => useQuery(_singleKey(id), () => getDecisionApplication(id));
export const useEditDecisionApplication = () => useMutation(decision => editDecisionApplication(decision));
export const clearDecisionApplications = () => queryCache.invalidateQueries('decision-applications');

export const useDecisionApplications = params => {
  return usePaginatedQuery(_paginatedKey(params), () => getDecisionApplications(params));
};

export const useDeleteDecisionApplication = () => {
  return useMutation(id => deleteDecisionApplication(id), {
    onSettled: () => {
      queryCache.invalidateQueries('decision-applications');
      queryCache.invalidateQueries('decision-application');
    }
  });
};
