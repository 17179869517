import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const DecisionAppCreateModal = ({ isLoading, ...rest }) => {
  const [t] = useTranslation();

  return (
    <Modal
      okText={t('button.create-issuance-notice')}
      cancelText={t('button.no')}
      title={t('confirm.create-decision-application-title')}
      confirmLoading={isLoading}
      closable={false}
      {...rest}
    >
      <span>{t('confirm.create-decision-application-message')}</span>
    </Modal>
  );
};

export default DecisionAppCreateModal;
