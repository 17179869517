import { useMutation, queryCache, useQuery, usePaginatedQuery } from 'react-query';
import jwt_decode from 'jwt-decode';
import {
  signIn,
  getUserProfile,
  createUser,
  deleteUser,
  updateUser,
  getUsers,
  getUserRoles,
  updateUserRoles,
  changePassword
} from 'services/user';
import { setToken, setTenant, setForceChangePwd } from 'utils/authority';

const _paginatedKey = page => ['users', page];
const _userRolesKey = () => ['user_roles'];

export const useSignIn = () => {
  const f = ({ username, password, tax_code }) => signIn(username, password, tax_code);
  return useMutation(f, {
    onSuccess: ({ auth_token, profile }) => {
      queryCache.setQueryData('user/profile', profile);
      const { force_change_password } = profile;
      const { tenant } = jwt_decode(auth_token);
      setToken(auth_token);
      setTenant(tenant);
      setForceChangePwd(force_change_password);
    }
  });
};

export const useUserProfile = () => {
  return useQuery('user/profile', getUserProfile, {
    initialData: () => queryCache.getQueryData('user/profile')
  });
};

export const useUsers = params => usePaginatedQuery(_paginatedKey(params), () => getUsers(params));
export const useUserRoles = (username, options) => useQuery(_userRolesKey(), () => getUserRoles(username), options);
export const useCreateUser = () => useMutation(user => createUser(user));
export const useUpdateUser = () => useMutation(user => updateUser(user));
export const useUpdateUserRoles = () => useMutation(({ username, roles }) => updateUserRoles(username, roles));
export const useDeleteUser = () => useMutation(user => deleteUser(user.id));
export const useChangePassword = () => useMutation(data => changePassword(data));
