import React from 'react';
import { Link } from 'react-router-dom';
import DmcaBadge from 'components/Dmca/DmcaBadge';
import styles from './Certificate.less';

const Certificate = () => {
  return (
    <div className={styles.certificate}>
      <div className={styles.nav}>
        <a href="https://zamo.io/">Công ty</a>
        <Link to="/support">Liên hệ</Link>
      </div>
      <div className={styles.certificateContent}>
        <div className={styles.certificateItem}>
          Giấy CNĐKKD:
          <div className={styles.certificateInfo}>0313950909</div>
        </div>
        <div className={styles.certificateItem}>
          Ngày cấp:
          <div className={styles.certificateInfo}>05/08/2016</div>
        </div>
        <div className={styles.certificateItem}>
          Cơ quan cấp:
          <div className={styles.certificateInfo}>Phòng đăng ký kinh doanh - Sở Kế hoạch và Đầu tư TP Hồ Chí Minh</div>
        </div>
        <div className={styles.certificateItem}>
          <DmcaBadge />
        </div>
      </div>
    </div>
  );
};

export default Certificate;
