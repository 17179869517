import request from 'utils/request';
import buildQueryParams from 'utils/buildQueryParams';
import config from 'config/app.config';

export const importCustomers = file => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('fileName', file.name);
  return request.post(`/customers/imports`, formData);
};

export const getCustomers = params => request.get(`/customers`, { params: buildQueryParams(params) });
export const exportCustomerTemplate = formatFile => `${config.api}/files/template/customer-template.${formatFile}`;
export const createCustomer = customer => request.post('/customers', customer);
export const getCustomer = id => request.get(`/customers/${id}`);
export const updateCustomer = customer => request.put(`/customers/${customer.id}`, customer);
export const deleteCustomer = id => request.delete(`/customers/${id}`);
export const exportDataCustomers = (format = 'xlsx') => {
  return request.post(
    '/customers/export',
    {},
    {
      params: { format }
    }
  );
};
