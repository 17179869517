import buildQueryParams from 'utils/buildQueryParams';
import request from 'utils/request';
import config from 'config/app.config';

export const importProducts = file => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('fileName', file.name);
  return request.post(`/products/imports`, formData);
};

export const getProducts = params => request.get(`/products`, { params: buildQueryParams(params) });
export const exportProducts = formatFile => `${config.api}/files/template/product-template.${formatFile}`;
export const createProduct = product => request.post('/products', product);
export const updateProduct = product => request.put(`/products/${product.id}`, product);
export const deleteProduct = id => request.delete(`/products/${id}`);
export const exportDataProducts = (format = 'xlsx') => {
  return request.post(
    '/products/export',
    {},
    {
      params: { format }
    }
  );
};
