import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LoadingModal from 'components/Loading/LoadingModal';
import { pollingRequest, download } from 'functions';
import { exportDecisionApplication } from 'services/decisionApplication';

const DecisionApplicationExportModal = props => {
  const { visible, onOk, decisionApplication, format } = props;

  useEffect(() => {
    if (visible) {
      (async function () {
        const _fn = () => exportDecisionApplication(decisionApplication.id, format);
        const { download_url } = await pollingRequest(_fn);
        const fileName = `quyet-dinh-ap-dung-HDDT-${decisionApplication.decision_number}.pdf`;
        download(download_url, fileName);
        onOk();
      })();
    }
  }, [visible]); // eslint-disable-line

  return <LoadingModal visible={visible} />;
};

DecisionApplicationExportModal.defaultProps = {
  format: 'pdf'
};

DecisionApplicationExportModal.propTypes = {
  onOk: PropTypes.func.isRequired,
  decisionApplication: PropTypes.object.isRequired,
  format: PropTypes.string
};

export default DecisionApplicationExportModal;
