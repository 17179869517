import React from 'react';
import { Route, Switch } from 'react-router-dom';
import withLoadable from 'utils/withLoadable';
const InvoiceSummaryRouter = withLoadable(() => import('pages/report/invoice-summary/InvoiceSummaryRouter'));
const ProductSaleReportRouter = withLoadable(() => import('pages/report/product-sale/ProductSaleReportRouter'));
const SoldInvoicePage = withLoadable(() => import('pages/report/soldInvoice'));
const InvoiceDetailReportRouter = withLoadable(() => import('pages/report/invoice-detail/InvoiceDetailReportRouter'));
const BuyerReportRouter = withLoadable(() => import('pages/report/buyer/BuyerReportRouter'));
const InvoiceUsageReportPage = withLoadable(() => import('pages/report/invoiceUsage'));

const ReportRouter = () => (
  <Switch>
    <Route path="/report/invoice-summary" component={InvoiceSummaryRouter} />
    <Route path="/report/product-sale" component={ProductSaleReportRouter} />
    <Route path="/report/sold-invoice" component={SoldInvoicePage} />
    <Route path="/report/buyer" component={BuyerReportRouter} />
    <Route path="/report/invoice-detail" component={InvoiceDetailReportRouter} />
    <Route path="/report/invoice-usage" component={InvoiceUsageReportPage} />
  </Switch>
);

export default ReportRouter;
