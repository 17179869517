import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Col, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PermissionSelect from './PermissionSelect';
import styles from './RoleModal.less';

const RoleModal = ({ role = {}, onSubmit, ...props }) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();

  const submitProduct = async () => {
    const values = await form.validateFields();
    onSubmit({ id: role.id, ...values });
  };

  React.useEffect(() => {
    form.setFieldsValue({ ...role });
  }, [role, form]);
  return (
    <Modal
      width={703}
      className={styles.roleModal}
      cancelText={t('button.cancel')}
      okText={role.id ? t('button.update') : t('button.create-in-short')}
      onOk={submitProduct}
      {...props}
    >
      <Form form={form} colon={false} layout="vertical">
        <Form.Item
          label="Tên vai trò"
          name="name"
          rules={[
            { required: true, message: 'Tên vai trò không được để trống.' },
            { whitespace: true, message: 'Tên vai trò không được để trống.' },
            { max: 255, message: 'Tên vai trò không quá 255 ký tự.' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Mô tả"
          name="description"
          rules={[
            { required: true, message: 'Mô tả không được để trống.' },
            { whitespace: true, message: 'Mô tả không được để trống.' },
            { max: 255, message: 'Mô tả không quá 255 ký tự.' }
          ]}
        >
          <Input />
        </Form.Item>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label="Quyền"
              name="permissions"
              rules={[{ required: true, message: 'Quyền không được để trống' }]}
            >
              <PermissionSelect />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

RoleModal.defaultProps = {
  role: {},
  onSubmit: () => {}
};

RoleModal.propTypes = {
  role: PropTypes.object,
  onSubmit: PropTypes.func
};

export default RoleModal;
