import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const CancelConfirmModal = ({ onOk, isLoading, ...rest }) => {
  const [t] = useTranslation();
  return (
    <Modal
      okText="Đồng ý"
      cancelText="Không, ở lại"
      title={t('confirm.delete-title')}
      confirmLoading={isLoading}
      onOk={onOk}
      {...rest}
    >
      <span>Những thay đổi sẽ không được lưu lại. Bạn có chắc chắn muốn thoát?</span>
    </Modal>
  );
};

export default CancelConfirmModal;
