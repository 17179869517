import { queryCache } from 'react-query';
import { filter, keys, pipe } from 'ramda';
import { get, merge, values } from 'lodash';
import { v1 as uuidv1 } from 'uuid';
import request from 'utils/request';
import { downloadAttachmentFileId } from 'services/document';
import buildQueryParams, { buildFilterQuery, buildSortParams, buildPaginationParams } from 'utils/buildQueryParams';
import { getFileExtension } from 'utils';
import {
  INVOICE_TYPE,
  LEGAL_INVOICE_TYPE,
  INVOICE_STATUS,
  DECREE51_ACTION_NO_TAX_AUTHORITY_MAPPING,
  DECREE123_ACTION_NO_TAX_AUTHORITY_MAPPING,
  INVOICE_UPLOAD_TYPE,
  AUTHORITY_INVOICE_STATUS
} from 'constants/invoice';
import config from 'config/app.config';

const _checkOriginInvoice = (action, { status, extra_fields }) => {
  let validOriginInvoiceStatus = true;
  const { previous_status } = extra_fields || {};
  if (status === INVOICE_STATUS.CANCELED && action === 'replace') {
    const originInvoiceStatuses = [INVOICE_STATUS.ORIGINAL, INVOICE_STATUS.REPLACED];
    validOriginInvoiceStatus = originInvoiceStatuses.includes(previous_status);
  }
  return validOriginInvoiceStatus;
};

export const checkIfActionAvailableDecree51 = (action, invoice) => {
  const { stage, status } = invoice;
  const [stages, statuses] = DECREE51_ACTION_NO_TAX_AUTHORITY_MAPPING[action];
  return stages.includes(stage) && statuses.includes(status) && _checkOriginInvoice(action, invoice);
};

export const checkIfActionAvailableDecree123 = (action, invoice) => {
  const { stage, status, authority_status } = invoice;
  if (action === 'viewCancellations') {
    return authority_status === AUTHORITY_INVOICE_STATUS.SUCCESS;
  }
  const [stages, statuses] = DECREE123_ACTION_NO_TAX_AUTHORITY_MAPPING[action];
  return stages.includes(stage) && statuses.includes(status) && _checkOriginInvoice(action, invoice);
};

export const downloadAttachmentInvoiceMinutes = async (documentId, invoice = {}) => {
  const { symbol, serial_number, invoice_template = {} } = invoice;
  const parts = ['bien_ban_dinh_kem_hoa_don', invoice_template.form, symbol, serial_number];
  const fileName = parts.filter(e => e).join('_');
  await downloadAttachmentFileId(documentId, fileName);
};

export const mergeInvoiceFormToPostRequest = (data, options) => {
  const formValues = [...data];
  const mergedData = formValues.reduce((v, data) => merge(v, data), { ...options });
  return {
    ...mergedData,
    invoice_lines: values(mergedData.invoice_lines),
    extra_fields: {
      ...mergedData.extra_fields,
      override_amount_flag: mergedData.override_amount_flag ? 'true' : 'false'
    }
  };
};

/**
 * Create invoice
 */
export const createInvoice = ({ invoice_lines, ...others }) => {
  return request.post('/invoices', {
    ...others,
    invoice_lines: invoice_lines.map(({ extra_fields, ...others }) => ({
      ...others,
      extra_fields: {
        ...extra_fields,
        promoted: get(extra_fields, 'promoted') ? 'true' : 'false'
      }
    })),
    authority_status: AUTHORITY_INVOICE_STATUS.NOT_SUBMITTED,
    date_of_issue: null // remove date_of_issue
  });
};

/**
 * Get list of invoices.
 */
export const getInvoices = params => {
  return request.get('/invoices', { params: buildQueryParams(params) });
};

/**
 * Get an invoice.
 */
export const getInvoice = id => request.get(`/invoices/${id}`);

/**
 * Update an invoice
 */
export const updateInvoice = (id, { invoice_lines, ...others }) =>
  request.put(`/invoices/${id}`, {
    ...others,
    extra_fields: { ...others.extra_fields },
    invoice_lines: invoice_lines.map(({ extra_fields, ...others }) => ({
      ...others,
      extra_fields: {
        ...extra_fields,
        promoted: get(extra_fields, 'promoted') ? 'true' : 'false'
      }
    }))
  });

/**
 * Check if report's export is completed.
 *
 * @param status the given export status
 * @returns {boolean} true if the export is completed
 */
export const isReportExportCompleted = status => status === 'DONE' || status === 'FAIL';

/**
 * Export invoice report as XML or PDF.
 */
export const exportInvoiceReport = (id, format = 'pdf') =>
  request.post(
    `/invoices/${id}/export`,
    { id },
    {
      params: { format }
    }
  );

/**
 * Get invoice by code
 */
export const getInvoiceByCode = (code, format = 'pdf') =>
  request.get(`/invoices/code/${code}`, {
    params: { format }
  });

/**
 * Request invoice base64 payload to sign.
 */
export const requestInvoiceSignPayload = (id, serialNumber, forceSign = false, recipients = []) =>
  request.post(
    `/invoices/${id}/signing`,
    { force_sign: forceSign, serial_number: serialNumber, recipients },
    {
      params: { format: 'xml' }
    }
  );

/**
 * Send invoice to customer
 */
export const sendInvoiceToCustomer = (id, recipients) => request.post(`/invoices/${id}/send`, { recipients });

/**
 * Delete an e-invoice
 */
export const deleteInvoice = id => request.delete(`/invoices/${id}`);

/**
 * Cancel an issued invoice
 */
export const cancelInvoice = (id, data = {}) => request.put(`/invoices/${id}/cancel`, data);

/**
 * Replace an invoice.
 */
export const replaceInvoice = (id, { invoice_lines, ...others }) =>
  request.post(`/invoices/${id}/replace`, {
    ...others,
    extra_fields: { ...others.extra_fields },
    invoice_lines: invoice_lines.map(({ extra_fields, ...others }) => ({
      ...others,
      extra_fields: {
        ...extra_fields,
        promoted: get(extra_fields, 'promoted') ? 'true' : 'false'
      }
    }))
  });

/**
 * Adjust an invoice
 */
export const adjustInvoice = (id, { invoice_lines, ...others }) =>
  request.put(`/invoices/${id}/adjust`, {
    ...others,
    extra_fields: { ...others.extra_fields },
    invoice_lines: invoice_lines.map(({ extra_fields, ...others }) => ({
      ...others,
      extra_fields: {
        ...extra_fields,
        promoted: get(extra_fields, 'promoted') ? 'true' : 'false'
      }
    }))
  });

/**
 * Clone invoice.
 *
 * @param id the given invoice id
 */
export const cloneInvoice = id => request.post(`/invoices/${id}/clone`);

/**
 * Get cancellations notification
 */
export const getCancellationsByInvoiceId = id => request.get(`/invoices/${id}/announcements`);

/**
 * Request PDF Upload URL for a registrations form
 */
export const requestInvoiceProductLineUrl = (type, file_name, format) =>
  request.post(
    `/invoices/documents`,
    { file_name },
    {
      params: { type, format }
    }
  );

/**
 * Import product lines to invoice
 */
export const importInvoiceProductLines = id => request.post(`/invoices/documents/${id}/import`);

/**
 * Import invoices by excel file
 */
export const importInvoicesByExcel = (documentId, type) =>
  request.post(
    `/invoices/imports`,
    {},
    {
      params: { documentId, type }
    }
  );

/**
 * Get format product-lines file and return url to download
 * @param {*} formatFile
 */
export const downloadInvoiceProductLineTemplate = formatFile =>
  `${config.api}/files/template/invoice-line-template.${formatFile}`;

export const withKey = data => {
  return data.map(item => {
    if (item.key) return item;
    return { ...item, key: uuidv1() };
  });
};

/**
 * Request an upload document url.
 */
export const requestInvoiceUploadUrl = (id, type = INVOICE_UPLOAD_TYPE.REPLACE_ATTACHMENT, file_name, format) => {
  const extension = format || getFileExtension(file_name, 'pdf');
  return request.post(
    `/invoices/${id}/documents`,
    { file_name },
    {
      params: { type, format: extension }
    }
  );
};

/**
 * Convert invoice.
 */
export const convertInvoice = (id, data = {}) => request.put(`/invoices/${id}/convert`, data);

/**
 * Export converted invoice.
 */
export const exportConvertedInvoice = (id, format = 'pdf') =>
  request.post(
    `/invoices/${id}/converted/export`,
    { id },
    {
      params: { format }
    }
  );

/**
 * Get all available invoice types.
 */
export const getAvailableInvoiceTypes = (included = []) => {
  let excludedList = [INVOICE_TYPE.OTHERS, INVOICE_TYPE.COMMERCIAL];
  if (included.length > 0) {
    excludedList = excludedList.filter(item => !included.includes(item));
  }
  return pipe(
    keys,
    filter(key => !excludedList.includes(key))
  )(INVOICE_TYPE);
};

/**
 * Calculate invoice usage by range
 */
export const calculateInvoiceUsageByRange = data => request.post(`/invoices/count`, data);

/**
 * Preview invoice
 */
export const previewInvoice = ({ invoice_lines, ...others }) =>
  request.post(
    '/invoices/preview',
    {
      ...others,
      invoice_lines: invoice_lines.map(({ extra_fields, ...others }) => ({
        ...others,
        extra_fields: {
          ...extra_fields,
          promoted: get(extra_fields, 'promoted') ? 'true' : 'false'
        }
      })),
      authority_status: AUTHORITY_INVOICE_STATUS.NOT_SUBMITTED,
      legal_invoice_type: LEGAL_INVOICE_TYPE.INVOICE_119,
      date_of_issue: null // remove date_of_issue
    },
    {
      params: {
        status: others.status,
        original_version_id: get(others, 'original_version.id')
      }
    }
  );

/**
 * Preview invoice by id.
 */
export const previewInvoiceById = (id, invoice, status) => {
  const { invoice_lines, ...others } = invoice;
  return request.post(
    `/invoices/${id}/preview`,
    {
      ...others,
      invoice_lines: invoice_lines.map(({ extra_fields, ...others }) => ({
        ...others,
        extra_fields: {
          ...extra_fields,
          promoted: get(extra_fields, 'promoted') ? 'true' : 'false'
        }
      })),
      authority_status: AUTHORITY_INVOICE_STATUS.NOT_SUBMITTED,
      legal_invoice_type: LEGAL_INVOICE_TYPE.INVOICE_119,
      date_of_issue: null // remove date_of_issue
    },
    {
      params: {
        status
      }
    }
  );
};

/**
 * Export invoice data hook.
 */
export const exportInvoiceData = ({ filters, sorter, pagination }, isIncludeProducts) => {
  return request.post('/invoices/export-to-excel', {
    report_type: isIncludeProducts ? 'INVOICE_EXPORT' : 'INVOICE_EXPORT_COMPACT',
    extra_fields: {
      query: buildFilterQuery(filters),
      ...buildSortParams(sorter),
      ...buildPaginationParams(pagination)
    }
  });
};

/**
 * Get cancellations notification
 */
export const getInvoiceQuota = () => request.get(`/invoices/quota`);

export const checkHasAdjusted = id => request.get(`/invoices/adjustment/${id}/exist`);

export const isInvoiceExistInForm04 = (ids, context = ['SUCCESS', 'NOT_SUBMITTED']) => {
  return request.post(`/invoices/check-invoices-exists`, {
    invoice_ids: ids,
    authority_statuses: context
  });
};

export const refreshInvoices = () => queryCache.invalidateQueries('invoices');
