import { useMutation } from 'react-query';
import { extractSerialNumber, signEntity, validateIfSigningReady } from 'services/signing';
import { sendEsignerMessage } from 'services/esigner';
import { signBase64Message } from 'services/signature';

export const useSigningEntity = () =>
  useMutation(async payload => {
    const { id, forceSign = false, recipients = [], requestSignPayload } = payload;
    const certPayload = await validateIfSigningReady();
    const serialNumber = extractSerialNumber(certPayload);
    const requestedPayload = await requestSignPayload(id, serialNumber, forceSign, recipients);
    const signedPayload = await sendEsignerMessage(signBase64Message(requestedPayload));
    const { CERT_CHAIN, SIGNED_CONTENT, CERT, PIN_CODE } = signedPayload;
    return signEntity(requestedPayload.id, {
      id: requestedPayload.id,
      payload: requestedPayload.payload,
      signature: SIGNED_CONTENT,
      cert: CERT,
      cert_chain: CERT_CHAIN,
      pin_code: PIN_CODE,
      signature_path: requestedPayload.signature_path
    });
  });

export const useSigningBulkEntity = () =>
  useMutation(async payloads => {
    const certPayload = await validateIfSigningReady();
    const serialNumber = extractSerialNumber(certPayload);
    for (const payload of payloads) {
      const { id, forceSign = false, recipients = [], requestSignPayload } = payload;
      const requestedPayload = await requestSignPayload(id, serialNumber, forceSign, recipients);
      const signedPayload = await sendEsignerMessage(signBase64Message(requestedPayload));
      const { CERT_CHAIN, SIGNED_CONTENT, CERT, PIN_CODE } = signedPayload;
      await signEntity(requestedPayload.id, {
        id: requestedPayload.id,
        payload: requestedPayload.payload,
        signature: SIGNED_CONTENT,
        cert: CERT,
        cert_chain: CERT_CHAIN,
        pin_code: PIN_CODE,
        signature_path: requestedPayload.signature_path
      });
    }
  });
