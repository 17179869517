import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSigningEntity, useSigningBulkEntity } from 'queries/signing';
import LoadingModal from 'components/Loading/LoadingModal';
import { showResponseError } from 'functions';
import ForceSignConfirmModal from '../ForceSignConfirmModal';

const SigningEntityModal = ({ visible, payload, onOk, onCancel, ...restProps }) => {
  const [forceModal, setForceModal] = React.useState({ visible: false });

  const setPayloadWithForceSign = data => {
    const _map = item => ({ ...item, forceSign: true });
    if (Array.isArray(data)) return data.map(_map);
    return _map(data);
  };

  const isNotSignatureDefault = error => {
    return get(error, 'response.status') === 400 && get(error, 'response.data.code') === 'ESIG001';
  };

  const [sign] = useSigningEntity();
  const [bulkSign] = useSigningBulkEntity();
  const handleSignEntity = React.useCallback(
    data => {
      const signMethod = Array.isArray(data) ? bulkSign : sign;
      signMethod(data, {
        onSuccess: onOk,
        onError: error => {
          if (isNotSignatureDefault(error)) {
            setForceModal({ visible: true });
            return;
          }
          showResponseError({ error });
          onCancel();
        }
      });
    },
    [sign, bulkSign, onOk, onCancel]
  );

  React.useEffect(() => {
    if (visible && payload) {
      handleSignEntity(payload);
    }
  }, [visible, payload, handleSignEntity]);
  return (
    <React.Fragment>
      <LoadingModal visible={visible} {...restProps} />
      <ForceSignConfirmModal
        {...forceModal}
        onOk={() => {
          setForceModal({ visible: false });
          handleSignEntity(setPayloadWithForceSign(payload));
        }}
        onCancel={() => {
          setForceModal({ visible: false });
          onCancel();
        }}
      />
    </React.Fragment>
  );
};

SigningEntityModal.defaultProps = {
  visible: false,
  payload: {},
  onOk: () => {},
  onCancel: () => {}
};

SigningEntityModal.propTypes = {
  visible: PropTypes.bool,
  payload: PropTypes.object,
  onOk: PropTypes.func,
  onCancel: PropTypes.func
};

export default SigningEntityModal;
