import React from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import { Form, Modal, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from 'queries/user';
import RoleSelect from '../RoleSelect';
import styles from './index.less';

const UserRolesModal = ({ user, onSubmit, visible, ...props }) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const { data: userRoles = [], remove } = useUserRoles(user.username);

  const submitUser = async () => {
    const values = await form.validateFields();
    onSubmit({ ...user, ...values });
  };

  React.useEffect(() => {
    if (!visible) {
      remove();
      form.resetFields();
    }
  }, [visible, remove, form]);
  React.useEffect(() => {
    if (userRoles) {
      const orderedList = orderBy(userRoles || [], ['description'], ['asc']).map(item => item.id);
      form.setFieldsValue({ roles: orderedList });
    }
  }, [userRoles, form]);
  return (
    <Modal
      visible={visible}
      width={703}
      className={styles.userModal}
      cancelText={t('button.cancel')}
      okText={t('button.update')}
      onOk={submitUser}
      {...props}
    >
      <Form form={form} layout="vertical">
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label={t('user.user-roles-form.roles')}
              name="roles"
              rules={[
                {
                  required: true,
                  message: t('user.user-roles-form.roles-required')
                }
              ]}
            >
              <RoleSelect />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

UserRolesModal.defaultProps = {
  user: {},
  visible: false
};

UserRolesModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object,
  visible: PropTypes.bool
};

export default UserRolesModal;
