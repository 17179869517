import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Steps, Tooltip } from 'antd';
import { downloadInvoiceProductLineTemplate, requestInvoiceProductLineUrl } from 'services/invoice';
import { INVOICE_UPLOAD_TYPE } from 'constants/invoice';
import { download, showMessage } from 'functions';
import { useImportProductLines } from 'queries/invoice';
import { uploadDocument } from 'services/document';
import { ReactComponent as DownloadIcon } from 'assets/icons/action-download.svg';
import ProductLinesDraggerUpload from './ProductLinesDraggerUpload';
import styles from './ProductLinesImportModal.less';

const ProductLinesImportModal = ({ onOk, ...rest }) => {
  const [t] = useTranslation();
  const [isUploading, setIsUploading] = React.useState(false);

  const [imports] = useImportProductLines();
  const importInvoiceProductLines = async file => {
    setIsUploading(true);
    try {
      const format = file.name.split('.').pop();
      const response = await requestInvoiceProductLineUrl(INVOICE_UPLOAD_TYPE.IMPORT, file.name, format);
      const { document_id } = await uploadDocument(file, response);
      imports(document_id, {
        onSuccess: ({ data }) => {
          onOk(data);
          setIsUploading(false);
        },
        onError: () => {
          showMessage({
            type: 'error',
            content: t('invoice.product-lines.import.import-error')
          });
          setIsUploading(false);
        }
      });
    } catch (error) {}
  };

  const handleDownloadUrl = fileFormat => {
    const url = downloadInvoiceProductLineTemplate(fileFormat);
    download(
      url,
      fileFormat === 'xls' ? 'mau-import-san-pham-vao-hoa-don.xls' : 'mau-import-san-pham-vao-hoa-don.xlsx'
    );
  };

  const step1stDescription = (
    <>
      {t('invoice.product-lines.import.1stDescription')}
      <div className={styles.downloadTemplateWrapper}>
        <Tooltip title={t('button.downloadXLS')}>
          <div className={styles.downloadTemplateBtn} onClick={() => handleDownloadUrl('xls')}>
            <DownloadIcon />
            {t('button.downloadXLS')}
          </div>
        </Tooltip>
        <Tooltip title={t('button.downloadXLSX')}>
          <div className={styles.downloadTemplateBtn} onClick={() => handleDownloadUrl('xlsx')}>
            <DownloadIcon />
            {t('button.downloadXLSX')}
          </div>
        </Tooltip>
      </div>
    </>
  );

  const step3rdDescription = (
    <>
      <p style={{ marginBottom: 22 }}>{t('invoice.product-lines.import.3rdDescription')}</p>
      <ProductLinesDraggerUpload onOk={importInvoiceProductLines} isLoading={isUploading} />
    </>
  );

  return (
    <Modal
      title={t('invoice.product-lines.import.title')}
      width={663}
      className={styles.importModal}
      footer={null}
      {...rest}
    >
      <Steps direction="vertical" progressDot status="wait">
        <Steps.Step
          title={t('invoice.product-lines.import.1stStep')}
          className={styles.step1st}
          description={step1stDescription}
        />
        <Steps.Step
          title={t('invoice.product-lines.import.2ndStep')}
          description={t('invoice.product-lines.import.2ndDescription')}
        />
        <Steps.Step title={t('invoice.product-lines.import.3rdStep')} description={step3rdDescription} />
      </Steps>
    </Modal>
  );
};

export default ProductLinesImportModal;
