import React from 'react';
import { Form, Input } from 'antd';

const ChangePassword = ({ form, hideOldPassword = true }) => {
  return (
    <>
      {!hideOldPassword && (
        <Form.Item
          name="current_password"
          label="Mật khẩu cũ"
          rules={[
            {
              required: true,
              message: 'Mật khẩu cũ không được để trống.'
            }
          ]}
        >
          <Input.Password />
        </Form.Item>
      )}
      <Form.Item
        name="new_password"
        label="Mật khẩu mới"
        rules={[
          {
            required: true,
            message: 'Mật khẩu mới không được để trống.'
          }
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="confirm_pass"
        label="Xác nhận mật khẩu"
        rules={[
          {
            required: true,
            message: 'Xác nhận mật khẩu không được để trống.'
          },
          {
            validator: (_, value) => {
              if (value) {
                const { new_password: newPassword } = form.getFieldsValue();
                if (value === newPassword) {
                  return Promise.resolve();
                }

                return Promise.reject('Mật khẩu không trùng khớp.');
              }

              return Promise.resolve();
            }
          }
        ]}
      >
        <Input.Password />
      </Form.Item>
    </>
  );
};

export default ChangePassword;
