import React from 'react';
import { useOrganization } from 'queries/organization';

const SecurityContext = React.createContext({});
const SecurityContextWrapper = ({ children }) => {
  const { data = { settings: {} } } = useOrganization();
  const { decree_policy } = data.settings;

  if (!decree_policy) return null;
  return <SecurityContext.Provider value={{ decree: decree_policy }}>{children}</SecurityContext.Provider>;
};

export default SecurityContextWrapper;
export { SecurityContext };
