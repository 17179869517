import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { useRoles } from 'queries/role';
import { BIG_PAGE_SIZE } from 'constant';

const { Option } = Select;
const RoleSelect = ({ ...props }) => {
  const [t] = useTranslation();
  const { resolvedData: { items } = { items: [] }, isLoading } = useRoles({
    pagination: { current: 1, pageSize: BIG_PAGE_SIZE },
    sorter: { field: 'display_name', order: 'ascend' }
  });

  return (
    <Select
      allowClear
      mode="multiple"
      loading={isLoading}
      style={{ width: '100%' }}
      placeholder={t('user.role-select.placeholder')}
      {...props}
    >
      {items.map(({ id, display_name }) => (
        <Option key={id}>{display_name}</Option>
      ))}
    </Select>
  );
};

export default RoleSelect;
