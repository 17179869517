import React from 'react';
import PropTypes from 'prop-types';
import ViewPDFModal from 'components/_share/ViewPDFModal';
import { exportCancellation } from 'services/cancellationNotification';
import ModalHeaderActions from './ModalHeaderActions';

const ViewModal = ({ data, ...restProps }) => {
  const pdfRef = React.useRef();

  return (
    <ViewPDFModal
      ref={pdfRef}
      data={data}
      action={<ModalHeaderActions pdfRef={pdfRef} data={data} />}
      exportFunc={exportCancellation}
      {...restProps}
    />
  );
};

ViewModal.defaultProps = {
  data: {}
};

ViewModal.propTypes = {
  data: PropTypes.object
};

export default ViewModal;
