import React from 'react';
import { Modal, Steps, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { download } from 'functions';
import { ReactComponent as DownloadIcon } from 'assets/icons/action-download.svg';
import FileDraggerUpload from './FileDraggerUpload';
import { exportCustomerTemplate } from 'services/customer';
import styles from './CustomerImportModal.less';

const CustomerImportModal = ({ onOk, isLoading, ...restProps }) => {
  const [t] = useTranslation();

  const handleDownloadUrl = fileFormat => {
    const url = exportCustomerTemplate(fileFormat);
    download(url, `mau-nhap-khach-hang.${fileFormat}`);
  };

  const step1stDescription = (
    <>
      {t('category.customer.import.1stDescription')}
      <div className={styles.downloadTemplateWrapper}>
        <Tooltip title={t('button.downloadXLS')}>
          <div className={styles.downloadTemplateBtn} onClick={() => handleDownloadUrl('xls')}>
            <DownloadIcon />
            {t('button.downloadXLS')}
          </div>
        </Tooltip>
        <Tooltip title={t('button.downloadXLSX')}>
          <div className={styles.downloadTemplateBtn} onClick={() => handleDownloadUrl('xlsx')}>
            <DownloadIcon />
            {t('button.downloadXLSX')}
          </div>
        </Tooltip>
      </div>
    </>
  );

  const step3rdDescription = (
    <>
      <p style={{ marginBottom: 22 }}>{t('category.customer.import.3rdDescription')}</p>
      <FileDraggerUpload onOk={onOk} isLoading={isLoading} />
    </>
  );

  return (
    <Modal
      title={t('category.customer.import.title')}
      width={663}
      className={styles.importModal}
      footer={null}
      destroyOnClose
      {...restProps}
    >
      <Steps direction="vertical" progressDot status="wait">
        <Steps.Step title={t('category.customer.import.1stStep')} description={step1stDescription} />
        <Steps.Step
          title={t('category.customer.import.2ndStep')}
          description={t('category.customer.import.2ndDescription')}
        />
        <Steps.Step title={t('category.customer.import.3rdStep')} description={step3rdDescription} />
      </Steps>
    </Modal>
  );
};

export default CustomerImportModal;
