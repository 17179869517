import { queryCache, useMutation, usePaginatedQuery, useQuery } from 'react-query';
import {
  createCancellation,
  getCancellation,
  getCancellations,
  deleteCancellation,
  editCancellation
} from 'services/cancellationNotification';

const rootSingleKey = 'cancellation-notification';
const paginatedKey = params => ['cancellation-notifications', params];
const listKey = () => ['cancellation-notifications'];
const singleKey = id => [rootSingleKey, id];

/**
 * Use fetch cancellations hook.
 */
export const useCancellationNotifications = params => {
  const f = () => getCancellations(params);
  return usePaginatedQuery(paginatedKey(params), f);
};

/**
 * Get a cancellation hook.
 */
export const useCancellation = (id, options) => useQuery(singleKey(id), () => getCancellation(id), options);

/**
 * Create an cancellation hook.
 */
export const useCreateCancellation = () =>
  useMutation(cancellation => createCancellation(cancellation), {
    onSettled: () => {
      queryCache.invalidateQueries(listKey());
    }
  });

/**
 * Delete a cancellation hook.
 */
export const useDeleteCancellation = () =>
  useMutation(id => deleteCancellation(id), {
    onSuccess: () => {
      queryCache.invalidateQueries(listKey());
    }
  });

export const useEditCancellation = () => {
  const f = cancellation => editCancellation(cancellation);
  return useMutation(f, {
    onSuccess: () => {
      queryCache.invalidateQueries(listKey());
    }
  });
};
