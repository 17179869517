//TODO: enable non-latin cMaps
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { debounce } from 'lodash';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Spin, Empty, Divider } from 'antd';
import { pdfjs } from 'react-pdf';
import styles from './index.less';

// Enable pdfjs worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const PDFPage = props => {
  const [rendering, setRendering] = useState(true);
  const onRenderSuccess = () => setRendering(false);
  return (
    <Spin spinning={rendering}>
      <Page {...props} loading={<Spin spinning />} onRenderSuccess={onRenderSuccess} />
    </Spin>
  );
};

const PDFViewer = ({ file, empty, onLoadSuccess, ...props }) => {
  const [numPages, setNumPages] = useState();
  const [pageWidth, setPageWidth] = useState(null);
  const wrapperRef = React.useRef();

  const onDocumentLoadSuccess = ({ numPages }) => {
    if (typeof onLoadSuccess === 'function') onLoadSuccess();
    setPageSize();
    setNumPages(numPages);
  };

  const setPageSize = () => {
    if (wrapperRef.current) {
      setPageWidth(wrapperRef.current.getBoundingClientRect().width);
    }
  };

  React.useEffect(() => {
    setPageSize();
    window.addEventListener('resize', debounce(setPageSize, 100));
    return () => {
      window.removeEventListener('resize', debounce(setPageSize, 100));
    };
  }, []);

  return (
    <div className={styles.pdfViewer} ref={wrapperRef}>
      <Document
        options={{ cMapUrl: 'cmaps/', cMapPacked: true }}
        onLoadSuccess={onDocumentLoadSuccess}
        file={file}
        loading={<Spin spinning />}
        noData={empty || <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Không tìm thấy tài liệu" />}
        {...props}
      >
        {Array.from(new Array(numPages), (_, index) => (
          <div key={`page_${index + 1}`}>
            <PDFPage pageNumber={index + 1} width={pageWidth} />
            {index < numPages - 1 && <Divider className="pdfPageDivider" />}
          </div>
        ))}
      </Document>
    </div>
  );
};

export default PDFViewer;
