import React from 'react';

const DmcaBadge = () => (
  <a
    href="//www.dmca.com/Protection/Status.aspx?ID=8a831539-1745-480c-bfc3-fe4035e5fd53"
    title="DMCA.com Protection Status"
    className="dmca-badge"
  >
    {' '}
    <img
      src="https://images.dmca.com/Badges/dmca_protected_sml_120m.png?ID=8a831539-1745-480c-bfc3-fe4035e5fd53"
      alt="DMCA.com Protection Status"
    />
  </a>
);

export default DmcaBadge;
