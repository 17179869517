import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDeleteInvoice } from 'queries/invoice';
import { showResponseError, showMessage } from 'functions';

const InvoiceDeleteModal = ({ data, onOk, ...restProps }) => {
  const [t] = useTranslation();
  const [del, { isLoading }] = useDeleteInvoice();

  const deletePromise = ({ id }) => {
    return new Promise((resolve, reject) =>
      del(id, {
        onSuccess: resolve,
        onError: reject
      })
    );
  };

  const handleDelete = () => {
    if (Array.isArray(data)) {
      const promise = data.map(item => deletePromise(item));
      Promise.all(promise).then(() => {
        onOk();
        showMessage({ content: t('invoice.delete-success') });
      });
    } else {
      del(data.id, {
        onSuccess: () => {
          showMessage({ content: t('invoice.delete-success') });
          onOk();
        },
        onError: error => {
          showResponseError({ error });
        }
      });
    }
  };

  return (
    <Modal
      okText={t('button.delete')}
      cancelText={t('button.cancel')}
      title={t('confirm.delete-title')}
      {...restProps}
      onOk={handleDelete}
      confirmLoading={isLoading}
    >
      <span>{t('confirm.delete-message')}</span>
    </Modal>
  );
};

InvoiceDeleteModal.defaultProps = {
  data: {},
  okOk: () => {}
};

InvoiceDeleteModal.propTypes = {
  data: PropTypes.any,
  onOk: PropTypes.func
};
export default InvoiceDeleteModal;
