import moment from 'moment';
import { formatDate } from 'utils/format';
import { map } from 'ramda';

/**
 * Period type.
 */
export const PERIOD_TYPE = Object.freeze({
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR'
});

export const momentLocalDateTimeToMomentDateUtc = value => moment(value).utc();

export const isoUtcDateToMomentLocalDate = value => moment(value);

/**
 * Get period name.
 */
export const getPeriodName = (time, t, isFull = false) => {
  const { period, fromDate, toDate } = time;
  const formattedFromDate = formatDate(moment.unix(fromDate / 1000));
  const formattedToDate = formatDate(moment.unix(toDate / 1000));
  if (!period) return `${formattedFromDate} - ${formattedToDate}`;
  //eslint-disable-next-line
  return isFull ? t(`period.${period}`) + ' ' + `(${formattedFromDate} - ${formattedToDate})` : t(`period.${period}`);
};

const daysInWeek = startDate =>
  map(item => {
    const date = startDate.clone().add(item, 'days');
    return {
      type: PERIOD_TYPE.WEEK,
      label: date.format('dddd'),
      from: date.clone().startOf('day'),
      to: date.clone().endOf('day')
    };
  })(Array.from(Array(7).keys()));

/**
 * Get all days in the week of period time.
 */
export const getDaysInWeek = period => {
  switch (period) {
    case 0: {
      const startDate = moment().utc(true).startOf('isoWeek');
      return daysInWeek(startDate);
    }
    case 1: {
      const startDateOfLastWeek = moment().utc(true).subtract(1, 'weeks').startOf('isoWeek');
      return daysInWeek(startDateOfLastWeek);
    }
    default: {
      return {};
    }
  }
};

/**
 * Get all days in the month of period time.
 */
export const getDaysInMonth = period => {
  const startDate = moment([moment().year(), period]);
  return map(item => {
    const date = startDate.clone().add(item, 'days');
    return {
      type: PERIOD_TYPE.MONTH,
      label: date.format('DD/MM'),
      from: date.clone().startOf('day'),
      to: date.clone().endOf('day')
    };
  })(Array.from(Array(startDate.daysInMonth()).keys()));
};

/**
 * Get all months in the year of period time.
 */
export const getMonthsInYear = period => {
  const startDate = moment(period, 'YYYY').utc(true);
  return map(item => {
    const date = startDate.clone().add(item, 'months');
    return {
      type: PERIOD_TYPE.YEAR,
      label: date.format('MMM'),
      from: date.clone().startOf('month'),
      to: date.clone().endOf('month')
    };
  })(Array.from(Array(12).keys()));
};
