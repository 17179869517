import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'antd';
import { pickBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import ProductForm from './ProductForm';
import styles from './product-modal.less';

const ProductModal = ({ title, product = {}, loading, onSubmit, ...props }) => {
  const [t] = useTranslation();
  const [id, setId] = useState(null);
  const [form] = Form.useForm();

  const submitProduct = async () => {
    const values = await form.validateFields();
    //remove field that is empty value
    onSubmit(pickBy({ id, ...values }));
  };

  useEffect(() => {
    setId(product.id);
  }, [form, product]);

  return (
    <Modal
      title={title}
      width={703}
      className={styles.productCreationModal}
      cancelText={t('button.cancel')}
      okText={id ? t('button.update') : t('button.create-in-short')}
      onOk={submitProduct}
      confirmLoading={loading}
      {...props}
    >
      <ProductForm form={form} product={product} />
    </Modal>
  );
};

export default ProductModal;
