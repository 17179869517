import React, { useState } from 'react';
import { Modal } from 'antd';
import { DEFAULT_PAGE_SIZE } from 'constant';
import { INVOICE_TYPE } from 'constants/invoice';
import InvoiceSaleProductTable from './InvoiceSaleProductTable';
import InvoiceTransportationSlipProductTable from './InvoiceTransportationSlipProductTable';
import InvoiceVATProductTable from './InvoiceVATProductTable';

const InvoiceProductSelectionModal = ({ invoice, onOk, ...props }) => {
  const [selectedInvoiceLines, setSelectedInvoiceLines] = useState([]);
  const [tableConfig, setTableConfig] = useState({
    pagination: { current: 1, pageSize: DEFAULT_PAGE_SIZE }
  });
  const { invoice_template: { invoice_type: invoiceType } = {} } = invoice;

  const changeRowSelection = (_, selectedRows) => {
    setSelectedInvoiceLines(selectedRows);
  };

  const changeTable = pagination => {
    setTableConfig(prev => ({
      ...prev,
      pagination
    }));
  };

  return (
    <Modal
      width={1180}
      title="Chọn hàng hoá từ hoá đơn gốc"
      cancelText="Thoát"
      onOk={() => onOk(selectedInvoiceLines)}
      {...props}
    >
      {invoiceType === INVOICE_TYPE.SALE && (
        <InvoiceSaleProductTable
          records={invoice.invoice_lines}
          total={invoice.invoice_lines.length}
          onChange={changeTable}
          onRowSelection={changeRowSelection}
          {...tableConfig}
        />
      )}
      {invoiceType === INVOICE_TYPE.VAT && (
        <InvoiceVATProductTable
          records={invoice.invoice_lines}
          total={invoice.invoice_lines.length}
          onChange={changeTable}
          onRowSelection={changeRowSelection}
          {...tableConfig}
        />
      )}
      {invoiceType === INVOICE_TYPE.DELIVERY_NOTE && (
        <InvoiceTransportationSlipProductTable
          records={invoice.invoice_lines}
          total={invoice.invoice_lines.length}
          onChange={changeTable}
          onRowSelection={changeRowSelection}
          {...tableConfig}
        />
      )}
    </Modal>
  );
};

export default InvoiceProductSelectionModal;
