import React from 'react';
import { Link } from 'react-router-dom';
import Image from 'components/Image';
import logoGreenSVG from 'assets/logos/logo-green.svg';
import DmcaBadge from 'components/Dmca/DmcaBadge';
import styles from './index.less';

const Footer = () => {
  return (
    <div className={styles.support}>
      <div className={styles.contactUs}>
        <div className={styles.logo}>
          <Link to="/">
            <Image src={logoGreenSVG} width={100} alt="logo" />
          </Link>
        </div>
        <div className={styles.contactUsContent}>
          <div className={styles.contactUsItem}>Email: lienhe@koffi.vn</div>
          <div className={styles.contactUsItem}>Liên hệ: 028.6681.5808</div>
          <div className={styles.contactUsItem}>
            Địa chỉ: 23.07 Block A2 chung cư Kỷ Nguyên đường 15B, Phường Phú Mỹ, Quận 7, TP Hồ Chí Minh
          </div>
          <div className={styles.contactUsItem}>Giấy CNĐKKD: 0313950909</div>
          <div className={styles.contactUsItem}>Ngày cấp: 05/08/2016</div>
          <div className={styles.contactUsItem}>
            Giấy CNĐKKD: Phòng đăng ký kinh doanh - Sở Kế hoạch và Đầu tư TP Hồ Chí Minh
          </div>
          <div className={styles.contactUsItem}>
            <DmcaBadge />
          </div>
        </div>
      </div>
      <div className={styles.certificate}>
        <div className={styles.nav}>
          <a href="https://zamo.io/">Công ty</a>
          <Link to="/support">Liên hệ</Link>
        </div>
      </div>
      <div className={styles.privacy}>Copyright © 2021 ZAMO - All Right Reserved</div>
    </div>
  );
};

export default Footer;
