import request from 'utils/request';
import { isUrlDataReady, download } from '../functions';
import config from '../config/app.config';

export const uploadDocument = (file, { upload_url, file_url, document_id }) => {
  return new Promise(async resolve => {
    await window.fetch(upload_url, {
      method: 'PUT',
      body: file,
      headers: {
        'x-ms-blob-type': 'BlockBlob'
      }
    });
    // polling for completeness
    await isUrlDataReady(file_url);
    // notify backend service about completeness for doing post upload stuff
    await request.post(`/documents/${document_id}/upload/complete`, {
      status: 'UPLOADED',
      file_name: file.name
    });
    resolve({ upload_url, file_url, document_id });
  });
};

export const uploadTenantDocument = (file, tenant_id, { upload_url, file_url, document_id }) => {
  return new Promise(async resolve => {
    await window.fetch(upload_url, {
      method: 'PUT',
      body: file,
      headers: {
        'x-ms-blob-type': 'BlockBlob'
      }
    });
    // polling for completeness
    await isUrlDataReady(file_url);
    // notify backend service about completeness for doing post upload stuff
    await request.post(`tenants/${tenant_id}/documents/${document_id}/complete`, {
      status: 'UPLOADED',
      file_name: file.name
    });
    resolve({ upload_url, file_url, document_id });
  });
};

export const downloadAttachmentFileId = async (documentId, fileName) => {
  const url = `${config.api}/documents/${documentId}`;
  const { download_url } = await request.get(url);
  download(download_url, fileName);
};

/**
 * Get document url by id.
 *
 * @param id the given document id
 */
export const getDocumentUrlById = id => {
  const { api } = config;
  return `${api}/documents/${id}/download`;
};
