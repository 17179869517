import qs from 'qs';
import { get, cloneDeep } from 'lodash';
import request from 'utils/request';
import buildQueryParams, { buildFilterQuery } from '../utils/buildQueryParams';

export const getUsers = data => {
  const params = cloneDeep(data);
  const _customQuery = () => {
    const _getRoleQuery = () => {
      const roles = get(params, 'filters.roles.values', []);
      const queries = roles.map(role => buildFilterQuery({ roles: { values: role } }));
      return queries.join(',');
    };

    const roleQuery = _getRoleQuery();
    delete params.filters.roles;
    const normallyQuery = buildQueryParams(params);
    normallyQuery.q = normallyQuery.q ? `${normallyQuery.q}${roleQuery ? `;${roleQuery}` : ''}` : roleQuery;
    return `${qs.stringify(normallyQuery)}`;
  };

  return request.get(`/users?${_customQuery()}`);
};

export const getUserProfile = () => request.get('/users/me');
export const getUserRoles = username => request.get(`/users/${username}/roles`);
export const updateUserRoles = (username, roles) => request.put(`/users/${username}/roles`, roles);
export const createUser = user => request.post('/users', user);
export const updateUser = user => request.put(`/users/${user.id}`, user);
export const deleteUser = id => request.delete(`/users/${id}`);
export const changePassword = data => request.put('/users/me/password', data);
export const signIn = (username, password, taxCode) => {
  return request.post(
    '/sessions/login',
    { username, password },
    {
      headers: { [taxCode === 'system' ? 'tenant' : 'taxCode']: taxCode }
    }
  );
};
