import React from 'react';

export default React.createContext({
  sendInvoiceToCustomer: () => null,
  viewInvoice: () => null,
  editInvoice: () => null,
  replaceInvoice: () => null,
  exportPdf: () => null,
  exportXml: () => null,
  deleteInvoice: () => null,
  deleteInvoices: () => null,
  issueInvoice: () => null,
  issueInvoices: () => null,
  cancelInvoice: () => null,
  adjustInvoice: () => null,
  cloneInvoice: () => null,
  viewCancellations: () => null,
  convertInvoice: () => null,
  viewConvertedInvoice: () => null,
  exportInvoices: () => null
});
