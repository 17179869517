import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'antd';
import AttachmentFormItem from 'components/FormItem/AttachmentFormItem';
import { showMessage, showResponseError } from 'functions';
import { uploadDocument } from 'services/document';
import { requestUploadUrl } from 'services/decisionApplication';
import { clearDecisionApplications } from 'queries/decisionApplication';

const DecisionApplicationAttachmentModal = props => {
  const { visible, format, decisionApplication, onOk, onCancel } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [readyUpload, setReadyUpload] = React.useState(false);

  const handleFileChange = ({ fileList }) => {
    const [file] = fileList;
    const isFile = file instanceof File;
    setReadyUpload(isFile);
  };

  const ok = async () => {
    const { fileList } = await form.validateFields();
    try {
      const [file] = fileList;
      setLoading(true);
      const response = await requestUploadUrl(decisionApplication.id, file.name, format);
      await uploadDocument(file, response);
      await clearDecisionApplications();
      onOk();
      setLoading(false);
      showMessage({ content: 'Đã tải lên tệp đính kèm thành công.' });
    } catch (error) {
      showResponseError({ error });
    }
  };

  React.useEffect(() => {
    if (visible && decisionApplication.download_attachment_url) {
      form.setFieldsValue({ fileList: [decisionApplication.download_attachment_url] });
    }
  }, [visible, decisionApplication, form]);
  return (
    <Modal
      visible={visible}
      title="Tải lên tệp đính kèm"
      okText="Tải lên"
      cancelText="Thoát"
      destroyOnClose
      confirmLoading={loading}
      okButtonProps={{ disabled: !readyUpload }}
      onCancel={onCancel}
      onOk={ok}
    >
      <Form form={form} layout="vertical" preserve={false} onValuesChange={handleFileChange}>
        <AttachmentFormItem isRequired label="Biên bản đính kèm" name="fileList" />
      </Form>
    </Modal>
  );
};

DecisionApplicationAttachmentModal.defaultProps = {
  visible: false,
  format: ''
};

DecisionApplicationAttachmentModal.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  decisionApplication: PropTypes.object.isRequired,
  visible: PropTypes.bool,
  format: PropTypes.string
};
export default DecisionApplicationAttachmentModal;
