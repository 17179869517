import React, { useEffect } from 'react';
import { exportInvoiceSummaryReport } from 'services/invoiceSummary';
import LoadingModal from 'components/Loading/LoadingModal';
import { download } from 'functions';
import usePollingExportReport from 'hooks/usePollingExportReport';

const InvoiceSummaryExportModal = ({ onOk, isLoading, id, format = 'xml', ...rest }) => {
  const [{ url, loading }] = usePollingExportReport({
    exportFunc: exportInvoiceSummaryReport,
    args: [id, format]
  });

  useEffect(() => {
    if (url) {
      onOk();
      download(url);
    }
  }, [url, loading, onOk]);

  return <LoadingModal {...rest} />;
};

export default InvoiceSummaryExportModal;
