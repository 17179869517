import React, { useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import ModalContext from 'components/Modal/ModalContext';
import ViewInvoicePDFModal from 'components/_share/ViewInvoicePDFModal';
import ExportFileModal from 'components/_share/ExportFileModal';
import SigningEntityModal from 'components/_share/SigningEntityModal';
import RedirectPageForm04Confirm from 'components/_share/Invoice/RedirectPageForm04Confirm';
import { ModalType } from 'layouts/Modals';
import { useSendInvoiceToCustomer, useInvoiceExistInForm04 } from 'queries/invoice';
import {
  refreshInvoices,
  checkHasAdjusted,
  exportInvoiceReport,
  exportInvoiceData,
  requestInvoiceSignPayload,
  checkIfActionAvailableDecree123,
  checkIfActionAvailableDecree51
} from 'services/invoice';
import { formatDate } from 'utils/format';
import { INVOICE_STAGE, AUTHORITY_INVOICE_STATUS, INVOICE_STATUS } from 'constants/invoice';
import { showMessage } from 'functions';
import { SecurityContext } from 'SecurityContext';
import { DECREE_TYPE } from 'constant';
import InvoiceContext from './InvoiceContext';
import DeleteModal from './list/components/InvoiceDeleteModal';
import CloneModal from './list/components/InvoiceCloneModal';
import InvoiceReplaceModal from './list/components/InvoiceReplaceModal';
import InvoiceAdjustModal from './list/components/InvoiceAdjustModal';
import InvoiceCancelModal from './list/components/InvoiceCancelModal';

const InvoiceContextWrapper = ({ children }) => {
  const history = useHistory();
  const { decree } = React.useContext(SecurityContext);
  const [viewModal, setViewModal] = React.useState({ visible: false });
  const [cloneModal, setCloneModal] = React.useState({ visible: false });
  const [exportModal, setExportModal] = React.useState({ visible: false });
  const [deleteModal, setDeleteModal] = React.useState({ visible: false });
  const [replaceModal, setReplaceModal] = React.useState({ visible: false });
  const [adjustModal, setAdjustModal] = React.useState({ visible: false });
  const [cancelModal, setCancelModal] = React.useState({ visible: false });
  const [confirm04Modal, setConfirm04Modal] = React.useState({ visible: false });
  const [signingModal, setSigningModal] = React.useState({ visible: false });

  const { openModal, closeModal } = useContext(ModalContext);
  const contextValueRef = useRef();

  const checkIfActionAvailable = (...params) => {
    if (DECREE_TYPE.DECREE_123 === decree) return checkIfActionAvailableDecree123(...params);
    return checkIfActionAvailableDecree51(...params);
  };

  const showRedirectPage04Confirm = actionType => {
    setConfirm04Modal({
      visible: true,
      actionType,
      onOk: () => {
        _closeModal(setConfirm04Modal);
        history.push('/invoice/cancellation-notification');
      }
    });
  };

  const [check] = useInvoiceExistInForm04();
  const shouldRedirectPageForm04 = async (ids, actionType, next) => {
    const { pathname } = window.location;
    check(
      { ids },
      {
        onSuccess: ({ message }) => {
          if (message && pathname !== '/invoice/cancellation-notification') {
            showRedirectPage04Confirm(actionType);
          } else {
            next();
          }
        }
      }
    );
  };

  const exportInvoices = (tableConfig, isIncludeProducts) => {
    const fileName = isIncludeProducts ? 'danh-sach-hd-chi-tiet-' : 'danh-sach-hd-tom-gon-';
    setExportModal({
      visible: true,
      params: [tableConfig, isIncludeProducts],
      fileName: `${fileName}${formatDate(new Date())}.xlsx`,
      exportFunc: exportInvoiceData
    });
  };

  const doIssueInvoice = async (invoice, recipients = []) => {
    return new Promise(resolve => {
      const generatePayload = () => {
        if (Array.isArray(invoice)) {
          return invoice.map(item => ({
            id: item.id,
            recipients,
            requestSignPayload: requestInvoiceSignPayload
          }));
        }
        return {
          id: invoice.id,
          recipients,
          requestSignPayload: requestInvoiceSignPayload
        };
      };

      setSigningModal({
        visible: true,
        payload: generatePayload(),
        onOk: () => {
          resolve(true);
          _closeModal(setSigningModal);
          refreshInvoices();
          showMessage({ content: 'Đã phát hành hoá đơn thành công.' });
        },
        onCancel: () => {
          _closeModal(setSigningModal);
          resolve();
        }
      });
    });
  };

  const issueInvoice = invoice => {
    return openModal(ModalType.INVOICE_ISSUE, {
      onOk: async emails => {
        closeModal();
        await doIssueInvoice(invoice, emails);
      },
      invoice
    });
  };

  const issueInvoices = (invoices, callback) => {
    return openModal(ModalType.INVOICE_ISSUE_CONFIRM, {
      onOk: async () => {
        closeModal();
        await doIssueInvoice(invoices);
        callback();
      }
    });
  };

  const sendInvoiceToCustomer = invoice => {
    const { stage, has_tax_authorities, authority_status } = invoice;
    const isIssuedNoAuthInvoice = !has_tax_authorities && stage === INVOICE_STAGE.SIGNED;
    const isIssuedAuthInvoice =
      has_tax_authorities && authority_status === AUTHORITY_INVOICE_STATUS.SUCCESS && stage === INVOICE_STAGE.SIGNED;
    const isIssued = isIssuedAuthInvoice || isIssuedNoAuthInvoice;
    openModal(ModalType.SEND_EMAIL, {
      data: invoice,
      isIssued,
      sendEmailMethod: useSendInvoiceToCustomer,
      onOk: closeModal
    });
  };

  const cancelInvoice = async (invoice, signedForm04 = false) => {
    const _close = resolve => {
      _closeModal(setCancelModal);
      resolve();
    };
    return new Promise(async resolve => {
      await shouldRedirectPageForm04([invoice.id], 'cancel', async () => {
        setCancelModal(prev => ({
          ...prev,
          visible: true,
          signedForm04,
          invoice,
          onOk: () => _close(resolve),
          onCancel: () => _close(resolve)
        }));
      });
    });
  };

  const replaceInvoice = async (invoice, signedForm04 = false) => {
    return new Promise(async resolve => {
      await shouldRedirectPageForm04([invoice.id], 'replace', async () => {
        if (decree === DECREE_TYPE.DECREE_123) {
          const hasAdjusted = await checkHasAdjusted(invoice.id);
          if (hasAdjusted) {
            showMessage({ type: 'error', content: 'Không thể thay thế hóa đơn bị điều chỉnh.' });
            return;
          }
        }
        const { invoice_replace_id, replace_symbol, replace_serial_number } = invoice;
        if (invoice_replace_id) {
          showMessage({
            type: 'error',
            content:
              replace_symbol && replace_serial_number
                ? `Hoá đơn này đã được thay thế bởi hoá đơn ${replace_symbol}, số hoá đơn ${replace_serial_number}.`
                : 'Hoá đơn này đã được lập hoá đơn thay thế.'
          });
          return;
        }
        setReplaceModal(prev => ({
          ...prev,
          visible: true,
          signedForm04,
          invoice,
          onCancel: () => {
            _closeModal(setReplaceModal);
            resolve();
          }
        }));
      });
    });
  };

  const adjustInvoice = async (invoice, signedForm04 = false) => {
    return new Promise(async resolve => {
      await shouldRedirectPageForm04([invoice.id], 'adjust', async () => {
        if (decree === DECREE_TYPE.DECREE_123) {
          if (invoice.status === INVOICE_STATUS.REPLACED) {
            showMessage({ type: 'error', content: 'Không thể điều chỉnh hóa đơn thay thế.' });
            return;
          }
        }
        setAdjustModal(prev => ({
          ...prev,
          visible: true,
          signedForm04,
          invoice,
          onCancel: () => {
            _closeModal(setAdjustModal);
            resolve();
          }
        }));
      });
    });
  };

  const viewCancellations = invoice => {
    return openModal(ModalType.VIEW_CANCELLATION_NOTIFICATIONS, { invoice });
  };

  const viewConvertedInvoice = invoice =>
    openModal(
      ModalType.VIEW_CONVERTED_INVOICE,
      {
        invoice
      },
      ({ children }) => <InvoiceContext.Provider value={contextValueRef.current}>{children}</InvoiceContext.Provider>
    );

  const convertInvoice = invoice => {
    return openModal(ModalType.INVOICE_CONVERT, {
      decree,
      invoice,
      onOk: () => {
        closeModal();
        viewConvertedInvoice(invoice);
      }
    });
  };

  const _closeModal = _func => _func({ visible: false });

  const _getExportFileName = (invoice, extension) => {
    const { invoice_template, serial_number, symbol } = invoice;
    if (decree === DECREE_TYPE.DECREE_51) {
      return `${invoice_template.form}-${serial_number || '0000000'}.${extension}`;
    }
    if (decree === DECREE_TYPE.DECREE_123) {
      return `${invoice_template.form + symbol}-${serial_number || '00000000'}.${extension}`;
    }
  };

  const viewInvoice = (invoice, props = {}) => {
    setViewModal(prev => ({
      ...prev,
      ...props,
      data: invoice,
      visible: true
    }));
  };

  const editInvoice = item => history.push(`/invoice/${item.id}/edit`);

  const exportPdf = invoice => {
    setExportModal({
      visible: true,
      params: [invoice.id, 'pdf'],
      fileName: _getExportFileName(invoice, 'pdf'),
      exportFunc: exportInvoiceReport
    });
  };

  const exportXml = invoice => {
    setExportModal({
      visible: true,
      params: [invoice.id, 'xml'],
      fileName: _getExportFileName(invoice, 'xml'),
      exportFunc: exportInvoiceReport
    });
  };

  const deleteInvoice = (invoice, callback) => {
    setDeleteModal(prev => ({
      ...prev,
      visible: true,
      data: invoice,
      onOk: () => {
        _closeModal(setDeleteModal);
        _closeModal(setViewModal);
        callback && callback();
      }
    }));
  };

  const cloneInvoice = invoice => {
    setCloneModal(prev => ({
      ...prev,
      visible: true,
      data: invoice,
      onOk: () => _closeModal(setCloneModal)
    }));
  };

  return (
    <InvoiceContext.Provider
      value={{
        viewInvoice,
        editInvoice,
        replaceInvoice,
        exportPdf,
        exportXml,
        deleteInvoice,
        cloneInvoice,
        issueInvoice,
        cancelInvoice,
        issueInvoices,
        adjustInvoice,
        sendInvoiceToCustomer,
        viewCancellations,
        convertInvoice,
        viewConvertedInvoice,
        exportInvoices,
        checkIfActionAvailable
      }}
    >
      <InvoiceCancelModal decree={decree} onCancel={() => _closeModal(setCancelModal)} {...cancelModal} />
      <InvoiceAdjustModal onCancel={() => _closeModal(setAdjustModal)} {...adjustModal} />
      <InvoiceReplaceModal onCancel={() => _closeModal(setReplaceModal)} {...replaceModal} />
      <SigningEntityModal onCancel={() => _closeModal(setSigningModal)} {...signingModal} />
      <ExportFileModal {...exportModal} onCancel={() => _closeModal(setExportModal)} />
      <DeleteModal {...deleteModal} onCancel={() => _closeModal(setDeleteModal)} />
      <CloneModal {...cloneModal} onCancel={() => _closeModal(setCloneModal)} />
      <RedirectPageForm04Confirm {...confirm04Modal} onCancel={() => _closeModal(setConfirm04Modal)} />
      <ViewInvoicePDFModal
        {...viewModal}
        checkIfActionAvailable={checkIfActionAvailable}
        onCancel={() => _closeModal(setViewModal)}
      />
      {children}
    </InvoiceContext.Provider>
  );
};

export default InvoiceContextWrapper;
