import { includes } from 'ramda';
import moment from 'moment';
import request from 'utils/request';
import { TAX_RATE } from 'constants/tax';
import { DEFAULT_EXPORT_INTERVAL, DEFAULT_EXPORT_TIMEOUT } from '../constant';
import { isReportExportCompleted } from './invoice';

/**
 * Report stage.
 */
export const ReportStage = Object.freeze({
  SIGNED: 'SIGNED',
  WAITING: 'WAITING'
});

/**
 * Report type.
 */
export const ReportType = Object.freeze({
  INVOICE_REPORT_GROUP_BY_CONSUMER: 'INVOICE_REPORT_GROUP_BY_CONSUMER',
  INVOICE_REPORT_GROUP_BY_PRODUCT: 'INVOICE_REPORT_GROUP_BY_PRODUCT',
  INVOICE_REPORT_GROUP_BY_VAT: 'INVOICE_REPORT_GROUP_BY_VAT',
  INVOICE_DETAIL_REPORT: 'INVOICE_DETAIL_REPORT'
});

const title = {
  [TAX_RATE.NONE]: '1. Hàng hóa, dịch vụ không chịu thuế giá trị gia tăng (GTGT):',
  [TAX_RATE.ZERO]: '2. Hàng hóa, dịch vụ chịu thuế suất thuế GTGT 0%:',
  [TAX_RATE.FIVE]: '3. Hàng hóa, dịch vụ chịu thuế suất thuế GTGT 5%:',
  [TAX_RATE.TEN]: ' 4. Hàng hóa, dịch vụ chịu thuế suất thuế GTGT 10%:'
};

/**
 * Sold invoice status
 */
export const SoldInvoiceStatus = Object.freeze({
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
});

const ActionStatusMapping = Object.freeze({
  edit: [SoldInvoiceStatus.NOT_SUBMITTED, SoldInvoiceStatus.ERROR],
  delete: [SoldInvoiceStatus.NOT_SUBMITTED, SoldInvoiceStatus.ERROR],
  issue: [SoldInvoiceStatus.NOT_SUBMITTED, SoldInvoiceStatus.ERROR]
});

const calculateReducer = ({ amount, vat_amount }, { amount: a, vat_amount: va }) => ({
  amount: amount + a,
  vat_amount: vat_amount + va
});

const calculateTotal = (dataSource, vatRate) => {
  const result = dataSource
    ? dataSource.reduce(calculateReducer, {
        amount: 0,
        vat_amount: 0,
        vat_rate: vatRate
      })
    : { amount: 0, vat_amount: 0 };
  return { ...result, vat_rate: vatRate };
};

const convertDataSource = (dataSource = {}) => [
  {
    title: title[TAX_RATE.NONE],
    items: dataSource[TAX_RATE.NONE] || [],
    total: calculateTotal(dataSource[TAX_RATE.NONE], TAX_RATE.NONE)
  },
  {
    title: title[TAX_RATE.ZERO],
    items: dataSource[TAX_RATE.ZERO] || [],
    total: calculateTotal(dataSource[TAX_RATE.ZERO], TAX_RATE.ZERO)
  },
  {
    title: title[TAX_RATE.FIVE],
    items: dataSource[TAX_RATE.FIVE] || [],
    total: calculateTotal(dataSource[TAX_RATE.FIVE], TAX_RATE.FIVE)
  },
  {
    title: title[TAX_RATE.TEN],
    items: dataSource[TAX_RATE.TEN] || [],
    total: calculateTotal(dataSource[TAX_RATE.TEN], TAX_RATE.TEN)
  }
];

const calculateSummaryTotal = dataSource => {
  return dataSource.reduce(
    ({ amount, vat_amount }, { total }) => ({
      amount: total.vat_rate === TAX_RATE.NONE ? amount : amount + total.amount,
      vat_amount: total.vat_rate === TAX_RATE.NONE ? vat_amount : vat_amount + total.vat_amount
    }),
    {
      amount: 0,
      vat_amount: 0
    }
  );
};

/**
 * Get sold report data
 */
export const createReport = async params => {
  const result = await request.post(
    `/reports/invoices`,
    {},
    {
      params
    }
  );

  const { id, stage, organization, lines } = result;
  const convertedDataSource = convertDataSource(lines);
  const summaryTotal = calculateSummaryTotal(convertedDataSource);
  return {
    id,
    stage,
    organization,
    data: convertedDataSource,
    total: summaryTotal
  };
};

/**
 * Get sold report data
 */
export const generatePdfReport = params =>
  request.post(
    `/reports/invoices/export`,
    {},
    {
      params
    }
  );

export const exportExcelReport = (
  report_type,
  organization,
  time,
  invoice_template_ids = [],
  forms = [],
  symbols = []
) => {
  return request.post('/invoices/export-to-excel', {
    organization_id: organization.id,
    report_type: report_type,
    start_date: moment.unix(time.fromDate / 1000),
    end_date: moment.unix(time.toDate / 1000),
    invoice_template_ids,
    period_type: time.period_type,
    forms,
    symbols
  });
};

export const exportReport = (id, type = ReportType.INVOICE_REPORT_GROUP_BY_VAT, format = 'XML') =>
  request.post(
    `/reports/invoices/${id}/export`,
    {},
    {
      params: { format, type }
    }
  );

/**
 * Request invoice base64 payload to sign.
 */
export const requestReportSignPayload = (
  id,
  serialNumber,
  type = ReportType.INVOICE_REPORT_GROUP_BY_VAT,
  format = 'xml'
) =>
  request.post(
    `/reports/invoices/${id}/signing`,
    { serial_number: serialNumber },
    {
      params: { format, type }
    }
  );

export const checkIfActionAvailable = (action, status) => includes(status)(ActionStatusMapping[action]);

/**
 * Export invoice usage report.
 */
export const exportInvoiceUsageReport = (data, format = 'xml') =>
  request.post(`/reports/invoice-usage-summaries/export`, data, {
    params: { format }
  });

/**
 * Customer invoice value.
 */
export const calculateCustomerInvoiceValueByRange = data => request.post(`/reports/invoices/customer-revenues`, data);

export const calculateProductWithHighestSales = data => request.post(`/reports/products/selling`, data);

/**
 * Poll report.
 */
export const pollReport = (fn, timeout = DEFAULT_EXPORT_TIMEOUT, interval = DEFAULT_EXPORT_INTERVAL) => {
  const endTime = Number(new Date()) + (timeout || 2000);
  interval = interval || 100;

  const checkCondition = async (resolve, reject) => {
    const result = await fn();
    if (result && isReportExportCompleted(result.status)) {
      resolve(result);
    } else if (Number(new Date()) < endTime) {
      setTimeout(checkCondition, interval, resolve, reject);
    } else {
      reject(new Error('timed out for ' + fn));
    }
  };

  return new Promise(checkCondition);
};
