import { TreeSelect } from 'antd';
import { map, toLower } from 'ramda';
import React, { useMemo } from 'react';
import { usePermissions } from 'queries/role';

const PermissionSelect = ({ ...props }) => {
  const { data: items = [] } = usePermissions();

  const computedPermissions = useMemo(
    () =>
      map(item => ({
        ...item,
        title: item.name,
        value: item.name,
        key: item.name,
        children: map(p => ({
          ...p,
          title: `${p.name} ${toLower(item.name)}`,
          value: p.code,
          key: p.code
        }))(item.permissions)
      }))(items),
    [items]
  );

  const handlePermissionChange = () => {};

  return (
    <TreeSelect
      treeData={computedPermissions}
      onChange={handlePermissionChange}
      treeCheckable={true}
      showCheckedStrategy={TreeSelect.SHOW_CHILD}
      placeholder="Vui lòng chọn quyền"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default PermissionSelect;
