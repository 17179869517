import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TenantOrganizationModal = ({ loading, onSubmit, ...props }) => {
  const [t] = useTranslation();
  return (
    <Modal okText="Khởi tạo" cancelText={t('button.cancel')} title="Xác nhận" confirmLoading={loading} {...props}>
      <span>Bạn có muốn khởi tạo công ty?</span>
    </Modal>
  );
};

export default TenantOrganizationModal;
