import config from 'config/app.config';
import { FILE_STATUS } from 'constants/status';

const { maxExportRetries } = config;
export default async (request, options = {}) => {
  const completedStatus = [FILE_STATUS.done, FILE_STATUS.fail];
  const defaultOption = { max: maxExportRetries, delay: 2000, completedStatus, ...options };
  let { max, delay } = defaultOption;
  let completed = false;

  const _isCompleted = status => defaultOption.completedStatus.includes(status);
  return new Promise(async resolve => {
    const _resolver = res => {
      completed = true;
      resolve(res);
    };
    const _polling = async () => {
      --max;
      request()
        .then(response => {
          if (_isCompleted(response.status)) _resolver(response);
          if (!max) _resolver({ status: FILE_STATUS.fail });
          if (!completed) setTimeout(_polling, delay);
        })
        .catch(e => _resolver({ status: FILE_STATUS.notFound, error: e }));
    };
    await _polling();
  });
};
