import { queryCache } from 'react-query';
import buildQueryParams from 'utils/buildQueryParams';
import request from 'utils/request';

export const AnnouncementStatusEnum = Object.freeze({
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED'
});

export const AnnouncementStageEnum = Object.freeze({
  SIGNED: 'SIGNED',
  WAITING: 'WAITING'
});

export const AnnouncementTypeEnum = Object.freeze({
  NEW: 'NEW',
  CANCEL_NOT_REPLACEMENT: 'CANCEL_NOT_REPLACEMENT',
  ADJUST: 'ADJUST',
  CANCEL_REPLACEMENT: 'CANCEL_REPLACEMENT',
  EXPLANATION: 'EXPLANATION',
  ERROR_DATA_COLLECTION: 'ERROR_DATA_COLLECTION'
});

const ActionAnnouncementTypeMapping = Object.freeze({
  adjust: [
    AnnouncementTypeEnum.NEW,
    AnnouncementTypeEnum.ADJUST,
    AnnouncementTypeEnum.ERROR_DATA_COLLECTION,
    AnnouncementTypeEnum.EXPLANATION
  ],
  replace: [
    AnnouncementTypeEnum.NEW,
    AnnouncementTypeEnum.CANCEL_REPLACEMENT,
    AnnouncementTypeEnum.EXPLANATION,
    AnnouncementTypeEnum.ERROR_DATA_COLLECTION
  ],
  cancel: [
    AnnouncementTypeEnum.NEW,
    AnnouncementTypeEnum.CANCEL_NOT_REPLACEMENT,
    AnnouncementTypeEnum.EXPLANATION,
    AnnouncementTypeEnum.ERROR_DATA_COLLECTION
  ]
});

const ActionStatusMapping = Object.freeze({
  edit: [AnnouncementStatusEnum.NOT_SUBMITTED, AnnouncementStatusEnum.ERROR],
  delete: [AnnouncementStatusEnum.NOT_SUBMITTED, AnnouncementStatusEnum.ERROR],
  issue: [AnnouncementStatusEnum.NOT_SUBMITTED, AnnouncementStatusEnum.ERROR]
});

export const checkIfActionAvailable = (action, stage) => ActionStatusMapping[action].includes(stage);

export const checkIfAnnounActionAvailable = (action, type) => {
  return ActionAnnouncementTypeMapping[action].includes(type);
};

export const getCancellations = params => {
  return request.get('/invoices/announcements', {
    params: buildQueryParams(params)
  });
};

export const getCancellation = id => request.get(`/invoices/announcements/${id}`);

export const exportCancellation = (id, format = 'pdf') => {
  return request.post(
    `/invoices/announcements/${id}/export`,
    { id },
    {
      params: { format }
    }
  );
};

export const createCancellation = cancellation => request.post('/invoices/announcements', cancellation);

export const deleteCancellation = id => request.delete(`/invoices/announcements/${id}`);

export const requestCancellationSignPayload = (id, serial_number, force_sign) =>
  request.post(
    `/invoices/announcements/${id}/signing`,
    { serial_number, force_sign },
    {
      params: { format: 'xml' }
    }
  );

export const editCancellation = cancellation => {
  return request.put(`/invoices/announcements/${cancellation.id}`, cancellation);
};

export const refreshCancellations = () => queryCache.invalidateQueries('cancellation-notifications');
