import React from 'react';
import { Redirect } from 'react-router-dom';
import { getToken } from 'utils/authority';

/**
 * Check for token before rendering child
 * If have no token, redirect to login page
 */
export default ({ children }) => {
  const token = getToken();
  if (!token) {
    return <Redirect to={'/landing/home'} />;
  }

  return children;
};
