import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { showMessage, showResponseError } from 'functions';
import { usePollReport } from 'queries/report';

export default ({ exportFunc, args, auto = true }) => {
  const [t] = useTranslation();
  const [pollReport, { isLoading }] = usePollReport();
  const [url, setUrl] = useState();

  const clearUrl = useCallback(() => setUrl(''), []);

  const startExport = () => {
    pollReport(() => exportFunc.apply(null, args), {
      onSuccess: reportRes => {
        if (reportRes.download_url) {
          setUrl(reportRes.download_url);
        } else {
          showMessage({
            type: 'error',
            content: t('general.export-error')
          });
        }
      },
      onError: error => showResponseError({ error })
    });
  };

  useEffect(() => {
    if (auto) {
      startExport();
    }
  }, []); // eslint-disable-line

  return [
    {
      url,
      loading: isLoading,
      clear: clearUrl,
      startExport
    }
  ];
};
