import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Spin } from 'antd';
import PDFViewer from 'components/PDF/PDFViewer';
import { pollingRequest } from 'functions';
import styles from './index.less';

const ViewModal = React.forwardRef(({ data, visible, action, exportFunc, ...restProps }, ref) => {
  const [loading, setLoading] = React.useState(false);
  const [url, setUrl] = React.useState('');

  React.useEffect(() => {
    if (data.id && visible && exportFunc) {
      (async function () {
        setLoading(true);
        const _f = () => exportFunc(data.id, 'pdf');
        const { url } = await pollingRequest(_f);
        setUrl(url);
        setLoading(false);
      })();
    }
  }, [data, visible, exportFunc]);

  return (
    <Modal width="1000px" className={styles.modal} footer={null} visible={visible} title={action} {...restProps}>
      <div className="sectionToPrint" ref={ref} style={{ textAlign: 'center' }}>
        {loading && <Spin spinning={loading} style={{ padding: 20 }} />}
        {!loading && <PDFViewer file={url} />}
      </div>
    </Modal>
  );
});

ViewModal.defaultProps = {
  data: {},
  visible: false,
  format: undefined,
  fileName: ''
};

ViewModal.propTypes = {
  fileName: PropTypes.string,
  visible: PropTypes.bool,
  data: PropTypes.any
};

export default ViewModal;
