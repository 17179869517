import { Form, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './tenant-modal.less';
import TenantAccountForm from './TenantAccountForm';

const TenantAccountModal = ({ title, tenant, loading, onSubmit, ...props }) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();

  const submitTenant = async () => {
    const values = await form.validateFields();
    onSubmit(values);
  };

  return (
    <Modal
      title={title}
      width={703}
      className={styles.userModal}
      cancelText={t('button.cancel')}
      okText="Khởi tạo"
      onOk={submitTenant}
      confirmLoading={loading}
      {...props}
    >
      <TenantAccountForm form={form} tenant={tenant} />
    </Modal>
  );
};

export default TenantAccountModal;
