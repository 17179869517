import React from 'react';
import { Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useChangePassword } from 'queries/user';
import { showResponseError, showMessage } from 'functions';
import ChangePassword from 'pages/user/changePassword/components/ChangePassword';

const ChangePasswordModal = ({ onOk, mode, onCancel, ...rest }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [changePassword, { isLoading }] = useChangePassword();

  const submit = async () => {
    const values = await form.validateFields();
    changePassword(values, {
      onSuccess: () => {
        onCancel();
        showMessage({ content: 'Đã thay đổi mật khẩu thành công' });
      },
      onError: error => {
        showResponseError({ error });
      }
    });
  };

  return (
    <Modal
      okText="Đổi mật khẩu"
      cancelText={t('button.cancel')}
      title="Thay đổi mật khẩu"
      loading={isLoading}
      onOk={submit}
      onCancel={onCancel}
      {...rest}
    >
      <Form form={form} layout="vertical" requiredMark={false}>
        <ChangePassword form={form} hideOldPassword={false} />
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
