import { Col, Form, Input, Row } from 'antd';
import React, { useEffect } from 'react';
import { USER_STATUS } from 'constants/user';
import { concat } from 'ramda';
import rules from 'utils/rules';

const TenantForm = ({ form, tenant = { status: USER_STATUS.ACTIVE } }) => {
  useEffect(() => {
    form.setFieldsValue(tenant);
  }, [form, tenant]);

  return (
    <Form form={form} colon={false} layout="vertical">
      <Row gutter={10}>
        <Col span={24}>
          <Form.Item
            label="Tên tenant"
            name="name"
            rules={[
              {
                required: true,
                message: 'Tên tenant không được để trống'
              },
              {
                pattern: '^[A-Za-z0-9]*$',
                message: 'Tên tenant chỉ hỗ trợ ký tự hoặc số'
              }
            ]}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={24}>
          <Form.Item
            label="Mã số thuế"
            name={['extra_fields', 'tax_code']}
            rules={concat([
              {
                required: true,
                message: 'Mã số thuế không được để trống'
              }
            ])(rules.tax_code)}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={24}>
          <Form.Item
            label="Tên công ty"
            name={['extra_fields', 'company_name']}
            rules={[
              {
                required: true,
                message: 'Tên công ty không được để trống'
              }
            ]}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default TenantForm;
