import { Form, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { showMessage, showResponseError } from 'functions';
import { useCreateSignature } from 'queries/signature';
import SignatureForm from './SignatureForm';
import styles from './signature-modal.less';

const SignatureRegistrationModal = ({ title, signature = {}, onSuccess, ...props }) => {
  const [form] = Form.useForm();
  const [t] = useTranslation();
  const [create, { isLoading }] = useCreateSignature();

  const createSignature = signature => {
    create(signature, {
      onSuccess: signature => {
        onSuccess(signature);
        showMessage({
          content: t('organization.signature.create-success')
        });
      },
      onError: error => showResponseError({ error })
    });
  };

  const submitSignature = async () => {
    const values = await form.validateFields();
    createSignature(values);
  };

  return (
    <Modal
      title={title}
      width={703}
      className={styles.signatureModal}
      onOk={submitSignature}
      confirmLoading={isLoading}
      {...props}
    >
      <SignatureForm form={form} signature={signature} />
    </Modal>
  );
};

export default SignatureRegistrationModal;
