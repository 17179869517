/**
 * Send message to esigner app.
 */
export const sendEsignerMessage = message => {
  return new Promise(function (resolve, reject) {
    const handleMessage = event => {
      if (event.source !== window) return;
      if (event.data.src && event.data.src === 'background.js') {
        if (event.data.result !== 'no_implementation') {
          window.removeEventListener('message', handleMessage);
          resolve(event.data);
        } else {
          console.log('No nonce in event msg');
          reject({ message: 'No nonce in event msg' });
        }
      }
    };

    const sendMessage = msg => {
      msg['nonce'] = msg.CODE;
      msg['src'] = 'page.js';
      console.log('Sending message ...');
      console.log(msg);
      window.postMessage(msg, '*');
    };

    window.addEventListener('message', handleMessage, false);
    sendMessage(message);
  });
};

/**
 * Send an esigner message with timeout.
 */
export const sendEsignerMessageWithTimeout = (message, seconds) => {
  const timeoutPromise = new Promise(resolve => setTimeout(resolve, seconds * 1000, ''));

  const sendPromise = sendEsignerMessage(message);
  return Promise.race([timeoutPromise, sendPromise]);
};
