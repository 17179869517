// ref https://github.com/hankphung/numer_to_vietnamese_text/blob/master/src/n2vi.js

const default_numbers = ' hai ba bốn năm sáu bảy tám chín';

const units = ('không một' + default_numbers).split(' ');
const ch = 'lẻ mười' + default_numbers;
const tr = 'không một' + default_numbers;
const tram = tr.split(' ');
const u = '2 nghìn triệu tỷ'.split(' ');
const chuc = ch.split(' ');
/**
 * additional words
 * @param  {[type]} a [description]
 * @return {[type]}   [description]
 */
const tenth = a => {
  let sl1 = units[a[1]];
  let sl2 = chuc[a[0]];
  let append = '';
  if (a[0] > 0 && parseInt(a[1]) === 5) sl1 = 'lăm';
  if (a[0] > 0 && parseInt(a[1]) === 0) sl1 = '';

  if (a[0] > 1) {
    append = ' mươi';
    if (parseInt(a[1]) === 1) sl1 = ' mốt';
  }
  const str = sl2 + '' + append + ' ' + sl1;
  return str;
};

/**
 * convert number in blocks of 3
 * @param  {[type]} d [description]
 * @return {[type]}   [description]
 */
const blockOfThree = d => {
  let _a = d + '';
  if (d === '000') return '';
  switch (_a.length) {
    case 0:
      return '';

    case 1:
      return units[_a];

    case 2:
      return tenth(_a);

    case 3:
      let sl12 = '';
      if (_a.slice(1, 3) !== '00') sl12 = tenth(_a.slice(1, 3));
      let sl3 = tram[_a[0]] + ' trăm';
      return sl3 + ' ' + sl12;
    default:
      return d;
  }
};

export const toVietnamese = (s, currency) => {
  let str = parseInt(s) + '';
  //str=fixCurrency(a,1000);
  const arr = [];
  let index = str.length;
  const result = [];
  if (index === 0 || str === 'NaN') return '';
  let string = '';

  //explode number string into blocks of 3numbers and push to queue
  while (index >= 0) {
    arr.push(str.substring(index, Math.max(index - 3, 0)));
    index -= 3;
  }

  //loop though queue and convert each block
  for (let i = arr.length - 1; i >= 0; i--) {
    if (arr[i] !== '' && arr[i] !== '000') {
      result.push(blockOfThree(arr[i]));
      if (u[i]) result.push(u[i]);
    }
  }
  if (currency) result.push(currency);
  string = result.join(' ');
  //remove unwanted white space
  return string.replace(/[0-9]/g, '').replace(/\s/g, ' ').replace(/ $/, '');
};

export const toVietnameseAfterComma = (number, currency) => {
  const units = ('không một' + default_numbers).split(' ');
  if (number.length === 1) return `${units[number]} ${currency}`;
  if (number.length > 1 && parseInt(number[0]) === 0) {
    return `không ${units[number[1]]} ${currency}`;
  }
  let sl1 = units[number[1]];
  let sl2 = chuc[number[0]];

  let append = '';
  if (parseInt(number[0]) > 0 && parseInt(number[1]) === 5) sl1 = 'lăm';
  if (parseInt(number[0]) > 1) {
    append = 'mươi';
    if (parseInt(number[1]) === 1) sl1 = ' mốt';
  }
  const str = `${sl2} ${append} ${sl1} ${currency}`;
  return str;
};
