import { Col, Form, Input, Row } from 'antd';
import React from 'react';
import { concat } from 'ramda';
import rules from 'utils/rules';

const TenantAccountForm = ({ form, tenant }) => (
  <Form form={form} colon={false} initialValues={tenant} layout="vertical">
    <Row gutter={10}>
      <Col span={12}>
        <Form.Item
          label="Họ"
          name="last_name"
          rules={[
            {
              required: true,
              message: 'Họ không được để trống'
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Tên"
          name="first_name"
          rules={[
            {
              required: true,
              message: 'Tên không được để trống'
            }
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={10}>
      <Col span={12}>
        <Form.Item
          label="Tài khoản"
          name="username"
          rules={[
            {
              required: true,
              message: 'Tài khoản không được để trống'
            }
          ]}
        >
          <Input placeholder="" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Email"
          name="email"
          rules={concat([
            {
              required: true,
              message: 'Email không được để trống'
            }
          ])(rules.email)}
        >
          <Input type="email" />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={10}>
      <Col span={12}>
        <Form.Item
          label="Mật khẩu"
          name="password"
          rules={[
            {
              required: true,
              message: 'Mật khẩu không được để trống'
            }
          ]}
        >
          <Input.Password placeholder="" />
        </Form.Item>
      </Col>
    </Row>
  </Form>
);

export default TenantAccountForm;
