import React from 'react';
import { Modal, Steps, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { exportProducts } from 'services/product';
import { download } from 'functions';
import { ReactComponent as DownloadIcon } from 'assets/icons/action-download.svg';
import FileDraggerUpload from './FileDraggerUpload';
import styles from './ProductImportModal.less';

const ProductImportModal = ({ onOk, isLoading, ...rest }) => {
  const [t] = useTranslation();

  const handleDownloadUrl = fileFormat => {
    const url = exportProducts(fileFormat);
    download(url, `mau-nhap-san-pham.${fileFormat}`);
  };

  const step1stDescription = (
    <>
      {t('category.product.import.1stDescription')}
      <div className={styles.downloadTemplateWrapper}>
        <Tooltip title={t('button.downloadXLS')}>
          <div className={styles.downloadTemplateBtn} onClick={() => handleDownloadUrl('xls')}>
            <DownloadIcon />
            {t('button.downloadXLS')}
          </div>
        </Tooltip>
        <Tooltip title={t('button.downloadXLSX')}>
          <div className={styles.downloadTemplateBtn} onClick={() => handleDownloadUrl('xlsx')}>
            <DownloadIcon />
            {t('button.downloadXLSX')}
          </div>
        </Tooltip>
      </div>
    </>
  );

  const step3rdDescription = (
    <>
      <p style={{ marginBottom: 22 }}>{t('category.product.import.3rdDescription')}</p>
      <FileDraggerUpload onOk={onOk} isLoading={isLoading} />
    </>
  );

  return (
    <Modal
      title={t('category.product.import.title')}
      width={663}
      className={styles.importModal}
      footer={null}
      {...rest}
    >
      <Steps direction="vertical" progressDot status="wait">
        <Steps.Step
          title={t('category.product.import.1stStep')}
          className={styles.step1st}
          description={step1stDescription}
        />
        <Steps.Step
          title={t('category.product.import.2ndStep')}
          description={t('category.product.import.2ndDescription')}
        />
        <Steps.Step title={t('category.product.import.3rdStep')} description={step3rdDescription} />
      </Steps>
    </Modal>
  );
};

export default ProductImportModal;
