import React from 'react';
import { Tooltip, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { download } from 'functions';
import { ReactComponent as DownloadPDFIcon } from 'assets/icons/action-download-pdf.svg';
import styles from './ConvertedInvoiceActions.less';

const ConvertedInvoiceActions = ({ download_url: url, invoice = {} }) => {
  const [t] = useTranslation();
  const { invoice_template: invoiceTemplate, serial_number: serialNumber } = invoice;
  const filename = serialNumber
    ? `${invoiceTemplate.form}-${serialNumber}-chuyen-doi.pdf`
    : `${invoiceTemplate.form}-0000000-chuyen-doi.pdf`;
  return (
    <Space>
      <div className={styles.actions}>
        <Tooltip title={t('invoice.registration.action.download-pdf')}>
          <DownloadPDFIcon onClick={() => download(url, filename)} />
        </Tooltip>
      </div>
    </Space>
  );
};

export default ConvertedInvoiceActions;
