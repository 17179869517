import config from 'config/app.config';

const { maxExportRetries } = config;
export default function isUrlDataReady(url, options = {}) {
  const defaultOption = { max: maxExportRetries, delay: 1000 };
  const newOptions = { ...defaultOption, ...options };
  const _urlExists = url => {
    return new Promise(resolve => {
      const http = new XMLHttpRequest();
      http.open('GET', url, true);
      http.send();
      http.onload = function () {
        resolve(this.status === 200);
      };
    });
  };
  //eslint-disable-next-line
  let { max, delay } = newOptions;
  const timeout = null;
  return new Promise(resolve => {
    (function check() {
      if (max <= 0) {
        return resolve({ error: '404' });
      }
      setTimeout(async () => {
        const isAvailable = await _urlExists(url);
        if (isAvailable) return resolve({ url });
        clearTimeout(timeout);
        --max;
        check();
      }, delay);
    })(max);
  });
}
