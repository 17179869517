import { queryCache, useMutation } from 'react-query';
import {
  createReport,
  calculateProductWithHighestSales,
  calculateCustomerInvoiceValueByRange,
  pollReport
} from 'services/report';
import {
  extractSerialNumber,
  signEntity,
  validateIfSigningReady
} from 'services/signing';
import { requestReportSignPayload } from 'services/report';
import { sendEsignerMessage } from 'services/esigner';
import { signBase64Message } from 'services/signature';

/**
 * Get sold report data hook.
 */
export const useCreateReport = () =>
  useMutation(params => createReport(params));

/**
 * Request report base64 payload to sign
 */
export const useRequestReportSignPayload = () =>
  useMutation(async id => {
    const certPayload = await validateIfSigningReady();
    const serialNumber = extractSerialNumber(certPayload);
    const payload = await requestReportSignPayload(id, serialNumber);
    return Promise.resolve(payload);
  });

/**
 * Sign report hook.
 */
export const useSignReport = () =>
  useMutation(({ id, data }) => signEntity(id, data), {
    onSettled: () => {
      queryCache.invalidateQueries('report');
    }
  });

/**
 * Calculate customer invoice by range hook.
 */
export const useCalculateCustomerInvoiceValueByRange = () =>
  useMutation(id => calculateCustomerInvoiceValueByRange(id));

/**
 * Poll report hook.
 */
export const usePollReport = () => useMutation(fn => pollReport(fn));

/**
 * Calculate product with highest sales
 */
export const useCalculateProductWithHighestSales = () =>
  useMutation(data => calculateProductWithHighestSales(data));
/**

/**
 * Sign a sold report hook.
 */
export const useIssueReport = () =>
  useMutation(
    async id => {
      const certPayload = await validateIfSigningReady();
      const serialNumber = extractSerialNumber(certPayload);
      const requestedPayload = await requestReportSignPayload(id, serialNumber);
      const signedPayload = await sendEsignerMessage(
        signBase64Message(requestedPayload)
      );
      const {
        CERT_CHAIN: certChain,
        SIGNED_CONTENT: signedContent,
        CERT: certificate,
        PIN_CODE: pinCode
      } = signedPayload;
      return signEntity(requestedPayload.id, {
        id: requestedPayload.id,
        payload: requestedPayload.payload,
        signature: signedContent,
        cert: certificate,
        cert_chain: certChain,
        pin_code: pinCode,
        signature_path: requestedPayload.signature_path
      });
    },
    {
      onSettled: () => {
        queryCache.invalidateQueries('report');
      }
    }
  );
