import { Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { ReactComponent as UploadIcon } from 'assets/icons/action-upload.svg';
import { showMessage } from 'functions';
import AttachmentFileUpload from './AttachmentFileUpload';

const ErroneousAgreementFileUpload = ({ value, onChange, isValidation = true, ...props }) => {
  const [file, setFile] = useState();

  const isLargerThan5Mb = file => {
    const { size } = file;
    return size / 1024 / 1024 > 5;
  };

  const beforeUpload = file => {
    if (isValidation && isLargerThan5Mb(file)) {
      showMessage({ type: 'error', content: 'File import không quá 5MB.' });
      return Promise.reject();
    }
    setFile(file);
    onChange(file);
    return false;
  };

  useEffect(() => {
    setFile(value);
  }, [value]);

  return (
    <Upload.Dragger showUploadList={false} beforeUpload={beforeUpload} {...props}>
      {!file && (
        <>
          <UploadIcon />
          <p className="ant-upload-text">
            Tải lên hoặc kéo thả <strong>thoả thuận sai sót</strong> vào đây
          </p>
        </>
      )}
      {file && <AttachmentFileUpload file={file} />}
    </Upload.Dragger>
  );
};

export default ErroneousAgreementFileUpload;
