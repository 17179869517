import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { ConfigProvider, Spin } from 'antd';
import FontFaceObserver from 'fontfaceobserver';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactQueryConfigProvider } from 'react-query';
import viVN from 'antd/lib/locale/vi_VN';
import moment from 'moment';
import 'moment/locale/vi';
import dict from 'translations/vi/dict.json';
import RouterWrapper from './Router';

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'vi',
  resources: {
    vi: {
      dict: dict
    }
  }
});

// Just 1 namespace for now.
i18next.setDefaultNamespace('dict');

//
moment.locale('vi');

const interObserver = new FontFaceObserver('Inter', {});
interObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

Spin.setDefaultIndicator(<LoadingOutlined />);

const reactQueryConfig = {
  queries: {
    retry: 1,
    refetchOnWindowFocus: false
  }
};

function App() {
  return (
    <ConfigProvider locale={viVN}>
      <I18nextProvider i18n={i18next}>
        <ReactQueryConfigProvider config={reactQueryConfig}>
          <RouterWrapper />
        </ReactQueryConfigProvider>
      </I18nextProvider>
    </ConfigProvider>
  );
}

export default App;
