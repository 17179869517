import { Checkbox, Col, Form, Modal, Row, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InvoiceDescription from './InvoiceDescription';
import { validateEmailList } from 'utils/validate';

const IssueInvoiceModal = ({ isLoading, invoice = {}, onOk, ...rest }) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const searchRef = React.useRef();
  const [isSendToCustomer, setSendToCustomer] = useState(true);

  const handleKeyDown = e => {
    if (!searchRef.current && e.key === 'Enter') e.stopPropagation();
  };

  const issueInvoice = async () => {
    const { send_customer: sendCustomer, recipients = [] } = await form.validateFields();
    onOk(sendCustomer ? recipients : []);
  };

  const emailsValidator = (_, emails) => {
    if (!validateEmailList(emails)) {
      return Promise.reject('Email không hợp lệ.');
    }

    return Promise.resolve();
  };

  useEffect(() => {
    if (!isSendToCustomer) {
      form.validateFields();
    }
  }, [isSendToCustomer, form]);

  return (
    <Modal
      okText="Phát hành"
      cancelText={t('button.cancel')}
      title="Phát hành hoá đơn"
      confirmLoading={isLoading}
      onOk={issueInvoice}
      width={703}
      {...rest}
    >
      <InvoiceDescription invoice={invoice} />
      <Form
        form={form}
        colon={false}
        initialValues={{
          recipients: invoice.extra_fields?.email ? [invoice.extra_fields?.email] : [],
          send_customer: true
        }}
        layout="vertical"
      >
        <Row gutter={36}>
          <Col span={24}>
            <Form.Item name="send_customer" valuePropName="checked">
              <Checkbox onChange={e => setSendToCustomer(e.target.checked)}>Gửi hoá đơn cho khách hàng</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={36}>
          <Col span={24}>
            <Form.Item
              label="Email"
              name="recipients"
              rules={[
                {
                  required: isSendToCustomer,
                  message: 'Email không được để trống'
                },
                {
                  validator: emailsValidator
                }
              ]}
            >
              <Select
                open
                autoFocus
                placeholder="Nhập danh sách email khách hàng. Email cách nhau bởi dấu phẩy"
                mode="tags"
                disabled={!isSendToCustomer}
                dropdownStyle={{ display: 'none' }}
                style={{ width: '100%' }}
                tokenSeparators={[',', ' ']}
                onSearch={value => (searchRef.current = value)}
                onChange={() => (searchRef.current = '')}
                onInputKeyDown={handleKeyDown}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default IssueInvoiceModal;
