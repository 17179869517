export const YEAR_FROM = 2010;
export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const BIG_PAGE_SIZE = 100;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_EXPORT_INTERVAL = 3000;
export const DEFAULT_EXPORT_TIMEOUT = 60000;
export const TABLE_PAGE_SIZE_OPTION = ['10', '20', '50'];

export const PAYMENT_CURRENCY = Object.freeze({
  vnd: 'VND',
  usd: 'USD'
});

export const DEFAULT_SORT = Object.freeze({
  field: 'updatedDate',
  order: 'descend'
});

export const DECREE_TYPE = Object.freeze({
  DECREE_123: 'DECREE_123',
  DECREE_51: 'DECREE_51'
});

export const BASIC_MODE = Object.freeze({
  view: 'VIEW',
  edit: 'EDIT'
});

/**
 * The format when exporting the report.
 */
export const FileFormat = Object.freeze({
  PDF: 'pdf',
  XML: 'xml'
});

export const SUBMITSION_EXPORT_TYPE = Object.freeze({
  ONLINE: 'online',
  OFFLINE: 'offline'
});

export const PERIOD = Object.freeze({
  QUARTERLY: 'QUARTERLY',
  MONTHLY: 'MONTHLY',
  IMMEDIATELY: 'IMMEDIATELY'
});
