import { get } from 'lodash';

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateEmailList = (array = []) => {
  for (let i = 0; i < array.length; i++) {
    if (!validateEmail(array[i])) return false;
  }
  return true;
};

export const validateExcelFile = file => {
  const validExts = ['.xlsx', '.xls'];
  const { name } = file;
  const ext = name.substring(name.lastIndexOf('.'));
  return validExts.indexOf(ext) >= 0;
};

export function isFileSizeLessThan(file, max, isSame = false) {
  const { size } = file;
  if (!isSame) return size / 1024 / 1024 < max;
  return size / 1024 / 1024 <= max;
}

export function isExcelFile(file) {
  const acceptedFileTypes = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ];
  return acceptedFileTypes.includes(file.type);
}

export function isPNGFile(file) {
  const type = get(file, 'type', '');
  return type.endsWith('png');
}

export function isImageFile(file) {
  const imageReg = /[/.](jpg|png|jpeg)$/i;
  const type = get(file, 'type', '');
  return imageReg.test(type);
}

export function isEmptyOrSpaces(str = '') {
  try {
    if (parseFloat(str) === 0) return false;
    return !str || str.toString().match(/^ *$/) !== null;
  } catch (e) {
    return false;
  }
}
