import React from 'react';
import { Route, Switch } from 'react-router-dom';
import withLoadable from 'utils/withLoadable';

const LookupInvoicePage = withLoadable(() => import('./index'));
const LookupInvoiceRouter = () => (
  <Switch>
    <Route exact path="/lookup-invoice" children={LookupInvoicePage} />
    <Route exact path="/lookup-invoice/:code" children={LookupInvoicePage} />
  </Switch>
);

export default LookupInvoiceRouter;
