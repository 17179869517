import React from 'react';
import ContactUs from './components/ContactUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import Certificate from './components/Certificate';
import styles from './index.less';

const Footer = () => {
  return (
    <div className={styles.support}>
      <div className={styles.supportContact}>
        <ContactUs />
        <Certificate />
      </div>
      <div className={styles.privacyPolicy}>
        <PrivacyPolicy />
      </div>
    </div>
  );
};

export default Footer;
