import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { pickBy } from 'lodash';
import { Form, Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useUserRoles } from 'queries/user';
import { USER_STATUS } from 'constants/user';
import UserForm from '../UserForm';
import styles from './index.less';

const UserModal = props => {
  const { title, onYourSelf, user, loading, onSubmit, onCancel, visible } = props;

  const [t] = useTranslation();
  const [form] = Form.useForm();
  const { data: userRoles = [], isFetching, remove } = useUserRoles(user.username);

  const submitUser = async () => {
    const values = await form.validateFields();
    const payload = pickBy({ id: user.id, ...values });
    onSubmit(payload);
  };

  useEffect(() => {
    if (!visible) {
      remove();
      form.resetFields();
    }
  }, [visible, remove, form]);

  const computedUser = useMemo(() => ({ ...user, roles: userRoles.map(item => item.id) }), [userRoles, user]);
  return (
    <Modal
      title={title}
      width={703}
      className={styles.userModal}
      cancelText={t('button.cancel')}
      okText={t(`button.${user.id ? 'update' : 'create-in-short'}`)}
      onOk={submitUser}
      onCancel={onCancel}
      confirmLoading={loading}
      visible={visible}
    >
      <Spin spinning={isFetching}>
        <UserForm form={form} user={computedUser} fieldsDisabled={{ status: onYourSelf }} />
      </Spin>
    </Modal>
  );
};

UserModal.defaultProps = {
  user: { status: USER_STATUS.ACTIVE }
};

UserModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  user: PropTypes.object,
  onYourSelf: PropTypes.bool,
  visible: PropTypes.bool,
  loading: PropTypes.bool
};

export default UserModal;
