import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Row, Radio } from 'antd';
import { USER_STATUS } from 'constants/user';
import rules from 'utils/rules';
import RoleSelect from './RoleSelect';

const { Item: FormItem } = Form;
const UserForm = ({ form, fieldsDisabled, user }) => {
  const [t] = useTranslation();

  useEffect(() => {
    form.setFieldsValue(user);
  }, [user, form]);

  return (
    <Form form={form} colon={false} layout="vertical">
      <Row gutter={10}>
        <Col span={12}>
          <FormItem
            label={t('user.user-form.last-name')}
            name="last_name"
            rules={[
              {
                required: true,
                message: t('user.user-form.last-name-required')
              }
            ]}
          >
            <Input />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label={t('user.user-form.first-name')}
            name="first_name"
            rules={[
              {
                required: true,
                message: t('user.user-form.first-name-required')
              }
            ]}
          >
            <Input />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col span={24}>
          <FormItem
            label={t('user.user-form.email')}
            name="email"
            rules={[
              ...rules.email,
              {
                required: true,
                message: t('user.user-form.email-required')
              }
            ]}
          >
            <Input />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col span={12}>
          <FormItem
            label={t('user.user-form.username')}
            name="username"
            rules={[
              {
                required: true,
                message: t('user.user-form.username-required')
              }
            ]}
          >
            <Input />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label={t('user.user-form.password')}
            name="password"
            rules={
              user.id
                ? []
                : [
                    {
                      required: true,
                      message: t('user.user-form.password-required')
                    }
                  ]
            }
          >
            <Input.Password />
          </FormItem>
        </Col>
      </Row>
      {!user.id && (
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label={t('user.user-form.roles')}
              name="roles"
              rules={[{ required: true, message: t('user.user-form.roles-required') }]}
            >
              <RoleSelect />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={10}>
        <Col span={24}>
          <FormItem
            label={t('user.user-form.status')}
            name="status"
            rules={[
              {
                required: true,
                message: t('user.user-form.status-required')
              }
            ]}
          >
            <Radio.Group disabled={fieldsDisabled.status}>
              <Radio value={USER_STATUS.ACTIVE}>{t('user.status.ACTIVE')}</Radio>
              <Radio value={USER_STATUS.INACTIVE}>{t('user.status.INACTIVE')}</Radio>
            </Radio.Group>
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

UserForm.defaultProps = {
  fieldsDisabled: {}
};

UserForm.propTypes = {
  fieldsDisabled: PropTypes.object
};

export default UserForm;
