import { isValidPhoneNumber } from 'libphonenumber-js';

export default {
  email: [
    {
      type: 'email',
      message: 'Email không hợp lệ.'
    }
  ],
  phone: [
    {
      max: 15,
      message: 'Tối đa 15 ký tự.'
    }
  ],
  internationalPhone: [
    {
      validator: (_, value = '') => {
        if (!value) return Promise.resolve();
        const hasPrefixPlus = value.charAt(0) === '+';
        const phoneNumber = hasPrefixPlus ? value : `+${value}`;
        const isValid = isValidPhoneNumber(phoneNumber);
        if (!isValid) return Promise.reject('Số điện thoại không hợp lệ.');
        return Promise.resolve();
      }
    }
  ],
  fax: [
    {
      max: 15,
      message: 'Tối đa 15 ký tự.'
    }
  ],
  address: [
    {
      required: true,
      message: 'Địa chỉ không được để trống.'
    },
    {
      whitespace: true,
      message: 'Địa chỉ không được để trống.'
    }
  ],
  productName: [
    {
      required: true,
      message: 'Tên sản phẩm không được để trống.'
    },
    {
      whitespace: true,
      message: 'Tên sản phẩm không được để trống.'
    },
    {
      max: 500,
      message: 'Tên sản phẩm không quá 500 ký tự.'
    }
  ],
  productCode: [
    {
      required: true,
      message: 'Mã sản phẩm không được để trống.'
    },
    {
      whitespace: true,
      message: 'Mã sản phẩm không được để trống.'
    },
    {
      max: 50,
      message: 'Mã sản phẩm tối đa 50 ký tự.'
    }
  ],
  unit: [
    {
      max: 50,
      message: 'Đơn vị tính tối đa 50 ký tự.'
    }
  ],
  accountNumber: [
    {
      max: 30,
      message: 'Tối đa 30 ký tự.'
    },
    {
      pattern: /^[a-zA-Z0-9\-./\\]*$/,
      message: 'Chỉ được nhập chữ cái, số và các ký tự . - / \\.'
    }
  ],
  tax_code: [
    {
      max: 14,
      message: 'Mã số thuế tối đa 14 ký tự.'
    },
    {
      pattern: '^[0-9-]+$',
      message: 'Mã số thuế chỉ bao gồm số và ký tự -'
    }
  ],
  swift_code: [
    {
      max: 11,
      message: 'Mã SWIFT tối đa 11 ký tự.'
    },
    {
      pattern: '^[A-Z0-9]*$',
      message: 'Mã SWIFT chỉ bao gồm chữ in hoa, chữ số.'
    }
  ]
};
