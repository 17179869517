import { and, comparison, eq, ge, le, inList } from 'rsql-builder';
import { chain } from 'lodash';
import { DEFAULT_SORT, DEFAULT_PAGE_SIZE } from 'constant';

const isEmptyValue = value => {
  if (Array.isArray(value) && value.length === 0) return false;
  return !(value === undefined || value === null || value === '');
};

const _mapFilter = (key, value) => {
  if (value instanceof String) {
    return comparison(key, eq(`*${value.trim()}*`));
  }

  if (!Array.isArray(value)) {
    const { type, values } = value;
    if (['number', 'enum', 'boolean'].includes(type)) {
      return comparison(key, eq(values));
    }
    if (type === 'date-time') {
      const [start, end] = values;
      return and(comparison(key, ge(start)), comparison(key, le(end)));
    }
    if (Array.isArray(values)) {
      return comparison(key, inList(...values));
    }
    return comparison(key, eq(`*${values.trim()}*`));
  }
  return comparison(key, inList(...value));
};

export const buildFilterQuery = filters => {
  const filterQuery = chain(filters)
    .keys()
    .filter(key => isEmptyValue(filters[key].values))
    .map(key => _mapFilter(key, filters[key]))
    .value();
  return and(...filterQuery);
};

export const buildSortParams = sorter => {
  if (!sorter) return null;
  const { field, order } = { ...DEFAULT_SORT, ...sorter };
  const direction = order === 'ascend' ? '' : '-';
  return { sort: `${direction}${field}` };
};

export const buildPaginationParams = pagination => {
  if (!pagination) return null;
  const { current, pageSize } = { current: 1, pageSize: DEFAULT_PAGE_SIZE, ...pagination };
  return { page: current - 1, size: pageSize };
};

export default params => {
  const { pagination = {}, filters = {}, sorter = {} } = params || {};
  return {
    q: buildFilterQuery(filters),
    ...buildPaginationParams(pagination),
    ...buildSortParams(sorter)
  };
};
