import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { showMessage, showResponseError } from 'functions';

const DeleteModal = ({ onOk, data, deleteFunction, successfulDeletionMsg, ...rest }) => {
  const [t] = useTranslation();
  const [del, { isLoading }] = deleteFunction();

  const deletePromise = ({ id }) => {
    return new Promise((resolve, reject) =>
      del(id, {
        onSuccess: resolve,
        onError: reject
      })
    );
  };

  const handleDelete = () => {
    if (Array.isArray(data)) {
      const promise = data.map(item => deletePromise(item));
      Promise.all(promise)
        .then(() => {
          onOk();
          showMessage({ content: successfulDeletionMsg });
        })
        .catch(error => {
          showResponseError({ error });
        });
    } else {
      del(data.id, {
        onSuccess: () => {
          showMessage({ content: successfulDeletionMsg });
          onOk();
        },
        onError: error => {
          showResponseError({ error });
        }
      });
    }
  };

  return (
    <Modal
      okText={t('button.delete')}
      cancelText={t('button.cancel')}
      title={t('confirm.delete-title')}
      {...rest}
      onOk={handleDelete}
      confirmLoading={isLoading}
    >
      <span>{t('confirm.delete-message')}</span>
    </Modal>
  );
};

DeleteModal.defaultProps = {
  data: {},
  onOk: () => {},
  successfulDeletionMsg: 'Xóa thành công.',
  deleteFunction: undefined
};

DeleteModal.propTypes = {
  data: PropTypes.any,
  onOk: PropTypes.func,
  successfulDeletionMsg: PropTypes.string,
  deleteFunction: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([undefined])])
};

export default DeleteModal;
