import React from 'react';
import { Route, Switch } from 'react-router-dom';
import withLoadable from 'utils/withLoadable';

const CustomerListPage = withLoadable(() => import('./list'));
const DetailPage = withLoadable(() => import('./detail'));

const CustomerRouter = () => (
  <Switch>
    <Route path="/category/customer/:id/edit" component={DetailPage} />
    <Route path="/category/customer/create" component={DetailPage} />
    <Route path="/category/customer" component={CustomerListPage} />
  </Switch>
);

export default CustomerRouter;
