import request from 'utils/request';
import { includes } from 'ramda';
import buildQueryParams from '../utils/buildQueryParams';

/**
 * Invoice summary status
 */
export const InvoiceSummaryStatus = Object.freeze({
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
});

/**
 * Invoice summary stage.
 */
export const InvoiceSummaryStage = Object.freeze({
  WAITING: 'WAITING',
  SIGNED: 'SIGNED'
});

/**
 * Invoice summary type.
 */
export const InvoiceSummaryType = Object.freeze({
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY'
});

export const Periods = [
  { value: 1, label: 'Quý 1' },
  { value: 2, label: 'Quý 2' },
  { value: 3, label: 'Quý 3' },
  { value: 4, label: 'Quý 4' }
];

export const Months = [
  { value: 1, label: 'Tháng 1' },
  { value: 2, label: 'Tháng 2' },
  { value: 3, label: 'Tháng 3' },
  { value: 4, label: 'Tháng 4' },
  { value: 5, label: 'Tháng 5' },
  { value: 6, label: 'Tháng 6' },
  { value: 7, label: 'Tháng 7' },
  { value: 8, label: 'Tháng 8' },
  { value: 9, label: 'Tháng 9' },
  { value: 10, label: 'Tháng 10' },
  { value: 11, label: 'Tháng 11' },
  { value: 12, label: 'Tháng 12' }
];

const ActionStatusMapping = Object.freeze({
  edit: [InvoiceSummaryStatus.NOT_SUBMITTED, InvoiceSummaryStatus.ERROR],
  delete: [InvoiceSummaryStatus.NOT_SUBMITTED, InvoiceSummaryStatus.ERROR],
  issue: [InvoiceSummaryStatus.NOT_SUBMITTED, InvoiceSummaryStatus.ERROR]
});

/**
 * Check if action is available to execute.
 *
 * @param action the given action (edit, delete, issue...)
 */
export const checkIfActionAvailable = (action, status) => includes(status)(ActionStatusMapping[action]);

/**
 * Get list of invoice summary.
 */
export const getInvoiceSummaries = params =>
  request.get('/invoice-summaries', {
    params: buildQueryParams(params)
  });

/**
 * Get invoice summary by id.
 *
 * @param id the given invoice summary id
 */
export const getInvoiceSummary = id => request.get(`/invoice-summaries/${id}`);

/**
 * Get invoice summary part.
 *
 * @param id the given invoice summary id
 */
export const getPartOfInvoiceSummary = (id, partId) => request.get(`/invoice-summaries/${id}/parts/${partId}`);

/**
 * Delete invoice summary.
 *
 * @param id the given invoice summary id
 */
export const deleteInvoiceSummary = id => request.delete(`/invoice-summaries/${id}`);

/**
 * Export invoice summary report as XML.
 *
 * @param {string} id the given invoice summary id
 * @param format the given report format (xml or pdf). XML is default value
 */
export const exportInvoiceSummaryReport = (id, format = 'xml') =>
  request.post(
    `/invoice-summaries/${id}/export`,
    { id },
    {
      params: { format }
    }
  );

/**
 * Request invoice summary base64 payload to sign.
 */
export const requestInvoiceSummarySignPayload = (id, serialNumber) =>
  request.post(
    `/invoice-summaries/${id}/parts/1/signing`,
    { serial_number: serialNumber },
    {
      params: { format: 'xml' }
    }
  );

/**
 * Create invoice summary.
 */
export const createInvoiceSummary = data => request.post('/invoice-summaries', data);

/**
 * Edit an invoice summary.
 */
export const editInvoiceSummary = (id, invoiceSummary) => request.put(`/invoice-summaries/${id}`, { invoiceSummary });
