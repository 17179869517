import React from 'react';
import { DatePicker } from 'antd';
import { DATE_FORMAT } from 'constant';
import { getEpoch } from 'utils/format';

const TableDateRangePickerFilter = ({ field, onFilter, ...rest }) => {
  return (
    <DatePicker.RangePicker
      size="small"
      placeholder={['Từ ngày', 'Đến ngày']}
      format={DATE_FORMAT}
      onChange={value =>
        onFilter({
          [`${field}`]: {
            values: value ? [getEpoch(value[0].startOf('day')), getEpoch(value[1].endOf('day'))] : '',
            type: 'date-time'
          }
        })
      }
      {...rest}
    />
  );
};

export default TableDateRangePickerFilter;
