import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Form, Input, Modal, Row, Descriptions, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { showMessage, showResponseError } from 'functions';
import { requestInvoiceUploadUrl } from 'services/invoice';
import { uploadDocument } from 'services/document';
import { useCancelInvoice } from 'queries/invoice';
import { formatDate, formatNumber } from 'utils/format';
import { INVOICE_UPLOAD_TYPE, AUTHORITY_INVOICE_STATUS } from 'constants/invoice';
import { DATE_FORMAT, DECREE_TYPE } from 'constant';
import ErroneousAgreementFileUpload from './ErroneousAgreementFileUpload';

const InvoiceCancelModal = ({ visible, invoice, signedForm04, decree, onOk, onCancel, ...rest }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const isDecree51 = DECREE_TYPE.DECREE_51 === decree;
  const [isCancelling, setIsCancelling] = React.useState(false);
  const sentDoT = invoice.authority_status === AUTHORITY_INVOICE_STATUS.SUCCESS;
  const isReadyCreateCancel = !sentDoT || signedForm04;

  const uploadFile = async file => {
    const result = {};
    if (file) {
      let response = await requestInvoiceUploadUrl(invoice.id, INVOICE_UPLOAD_TYPE.CANCELLATION_ATTACHMENT, file.name);
      const { file_url, document_id } = file ? await uploadDocument(file, response) : {};
      result.fileUrl = file_url;
      result.documentId = document_id;
    }
    return result;
  };

  const [cancel] = useCancelInvoice();
  const handleCancelInvoice = async () => {
    if (!isReadyCreateCancel) {
      onCancel();
      history.push('/invoice/cancellation-notification/create');
      return;
    }
    const fValues = await form.validateFields();
    try {
      setIsCancelling(true);
      const { fileUrl, documentId } = await uploadFile(fValues.file);
      cancel(
        { id: invoice.id, data: { ...fValues, cancellation_url: fileUrl, cancel_document_id: documentId } },
        {
          onSuccess: () => {
            onOk();
            setIsCancelling(false);
            history.push(`/invoice/cancel`);
            showMessage({ content: t('invoice.cancel.success') });
          },
          onError: error => {
            showResponseError({ error });
          }
        }
      );
    } catch (error) {
      setIsCancelling(false);
      showResponseError({ error });
    }
  };

  function disabledDate(current) {
    const dateOfIssue = moment(invoice.date_of_issue).startOf('day');
    return current && current.isBefore(dateOfIssue);
  }

  return (
    <Modal
      okText={isReadyCreateCancel ? t('button.cancel-invoice') : t('button.create-cancel-notification')}
      cancelText={t('button.cancel')}
      title={t('invoice.cancel.title')}
      onOk={handleCancelInvoice}
      confirmLoading={isCancelling}
      visible={visible}
      width={isReadyCreateCancel ? 703 : 520}
      onCancel={onCancel}
      {...rest}
    >
      {!isReadyCreateCancel && t('invoice.cancel.notification')}
      {isReadyCreateCancel && (
        <Form
          form={form}
          colon={false}
          initialValues={{
            cancel_date: moment()
          }}
          layout="vertical"
        >
          <Row gutter={36}>
            <Col span={24}>
              {invoice.id && (
                <Descriptions column={2} title="">
                  <Descriptions.Item label="Số hoá đơn">
                    <strong>{invoice.serial_number}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Ngày hóa đơn">
                    <strong>{formatDate(invoice.date_of_issue)}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label={`${isDecree51 ? 'Mẫu số/' : ''}Ký hiệu`}>
                    <strong>
                      {invoice.invoice_template.form}
                      {DECREE_TYPE.DECREE_51 === decree ? '-' : ''}
                      {invoice.symbol}
                    </strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Số tiền">
                    <strong>{formatNumber(invoice.extra_fields.total)}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item span={2} label="Khách hàng">
                    <strong>{invoice.consumer.name}</strong>
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Col>
          </Row>
          <Row gutter={36}>
            <Col span={24}>
              <Descriptions column={2} />
            </Col>
          </Row>
          <Row gutter={36}>
            <Col span={24}>
              <Form.Item
                label="Ngày xoá bỏ"
                name="cancel_date"
                rules={[
                  {
                    required: true,
                    message: 'Ngày xoá bỏ không được để trống'
                  }
                ]}
              >
                <DatePicker disabledDate={disabledDate} placeholder="" format={DATE_FORMAT} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={36}>
            <Col span={24}>
              <Form.Item
                label="Biên bản đính kèm"
                name="file"
                rules={[
                  {
                    validator: (_, file) => {
                      if (file) {
                        const isLt5M = file.size / 1024 / 1024 < 5;
                        if (!isLt5M) {
                          return Promise.reject('Tệp không nặng quá 5MB.');
                        }
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <ErroneousAgreementFileUpload isValidation={false} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={36}>
            <Col span={24}>
              <Form.Item
                label="Lý do xoá bỏ"
                name="reason"
                rules={[
                  {
                    required: true,
                    message: 'Lý do xoá bỏ không được để trống'
                  }
                ]}
              >
                <Input.TextArea rows={6} placeholder="" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

InvoiceCancelModal.defaultProps = {
  visible: false,
  signedForm04: false,
  decree: '',
  invoice: {},
  onOk: () => {},
  onCancel: () => {}
};

InvoiceCancelModal.propTypes = {
  visible: PropTypes.bool,
  signedForm04: PropTypes.bool,
  invoice: PropTypes.object,
  decree: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func
};

export default InvoiceCancelModal;
