import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import SecurityLayout from 'layouts/SecurityLayout';
import InvoiceRouter from 'pages/invoice/InvoiceRouter';
import ReportRouter from 'pages/report/ReportRouter';
import CategoryRouter from 'pages/category/CategoryRouter';
import OrganizationRouter from 'pages/organization/OrganizationRouter';
import TenantRouter from './pages/tenant/TenantRouter';
import HomePage from './pages/home';
import LookupInvoiceRouter from 'pages/lookupInvoice/LookupInvoiceRouter';
import withLoadable from './utils/withLoadable';
import LandingLayout from './layouts/LandingLayout';
import TenantLayout from './layouts/TenantLayout';
import LayoutContent from './layouts/LayoutContent';
import SecurityContextWrapper from './SecurityContext';

const BasicLayout = withLoadable(() => import('layouts/BasicLayout'));
const SignInPage = withLoadable(() => import('pages/user/signIn'));
const ChangePasswordPage = withLoadable(() => import('pages/user/changePassword'));
const SetupPasswordPage = withLoadable(() => import('pages/user/setupPassword'));
const SupportPage = withLoadable(() => import('pages/support'));
const InvoiceTemplatesPage = withLoadable(() => import('pages/invoiceTemplates'));
const LandingPage = withLoadable(() => import('pages/landing'));
const RegistrationPage = withLoadable(() => import('pages/registration'));
const PackagesPage = withLoadable(() => import('pages/packages'));
const RegistrationSuccessPage = withLoadable(() => import('pages/registration/pages/successPage'));
const HowToUsePage = withLoadable(() => import('pages/howToUse'));
const PricingPage = withLoadable(() => import('pages/pricing'));
const UserListPage = withLoadable(() => import('pages/user/list'));
const RoleListPage = withLoadable(() => import('pages/role/list'));
const InvoiceSystemUsageReportPage = withLoadable(() => import('pages/report/invoiceSystemUsage'));

const UserDashboardPage = withLoadable(() => import('pages/dashboard/user'));
const NotAuthorizedPage = withLoadable(() => import('pages/user/notAuthorized'));

const ScrollRestoration = withRouter(function ({ children, history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }; //eslint-disable-next-line
  }, []);

  return children;
});

const RouterWrapper = () => (
  <BrowserRouter>
    <ScrollRestoration>
      <Switch>
        <Route path="/lookup-invoice">
          <LandingLayout>
            <LookupInvoiceRouter />
          </LandingLayout>
        </Route>
        <Route path="/pricing">
          <LandingLayout>
            <PricingPage />
          </LandingLayout>
        </Route>
        <Route path="/support">
          <LandingLayout>
            <SupportPage />
          </LandingLayout>
        </Route>
        <Route path="/invoice-templates">
          <LandingLayout>
            <InvoiceTemplatesPage />
          </LandingLayout>
        </Route>
        <Route path="/landing">
          <LandingLayout>
            <Switch>
              <Route path="/landing/home" component={LandingPage} />
              <Route path="/landing/registration/success" component={RegistrationSuccessPage} />
              <Route path="/landing/registration" component={RegistrationPage} />
            </Switch>
          </LandingLayout>
        </Route>
        <Route path="/user/*">
          <LandingLayout>
            <LayoutContent>
              <Route path="/user/sign-in" component={SignInPage} />
              <Route path="/user/change-password" component={ChangePasswordPage} />
              <Route path="/user/setup-password" component={SetupPasswordPage} />
            </LayoutContent>
          </LandingLayout>
        </Route>
        <Route path="/tenant">
          <SecurityLayout>
            <SecurityContextWrapper>
              <TenantLayout>
                <Route path="/tenant" component={TenantRouter} />
              </TenantLayout>
            </SecurityContextWrapper>
          </SecurityLayout>
        </Route>
        <Route path="/packages">
          <SecurityLayout>
            <SecurityContextWrapper>
              <TenantLayout>
                <PackagesPage />
              </TenantLayout>
            </SecurityContextWrapper>
          </SecurityLayout>
        </Route>
        <Route path="/report/invoice-system-usage">
          <SecurityLayout>
            <SecurityContextWrapper>
              <TenantLayout>
                <InvoiceSystemUsageReportPage />
              </TenantLayout>
            </SecurityContextWrapper>
          </SecurityLayout>
        </Route>
        <Route path="/">
          <SecurityLayout>
            <SecurityContextWrapper>
              <BasicLayout>
                <Switch>
                  <Route path="/report" component={ReportRouter} />
                  <Route path="/user" component={UserListPage} />
                  <Route path="/role" component={RoleListPage} />
                  <Route path="/organization" component={OrganizationRouter} />
                  <Route path="/invoice" component={InvoiceRouter} />
                  <Route path="/category" component={CategoryRouter} />
                  <Route path="/dashboard" component={UserDashboardPage} />
                  <Route path="/how-to-use" component={HowToUsePage} />
                  <Route path="/" component={HomePage} />
                </Switch>
              </BasicLayout>
            </SecurityContextWrapper>
          </SecurityLayout>
        </Route>
        <Route path="/not-authorized" component={NotAuthorizedPage} />
      </Switch>
    </ScrollRestoration>
  </BrowserRouter>
);

export default RouterWrapper;
