import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useInvoiceExistInForm04 } from 'queries/invoice';
import { getInvoices } from 'services/invoice';
import { formatDate } from 'utils/format';
import { showMessage } from 'functions';
import FilterableHeaderTable from 'components/Table/FilterableHeaderTable';
import TableDateRangePickerFilter from 'components/Table/TableDateRangePickerFilter';
import { AUTHORITY_INVOICE_STATUS, INVOICE_STAGE } from 'constants/invoice';

const InvoiceSearchSelectModal = ({ visible, onOk, ...restProps }) => {
  const [resolveData, setResolveData] = React.useState({});
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [tableConfig, setTableConfig] = useState({
    pagination: { current: 1, pageSize: 5, pageSizeOptions: [5, 10, 20] },
    filters: {
      authority_status: {
        values: Object.keys(AUTHORITY_INVOICE_STATUS).filter(k => k === AUTHORITY_INVOICE_STATUS.SUCCESS)
      },
      stage: {
        values: [INVOICE_STAGE.SIGNED]
      }
    }
  });
  const { items = [], count = 0 } = resolveData;
  const fetchInvoices = React.useCallback(async () => {
    setIsLoading(true);
    setResolveData(await getInvoices(tableConfig));
    setIsLoading(false);
  }, [tableConfig]);

  const changeRowSelection = (_, selectedRows) => setSelectedInvoices(selectedRows);

  const changeTable = pagination => {
    setTableConfig(prev => ({
      ...prev,
      pagination: { ...prev.pagination, ...pagination }
    }));
  };

  const changeFilter = filters => {
    setTableConfig(prev => ({
      ...prev,
      filters: { ...prev.filters, ...filters }
    }));
  };

  const [check, { isLoading: isChecking }] = useInvoiceExistInForm04();
  const handleSubmit = () => {
    const ids = selectedInvoices.map(item => item.id);
    check(
      { ids, context: ['NOT_SUBMITTED'] },
      {
        onSuccess: ({ message }) => {
          if (message) {
            showMessage({ type: 'error', content: message });
          } else {
            onOk(selectedInvoices);
          }
        }
      }
    );
  };

  React.useEffect(() => {
    fetchInvoices();
  }, [tableConfig, fetchInvoices]);
  React.useEffect(() => {
    if (visible) fetchInvoices();
  }, [visible, fetchInvoices]);
  return (
    <Modal
      visible={visible}
      width={1100}
      title="Thêm hóa đơn"
      cancelText="Thoát"
      {...restProps}
      okButtonProps={{ loading: isChecking }}
      onOk={handleSubmit}
    >
      <FilterableHeaderTable
        loading={isLoading}
        size="small"
        rowSelection={{
          selectedRowKeys: selectedInvoices.map(item => item.id),
          onChange: changeRowSelection
        }}
        rowKey="id"
        dataSource={items}
        pagination={{ ...tableConfig.pagination, total: count }}
        columns={[
          {
            title: 'Ký hiệu hoá đơn',
            dataIndex: 'symbol',
            width: 150,
            filter: true,
            onFilter: values => changeFilter({ symbol: { values } })
          },
          {
            title: 'Số hoá đơn',
            dataIndex: 'serial_number',
            width: 150,
            filter: true,
            onFilter: values => changeFilter({ serial_number: { values } })
          },
          {
            title: 'Tên đơn vị',
            dataIndex: ['consumer', 'name'],
            filter: true,
            onFilter: values => changeFilter({ 'consumer.name': { values } })
          },
          {
            title: 'Ngày phát hành',
            dataIndex: 'date_of_issue',
            filter: true,
            width: 250,
            render: v => formatDate(v),
            filterRenderer: () => (
              <TableDateRangePickerFilter field="date_of_issue" onFilter={value => changeFilter(value)} />
            )
          }
        ]}
        onChange={changeTable}
      />
    </Modal>
  );
};

InvoiceSearchSelectModal.propTypes = {
  onOk: PropTypes.func.isRequired
};

export default InvoiceSearchSelectModal;
