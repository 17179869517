import React from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { Tooltip, Space, Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import InvoiceContext from 'pages/invoice/InvoiceContext';
import { ReactComponent as EditIcon } from 'assets/icons/action-edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/action-delete.svg';
import { ReactComponent as DownloadPDFIcon } from 'assets/icons/action-download-pdf.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/action-print.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/action-copy.svg';
import { ReactComponent as ExportXMLIcon } from 'assets/icons/action-export-xml.svg';
import { ReactComponent as SendIcon } from 'assets/icons/action-send.svg';
import { ReactComponent as SignIcon } from 'assets/icons/action-sign.svg';
import styles from './ModalHeaderActions.less';

const pageStyle = `
  @media print {
    @page {
      margin: 0;
      size: 1000px 1415px;
    }
  }
`;
const ModalHeaderActions = ({ data, pdfRef, showAllActions, checkIfActionAvailable }) => {
  const [t] = useTranslation();
  const {
    exportXml,
    exportPdf,
    editInvoice,
    deleteInvoice,
    issueInvoice,
    cloneInvoice,
    sendInvoiceToCustomer
  } = React.useContext(InvoiceContext);

  const permission = {
    edit: !checkIfActionAvailable('edit', data),
    delete: !checkIfActionAvailable('delete', data),
    issue: !checkIfActionAvailable('issue', data),
    sendEmail: !checkIfActionAvailable('sendEmail', data)
  };

  return (
    showAllActions && (
      <Space>
        <div className={styles.actions}>
          <Tooltip title={t('invoice.action.issue')}>
            <Button type="text" onClick={() => issueInvoice(data)} disabled={permission.issue} icon={<SignIcon />} />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title={t('button.edit')}>
            <Button type="text" onClick={() => editInvoice(data)} disabled={permission.edit} icon={<EditIcon />} />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title={t('invoice.action.send-email')}>
            <Button
              type="text"
              onClick={() => sendInvoiceToCustomer(data)}
              disabled={permission.sendEmail}
              icon={<SendIcon />}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title={t('invoice.action.clone')}>
            <Button type="text" onClick={() => cloneInvoice(data)} icon={<CopyIcon />} />
          </Tooltip>
          <Divider type="vertical" />
          <ReactToPrint
            pageStyle={pageStyle}
            trigger={() => (
              <Tooltip title="In hóa đơn">
                <Button type="text" icon={<PrintIcon />} />
              </Tooltip>
            )}
            content={() => pdfRef.current}
          />
          <Divider type="vertical" />
          <Tooltip title={t('invoice.registration.action.download-pdf')}>
            <Button type="text" onClick={() => exportPdf(data)} icon={<DownloadPDFIcon />} />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title={t('invoice.action.export-xml')}>
            <Button type="text" onClick={() => exportXml(data)} icon={<ExportXMLIcon />} />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title={t('button.delete')}>
            <Button
              type="text"
              onClick={() => deleteInvoice(data)}
              icon={<DeleteIcon />}
              disabled={permission.delete}
            />
          </Tooltip>
        </div>
      </Space>
    )
  );
};

ModalHeaderActions.defaultProps = {
  data: {},
  pdfRef: {},
  showAllActions: true
};

ModalHeaderActions.propTypes = {
  data: PropTypes.object,
  pdfRef: PropTypes.object,
  showAllActions: PropTypes.bool,
  checkIfActionAvailable: PropTypes.func.isRequired
};

export default ModalHeaderActions;
