import React from 'react';
import { Button, Layout, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import Image from 'components/Image';
import ProfileDropdown from 'components/User/ProfileDropdown';
import ModalManager from 'components/Modal/ModalManager';
import { ReactComponent as ReportIcon } from 'assets/icons/header-report.svg';
import { ReactComponent as InvoiceIcon } from 'assets/icons/header-invoice.svg';
import { ReactComponent as SystemIcon } from 'assets/icons/header-system.svg';
import Logo from 'assets/logos/logo.svg';
import { clearAuthorization } from 'utils/authority';
import { reloadAuthorized } from 'utils/Authorized';
import { Modals } from './Modals';
import styles from './BasicLayout/index.less';

const TenantLayout = ({ children }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const signOut = () => {
    clearAuthorization();
    reloadAuthorized();
    history.go('/');
  };

  return (
    <Layout className={styles.layout}>
      <Layout.Header style={{ paddingLeft: 0, paddingRight: 20 }}>
        <div className={styles.logo}>
          <Button type="text" ghost href="/">
            <Image src={Logo} width={100} />
          </Button>
        </div>
        <div className={styles.header}>
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key="/tenant">
              <Link to="/tenant">
                <SystemIcon />
                {t('menu.system.root')}
              </Link>
            </Menu.Item>
            <Menu.Item key="/packages">
              <Link to="/packages">
                <InvoiceIcon />
                Gói hóa đơn
              </Link>
            </Menu.Item>
            <Menu.SubMenu
              key="3"
              popupClassName={styles.subMenu}
              title={
                <span>
                  <ReportIcon />
                  {t('menu.report')}
                </span>
              }
            >
              <Menu.Item key="/report/invoice-system-usage">
                <Link to="/report/invoice-system-usage">Báo cáo việc truyền hóa đơn điện tử TT 32</Link>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
          <div className={styles.profile}>
            <ProfileDropdown onSignOut={signOut} />
          </div>
        </div>
      </Layout.Header>
      <Layout.Content>
        <ModalManager modalMap={Modals}>{children}</ModalManager>
      </Layout.Content>
    </Layout>
  );
};
export default TenantLayout;
