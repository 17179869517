import { Col, Form, Input, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const SignatureForm = ({ form, signature = {} }) => {
  const [t] = useTranslation();

  useEffect(() => {
    form.setFieldsValue(signature);
  }, [form, signature]);

  return (
    <Form form={form} colon={false} layout="vertical" requiredMark={false}>
      <Form.Item label={t('organization.signature.subject')} name="subject">
        <Input readOnly />
      </Form.Item>

      <Form.Item label={t('organization.signature.serial-number')} name="serial_number">
        <Input readOnly />
      </Form.Item>

      <Row gutter={10}>
        <Col span={12}>
          <Form.Item label={t('organization.signature.validity-not-before')} name="validity_not_before">
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t('organization.signature.validity-not-after')} name="validity_not_after">
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t('organization.signature.fingerprint')} name="fingerprint">
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t('organization.signature.issuer')} name="issuer">
            <Input readOnly />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label={t('organization.signature.certificate')}
        name="certificate"
        rules={[
          {
            required: true,
            message: t('organization.signature.certificate-error')
          }
        ]}
      >
        <Input.TextArea rows={4} readOnly placeholder={t('organization.signature.certificate-placeholder')} />
      </Form.Item>
    </Form>
  );
};

export default SignatureForm;
