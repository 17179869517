import React from 'react';
import { Route, Switch } from 'react-router-dom';
import withLoadable from 'utils/withLoadable';
import CustomerRouter from './customer/CustomerRouter';
const ProductListPage = withLoadable(() => import('pages/category/product/list'));

const CategoryRouter = () => (
  <Switch>
    <Route path="/category/customer" component={CustomerRouter} />
    <Route path="/category/product" component={ProductListPage} />
  </Switch>
);

export default CategoryRouter;
