import React from 'react';
import { Redirect } from 'react-router-dom';
import PageLoading from 'components/PageLoading';
import { useOrganization } from 'queries/organization';
import { isValidOrgInfo } from 'utils/organization';
import { getForceChangePwd, getTenant } from 'utils/authority';
import { BASIC_MODE } from 'constant';

const HomePage = () => {
  const { data, isLoading = true } = useOrganization();
  const tenant = getTenant();
  const forceChangePwd = getForceChangePwd();
  const validOrg = isValidOrgInfo(data);

  if (isLoading) return <PageLoading />;
  if (forceChangePwd === 'true') return <Redirect to={'/user/change-password'} />;
  if (tenant === 'system') return <Redirect to={'/tenant'} />;
  if (!validOrg) return <Redirect to={`/organization?mode=${BASIC_MODE.edit}`} />;
  return <Redirect to="/dashboard" />;
};

export default HomePage;
