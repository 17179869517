import React from 'react';
import Loading from 'components/Loading/LoadingModal';

function importRetry(importFn, retries, time = 1000) {
  return new Promise((resolve, reject) => {
    importFn()
      .then(resolve)
      .catch(error => {
        console.log('retrying....');
        setTimeout(() => {
          if (retries === 1) return reject(error);
          importRetry(importFn, retries - 1).then(resolve, reject);
        }, time);
      });
  });
}

const withLoadable = importFunc => {
  const Component = React.lazy(() => importRetry(importFunc, 20));
  return props => (
    <React.Suspense fallback={<Loading visible />}>
      <Component {...props} />
    </React.Suspense>
  );
};

export default withLoadable;
