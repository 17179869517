import React from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { Tooltip, Space, Button, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import CancellationContext from 'pages/invoice/cancellationNotification/CancellationContext';
import { checkIfActionAvailable } from 'services/cancellationNotification';
import { ReactComponent as EditIcon } from 'assets/icons/action-edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/action-delete.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/action-print.svg';
import { ReactComponent as DownloadXMLIcon } from 'assets/icons/action-export-xml.svg';
import styles from './ModalHeaderActions.less';

const pageStyle = `
  @media print {
    @page {
      margin: 0;
      size: 1000px 708px;
    }
  }
`;
const ModalHeaderActions = ({ data, pdfRef }) => {
  const [t] = useTranslation();
  const { editCancellation, deleteCancellation, downloadXml } = React.useContext(CancellationContext);

  const disabledActions = {
    edit: !checkIfActionAvailable('edit', data.authority_status),
    delete: !checkIfActionAvailable('delete', data.authority_status)
  };

  return (
    <Space>
      <div className={styles.actions}>
        <Tooltip title="Chỉnh sửa">
          <Button
            type="text"
            icon={<EditIcon />}
            onClick={() => editCancellation(data)}
            disabled={disabledActions.edit}
          />
        </Tooltip>
        <Divider type="vertical" />
        <ReactToPrint
          pageStyle={pageStyle}
          trigger={() => (
            <Tooltip title="In hóa đơn">
              <Button type="text" icon={<PrintIcon />} />
            </Tooltip>
          )}
          content={() => pdfRef.current}
        />
        <Tooltip title="Tải xuống XML">
          <Button type="text" onClick={() => downloadXml(data)} icon={<DownloadXMLIcon />} />
        </Tooltip>
        <Tooltip title={t('button.delete')}>
          <Button
            type="text"
            icon={<DeleteIcon />}
            disabled={disabledActions.delete}
            onClick={() => deleteCancellation(data)}
          />
        </Tooltip>
      </div>
    </Space>
  );
};

ModalHeaderActions.defaultProps = {
  data: {},
  pdfRef: {}
};

ModalHeaderActions.propTypes = {
  data: PropTypes.object,
  pdfRef: PropTypes.object
};

export default ModalHeaderActions;
