import moment from 'moment';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';
import { isPlainObject, get, trim, isString } from 'lodash';
import { isoUtcDateToMomentLocalDate } from 'services/date';
import { toVietnamese, toVietnameseAfterComma } from './n2vi';

/**
 * Ceiling
 * @param {number} term
 */
export const ceilTerm = term => Math.ceil(term);

export const roundNumber = (num, roundedToTheNearestInteger = false) => {
  if (roundedToTheNearestInteger) return Math.round(num);
  return +(Math.round(num + 'e+2') + 'e-2');
};

export const formatNumber = (v, USDFormat = false) => {
  const _getThousandSeparator = () => (USDFormat ? ',' : '.');
  const _getDecimalSeparator = () => (USDFormat ? '.' : ',');

  if (v === null || Number.isNaN(v)) return '';
  const value = `${Math.round(v * 100) / 100}`;
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `${_getThousandSeparator()}${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `${_getDecimalSeparator()}${list[1]}` : ''}`;
};

/**
 * Unformat formatted number by #formatNumber
 * @param {string} formattedValue
 */
export const unFormatNumber = formattedValue => {
  const strValue = formattedValue ? formattedValue.toString().replace(/\./g, '').replace(/,/g, '.') : null;
  return strValue ? Number(strValue) : null;
};

/**
 * Format date
 * @param {string|number|moment} m
 * @param {string} format
 */
export const formatDate = (m, format = 'DD/MM/YYYY') => {
  // Assume 0 is null due to limitation of gRPC
  if (!m || Number(m) === 0) return null;

  // support ISO
  const momentObj = isoUtcDateToMomentLocalDate(m);
  return momentObj.format(format);
};

/**
 * Get epoch value from date
 * @param {moment|string|number} m
 */
export const getEpoch = m => {
  if (!m) return null;

  if (moment.isMoment(m)) return m.valueOf();

  if (typeof m === 'string' && /^-?\d+$/.test(m)) {
    return Number(m);
  }

  return m;
};

/**
 * Epoch to moment
 * @param {number|string} e epoch
 */
export const epochToMoment = e => {
  // Assume 0 is null due to limitation of gRPC
  if (!e || Number(e) === 0) return null;

  if (typeof e === 'string' && /^-?\d+$/.test(e)) {
    return moment(Number(e));
  }

  return moment(e);
};

export function lowercaseFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export const bigCurrencyToViText = (amount, paymentCurrency, postfix) => {
  if (!isNaN(amount)) {
    let [integerPart, fractionalPart] = amount.toString().split('.');
    let index = integerPart.length;
    let arr = [];
    while (index > 0) {
      let xx = integerPart.substring(index, Math.max(index - 9, 0));
      arr.push(xx);
      index -= 9;
    }
    arr = arr.map((item, i) => {
      const lastedItem = i === 0;
      const needPrefixNum = item.length > 1 && item.charAt(0) === '0';
      if (needPrefixNum) item = '1' + item;
      if (fractionalPart && lastedItem) item += '.' + fractionalPart;
      const viText = currencyToViText(item, paymentCurrency, lastedItem && postfix);
      if (needPrefixNum) return viText.replace('Một tỷ', '');
      return viText;
    });

    return arr
      .reverse()
      .map((value, index) => {
        if (index !== 0) return lowercaseFirstLetter(value.trim());
        return value;
      })
      .filter(e => e)
      .join(' tỷ ');
  }
  return '';
};

const currencyToViText = (amount, paymentCurrency, postfix) => {
  if (!amount) return '';
  const amountUnits = amount.toString().split('.');
  const [integerPart, fractionalPart] = amountUnits;
  let text = '';
  switch (paymentCurrency) {
    case 'USD':
      text = fractionalPart
        ? toVietnamese(integerPart, postfix) + ' ' + toVietnameseAfterComma(fractionalPart, 'xu')
        : toVietnamese(integerPart, postfix);
      break;
    // default is VND
    default:
      text = fractionalPart
        ? toVietnamese(integerPart) + ' phẩy ' + toVietnameseAfterComma(fractionalPart, postfix)
        : toVietnamese(integerPart, postfix);
      break;
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const pad = (n, width = 7, z) => {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export function trimObject(data) {
  const _trimString = value => {
    return isString(value) ? trim(value) : value;
  };

  const _trimArray = array =>
    array.map(item => {
      if (Array.isArray(item)) {
        return _trimArray(item);
      }
      if (isPlainObject(item)) {
        return _trimObject(item);
      }
      return item;
    });

  const _trimObject = object => {
    const output = {};
    Object.keys(object).forEach(key => {
      const value = get(object, key, '');
      let newValue = null;

      if (Array.isArray(value)) {
        newValue = _trimArray(value);
      } else if (isPlainObject(value)) {
        newValue = _trimObject(value);
      } else {
        newValue = value;
      }
      output[key] = _trimString(newValue);
    });
    return output;
  };

  if (Array.isArray(data)) {
    return _trimArray(data);
  }
  if (isPlainObject(data)) {
    return _trimObject(data);
  }
  return data;
}

export const formatDisplayPhone = (value = '') => {
  const hasPrefixPlus = value.charAt(0) === '+';
  const phoneNumber = hasPrefixPlus ? value : `+${value}`;
  if (isValidPhoneNumber(phoneNumber)) {
    const { countryCallingCode, nationalNumber } = parsePhoneNumber(phoneNumber);
    return `(+${countryCallingCode}) ${nationalNumber}`;
  }
  return value;
};
