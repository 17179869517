import { useMutation, usePaginatedQuery, useQuery } from 'react-query';
import { createRole, deleteRole, getPermissions, getRoles, updateRole } from 'services/role';

const _rolesPaginatedKey = params => ['roles', params];
const _permissionsKey = () => ['permissions'];

export const useRoles = params => {
  return usePaginatedQuery(_rolesPaginatedKey(params), () => getRoles(params));
};
export const usePermissions = () => useQuery(_permissionsKey(), () => getPermissions());
export const useCreateRole = () => useMutation(role => createRole(role));
export const useUpdateRole = () => useMutation(role => updateRole(role));
export const useDeleteRole = () => useMutation(id => deleteRole(id));
