import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import classNames from 'classnames';
import Image from 'components/Image';
import { ReactComponent as HomeIcon } from 'assets/icons/icon-home.svg';
import { ReactComponent as InviceIcon } from 'assets/landing/header-invoice.svg';
import { ReactComponent as PricingIcon } from 'assets/icons/icon-pricing.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/icon-search.svg';
import { ReactComponent as GuideIcon } from 'assets/icons/icon-guide.svg';
import Logo from 'assets/logos/logo-green.svg';
import AuthSection from 'layouts/AuthSection';
import styles from './index.less';

const LandingLayout = ({ onMeuItemClick, selectedKey }) => {
  const linkClassName = key => {
    return classNames(styles.link, { [styles.active]: selectedKey === key });
  };

  return (
    <Layout.Header>
      <div className={styles.logoWrapper}>
        <Link to="/">
          <Image src={Logo} width={100} alt="logo" />
        </Link>
      </div>
      <div className={styles.menu}>
        <Menu theme="light" mode="horizontal" onClick={onMeuItemClick}>
          <Menu.Item key="home">
            <Link to="/" className={linkClassName('home')}>
              <HomeIcon />
              Trang chủ
            </Link>
          </Menu.Item>
          <Menu.Item key="invoiceTemplates">
            <Link to="/invoice-templates" className={linkClassName('invoiceTemplates')}>
              <InviceIcon />
              Mẫu hóa đơn
            </Link>
          </Menu.Item>
          <Menu.Item key="pricing">
            <Link to="/pricing" className={linkClassName('pricing')}>
              <PricingIcon />
              Báo giá
            </Link>
          </Menu.Item>
          <Menu.Item key="lookupInvoice">
            <Link to="/lookup-invoice" className={linkClassName('lookupInvoice')}>
              <SearchIcon />
              Tra cứu
            </Link>
          </Menu.Item>
          <Menu.Item key="guide">
            <Link to="/support" className={linkClassName('guide')}>
              <GuideIcon />
              Hỗ trợ
            </Link>
          </Menu.Item>
        </Menu>
      </div>
      <div className={styles.auth}>
        <AuthSection />
      </div>
    </Layout.Header>
  );
};

export default LandingLayout;
