import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Form } from 'antd';
import DragUpload from 'components/DragUpload';
import AttachmentLineItem from './AttachmentLineItem';
import { isFileSizeLessThan } from 'utils/validate';

const Content = ({ value, onChange, customDownloadFile, disabled, ...restProps }) => {
  const getValue = () => {
    if (value) {
      if (Array.isArray(value)) return value[0];
      return value;
    }
    return undefined;
  };

  return (
    <>
      <DragUpload
        preventUpload
        description="Kéo tệp vào đây hoặc chọn tệp"
        multiple={false}
        onChange={onChange}
        disabled={disabled}
        {...restProps}
      />
      <AttachmentLineItem
        disabled={disabled}
        src={getValue()}
        onRemove={() => onChange([])}
        customDownloadFile={customDownloadFile}
      />
    </>
  );
};

const AttachmentFormItem = ({ name, label, isRequired, accept, ...restProps }) => {
  const allowedExts = accept.split(',').filter(e => e);
  const validator = (_, fileList = []) => {
    const isFile = fileList[0] instanceof File;
    if (!isFile) return Promise.resolve();
    if (isRequired && fileList.length === 0) return Promise.reject('Tệp tải lên không được để trống.');
    if (fileList.length > 0 && !isFileSizeLessThan(fileList[0], 5)) {
      return Promise.reject('Tệp tải lên không nặng quá 5MB.');
    }
    const ext = get(fileList, '0.name').split('.')[1];
    if (fileList.length > 0 && allowedExts.length > 0 && !allowedExts.includes(`.${ext}`)) {
      return Promise.reject('Tệp tải lên không đúng định dạng.');
    }
    return Promise.resolve();
  };

  return (
    <Form.Item name={name} label={label} required={isRequired} rules={[{ validator }]}>
      <Content {...restProps} />
    </Form.Item>
  );
};

AttachmentFormItem.defaultProps = {
  isRequired: false,
  accept: ''
};

AttachmentFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  accept: PropTypes.string,
  isRequired: PropTypes.bool
};

export default AttachmentFormItem;
