import { v1 as uuidv1 } from 'uuid';
import moment from 'moment';

export function getUrlParameter(name) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const isMobileScreen = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(navigator.userAgent);
};

export function convertImgToBase64(file, callback) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = function () {
    callback(reader.result);
  };
}

export function updateArrayItem(source = [], target = {}, criteria = 'id') {
  return source.map(item => {
    if (item[criteria] === target[criteria]) {
      return { ...item, ...target };
    }
    return item;
  });
}

export function limitText(str = '', max = 30) {
  if (str.length < max) return str;
  return `${str.substring(0, max - 1)}...`;
}

export function isTrueSet(value) {
  const isBoolean = val => val === false || val === true;
  return isBoolean(value) ? value : value === 'true';
}

export function getFileExtension(fileName = '', defaultValue) {
  const [extension] = fileName.toString().split('.').reverse();
  return extension || defaultValue || '';
}

export function randomString(length) {
  const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return result;
}

export const withKey = data => {
  const _setKey = item => {
    if (item.key) return item;
    return { ...item, key: uuidv1() };
  };

  if (Array.isArray(data)) return data.map(_setKey);
  return _setKey(data);
};

export const isDateSame = (dateA, dateB) => {
  const momentA = moment(dateA).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const momentB = moment(dateB).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return momentA.isSame(momentB);
};

export const replaceCurrentTime = value => {
  return moment(value).set({
    hour: moment().hour(),
    minute: moment().minute(),
    second: moment().second()
  });
};
