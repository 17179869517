import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Input } from 'antd';

const FormattedNumberInput = ({
  onChange,
  onBlur,
  max,
  min,
  required,
  decimalScale,
  onlyInteger,
  integerPart,
  fractionalPart,
  isValueInvisible,
  thousandSeparator,
  decimalSeparator,
  ...rest
}) => {
  const onPriceBlur = ({ target }) => {
    const _parse = val => {
      const num = val.toString().replaceAll(thousandSeparator, '').replaceAll(decimalSeparator, '.');
      if (isNaN(num)) return 0;
      return parseFloat(num);
    };
    const shouldUseMinValue = required && !_parse(target.value);
    const outputValue = shouldUseMinValue ? min : target.value;
    if (shouldUseMinValue) onChange(outputValue);
    onBlur(outputValue);
  };

  const withValueLimit = input => {
    const { value } = input;
    const [integer = '', fractional = ''] = value.split('.');
    const matchLengthRule = integer.length <= integerPart && fractional.length <= fractionalPart;
    const onlyIntegerRule = onlyInteger ? !(value.indexOf('.') > -1) : true;
    const matchMaxValue = max || max === 0 ? Math.abs(integer) <= max : true;
    return matchLengthRule && onlyIntegerRule && matchMaxValue;
  };

  const onPriceChange = ({ floatValue }) => onChange(floatValue);
  return (
    <NumberFormat
      onBlur={onPriceBlur}
      onValueChange={onPriceChange}
      customInput={Input}
      decimalScale={decimalScale}
      style={{ textAlign: 'right', color: `${isValueInvisible ? 'transparent' : 'initial'}` }}
      isAllowed={withValueLimit}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      {...rest}
    />
  );
};

FormattedNumberInput.defaultProps = {
  max: undefined,
  integerPart: 17,
  fractionalPart: 2,
  decimalScale: 2,
  min: 0,
  thousandSeparator: '.',
  decimalSeparator: ',',
  isValueInvisible: false,
  isNumericString: true,
  allowNegative: false,
  onlyInteger: false,
  required: false,
  onChange: () => {},
  onBlur: () => {}
};

FormattedNumberInput.propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
  thousandSeparator: PropTypes.string,
  decimalSeparator: PropTypes.string,
  decimalScale: PropTypes.number,
  integerPart: PropTypes.number,
  fractionalPart: PropTypes.number,
  required: PropTypes.bool,
  isValueInvisible: PropTypes.bool,
  allowNegative: PropTypes.bool,
  isNumericString: PropTypes.bool,
  onlyInteger: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};

export default FormattedNumberInput;
